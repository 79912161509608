import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useSelector } from 'react-redux';
import EduTemplateAdd from './EduTemplateAdd';
import './EduTemplateMenu.scss';

export default function EduTemplateMenu(props: any) {
  const { formatMessage } = useIntl();
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);
  const [openModal, setOpenModal] = useState(false);

  return (
    <div className="edu-page-menu-wrap">
      {isAdmin === 1 && (
        <div className="menu-button">
          <button
            className="common-button-brand initial-config-bt"
            type="button"
            onClick={() => setOpenModal(true)}
          >
            {formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
          </button>
        </div>
      )}

      {openModal && <EduTemplateAdd visible={openModal} dataInfo={{}} toggleModal={setOpenModal} />}
    </div>
  );
}
