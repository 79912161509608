import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntl } from 'react-intl';
import { changeSummaryState, getEduTarget, changeTargetState } from 'store/edu';
import ContentHeader from 'components/common/ContentHeader';
import Dimmer from 'components/common/Dimmer';
import EduSidebar from 'components/branch/edu/EduSidebar';
import ContentList from 'components/common/ContentList';
import EduItem from 'components/branch/edu/EduItem';
import SearchFilter from 'components/common/SearchFilter';
import EduDetail from 'components/branch/edu/EduDetail';
import './Eduresult.scss';

export const authLevel = 1;
function Eduresult() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const eduSummary = useSelector((state: any) => {
    return {
      data: state.edu.eduSummary.data,
      dataByEduNo: state.edu.eduSummary.dataByEduNo,
      param: state.edu.eduSummary.param,
      needReload: state.edu.eduSummary.needReload,
      selectedEduNo: state.edu.eduSummary.selectedEduNo,
    };
  }, shallowEqual);

  const eduTarget = useSelector((state: any) => {
    return {
      data: state.edu.eduTarget.data,
      dataByEduNo: state.edu.eduTarget.dataByEduNo,
      param: state.edu.eduTarget.param,
      needReload: state.edu.eduTarget.needReload,
      page: state.edu.eduTarget.page,
      totalPage: state.edu.eduTarget.totalPage,
      totalItem: state.edu.eduTarget.totalItem,
      selectedList: state.edu.eduTarget.selectedList,
    };
  }, shallowEqual);

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    onGetEduTarget(true);
  }, [eduSummary.selectedEduNo, eduTarget.param]);

  // 교육 리스트 새로고침
  const onEduRefresh = async () => {
    setLoading(true);
    try {
      const params = {
        filter: {},
        offset: 0,
        limit: eduTarget.param.limit,
        refresh: true,
      };

      // 교육 대상자 param 변경 시
      await dispatch(changeTargetState({ key: 'param', value: params }));
      setLoading(false);
    } catch (error) {
      console.log('Edu onEduRefresh', error);
    }
  };

  // 교육 대상자 조회
  const onGetEduTarget = async (refresh = false) => {
    setLoading(true);

    try {
      const params: any = {
        eduNo: eduSummary.selectedEduNo,
        filter: JSON.stringify(eduTarget.param.filter),
        sort: JSON.stringify(eduTarget.param.sort),
        offset: 0,
        limit: eduTarget.param.limit,
      };

      if (Object.keys(eduSummary?.param?.filter).length > 0) {
        params.examFilter = JSON.stringify(eduSummary.param.filter);
      }

      if (refresh) {
        // 처음부터 조회
        params.refresh = true;
      } else {
        // 이어서 조회
        params.offset = eduTarget.param.limit * eduTarget.page;
      }

      await dispatch(getEduTarget(params));
      setLoading(false);
    } catch (error) {
      console.log('Edu onGetEduTarget', error);
    }
  };

  // 교육 선택
  const onSelectItem = useCallback(
    (no: number) => {
      let select = [...eduTarget.selectedList];
      select = eduTarget.selectedList.includes(no) ? [] : [no];

      dispatch(changeTargetState({ key: 'selectedList', value: select }));
    },
    [eduTarget.selectedList],
  );

  // 전체 교육 선택 여부
  const isAllEdu = useMemo(() => eduSummary.selectedEduNo === 'all', [eduSummary.selectedEduNo]);

  // 선택한 교육 정보
  const currentEdu = useMemo(() => !isAllEdu && eduSummary.dataByEduNo[eduSummary.selectedEduNo], [
    isAllEdu,
    eduSummary.dataByEduNo,
    eduSummary.selectedEduNo,
  ]);

  // 상단 제목
  const headerTitle = useMemo(
    () =>
      isAllEdu ? formatMessage({ id: 'Edu_19', defaultMessage: '전체 교육' }) : currentEdu?.eduName,
    [isAllEdu, currentEdu],
  );

  // 상단 부제목
  const headerSubTitle = useMemo(
    () =>
      isAllEdu
        ? `${formatMessage({ id: 'Edu_22', defaultMessage: '총 교육' })}: ${
            eduSummary.data.length
          }${formatMessage({ id: 'Exam_26', defaultMessage: '개' })}, ${formatMessage({
            id: 'Target_11',
            defaultMessage: '대상자',
          })}: ${eduTarget.totalItem}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`
        : ` ${formatMessage({
            id: 'Target_12',
            defaultMessage: '총 대상자',
          })}: ${currentEdu?.eduCount}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`,
    [isAllEdu, eduSummary.data, eduSummary.data.length, eduTarget.data, currentEdu],
  );

  return (
    <div className="common-content-layout">
      <div className="common-left-side">
        <EduSidebar />
      </div>
      <div className="common-content">
        <div className="common-inner">
          <ContentHeader title={headerTitle} subTitle={headerSubTitle} onRefresh={onEduRefresh} />

          <div className="edu-content-wrap pi">
            {/* 검색 영역 */}
            <SearchFilter
              param={eduTarget.param}
              paramAction={changeTargetState}
              selectedList={isAllEdu && eduTarget.selectedList}
            />

            <ContentList
              dataList={eduTarget.data}
              onLoadData={onGetEduTarget}
              loading={loading}
              page={eduTarget.page}
              totalPage={eduTarget.totalPage}
              noContent={{
                title: formatMessage({ id: 'Edu_23', defaultMessage: '교육 대상자가 없습니다.' }),
                subTitle: formatMessage({ id: 'Edu_24', defaultMessage: '교육을 실시하세요.' }),
              }}
            >
              {eduTarget.data.map((rowData: any) => {
                return (
                  <div
                    className={`content-list-item ${
                      !isAllEdu && eduTarget.selectedList.includes(rowData.targetNo)
                        ? 'selected'
                        : ''
                    }`}
                    key={rowData.targetNo}
                    onClick={() => onSelectItem(rowData.targetNo)}
                    aria-hidden="true"
                  >
                    <EduItem
                      data={rowData}
                      selectedEduNo={eduSummary.selectedEduNo}
                      eduFileInfo={isAllEdu ? null : currentEdu?.fileInfo}
                      quizInfo={isAllEdu ? null : currentEdu?.quizData}
                    />
                  </div>
                );
              })}
            </ContentList>
          </div>
        </div>
        {/* eduTarget */}
        <div className="common-inner-right">
          {isAllEdu ? (
            <Dimmer
              dimmerText={formatMessage({ id: 'Edu_18', defaultMessage: '교육을 선택하세요.' })}
            />
          ) : (
            <EduDetail
              detailType={eduTarget.selectedList.length < 1 ? 'edu' : 'target'}
              detailData={
                eduTarget.selectedList.length < 1
                  ? currentEdu
                  : eduTarget.dataByEduNo[eduTarget.selectedList[0]]
              }
              eduSummaryData={currentEdu}
            />
          )}
        </div>
      </div>
    </div>
  );
}

export default Eduresult;
