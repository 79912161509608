import { useEffect, useMemo, useRef, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { Popover } from 'antd';
import { VideoJsPlayer } from 'video.js';
import {
  changeState,
  eduLogout,
  eduQuizSolution,
  eduVideoUpdate,
  getEdu,
  readEmail,
} from 'store/edumain';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import Loading from 'components/common/Loading';
import EduVideoPlayer from 'components/branch/edumain/EduVideoPlayer';
import { Option } from 'utils/commonValues';

function Eduplay() {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [openEduList, setOpenEduList]: any = useState([]);
  const [videoOptions, setVideoOptions]: any = useState({});
  const [currentData, setCurrentData]: any = useState({});
  const [autoPlay, setAutoPlay] = useState(false);
  const [quizScreen, setQuizScreen] = useState(false);
  const [quizSeq, setQuizSeq] = useState(0);
  const [answer, setAnswer] = useState(0);
  const [allEnd, setAllEnd] = useState(false);

  const { serviceNo, userData, eduTitle, eduList, eduDataByEduNo, selectedEduNo } = useSelector(
    (state: any) => {
      return {
        serviceNo: state.edumain.serviceNo,
        userData: state.edumain.userData,
        eduTitle: state.edumain.eduTitle,
        eduList: state.edumain.eduList,
        eduDataByEduNo: state.edumain.eduDataByEduNo,
        selectedEduNo: state.edumain.selectedEduNo,
      };
    },
    shallowEqual,
  );

  useEffect(() => {
    if (userData) {
      if (userData?.isActive) {
        onGetEdu();
      } else {
        history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
      }
    }
  }, [userData]);

  useEffect(() => {
    if (eduDataByEduNo) {
      // 조회한 교육 데이터 저장(비디오 처리하기 위해 별도 저장)
      updateCurrentData(eduDataByEduNo);

      // 비디오 리스트 설정
      const options: any = {};
      Object.keys(eduDataByEduNo)?.forEach((key: any) => {
        const eduItem = eduDataByEduNo[key];
        const videoList: Array<any> = [];
        eduItem?.fileInfo?.forEach((file: any) => {
          videoList.push({
            sources: [{ src: file.filePath, type: 'video/mp4' }],
            poster: eduItem.thumbNail,
          });
        });
        options[eduItem.eduNo] = videoList;
      });
      setVideoOptions(options);
    }
  }, [JSON.stringify(eduDataByEduNo)]);

  // 교육 데이터 업데이트
  const updateCurrentData = (updateData: any) => {
    setCurrentData({ ...currentData, ...updateData });
  };

  // 로그아웃
  const onLogout = async () => {
    try {
      await dispatch(eduLogout());
      history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
    } catch (error) {
      console.log('onLogout', error);
    }
  };

  // 교육 리스트 조회
  const onGetEdu = async () => {
    setLoading(true);
    try {
      const params = {
        eduTargetNo: userData.eduTargetNo,
      };
      const response: any = await dispatch(getEdu(params));
      setLoading(false);
      if (response?.data?.list?.length > 0) {
        const { list } = response.data;
        list.forEach((item: any) => {
          if (!item.readDate) {
            onReadEmail(item.eduTargetLinkNo);
          }
        });
      } else {
        alert(formatMessage({ id: 'Edu_80', defaultMessage: '진행중인 교육이 없습니다.' }));
        history.push(`/edumain/edulogin?serviceNo=${serviceNo}`);
      }
    } catch (error) {
      console.log('onGetEdu', error);
    }
  };

  // 교육 메일 열람 처리
  const onReadEmail = async (eduTargetLinkNo: number) => {
    try {
      const params = {
        eduTargetLinkNo: eduTargetLinkNo,
      };
      await dispatch(readEmail(params));
    } catch (error) {
      console.log('onReadEmail', error);
    }
  };

  // 교육 선택 시
  const onSelectEdu = (eduNo: number) => {
    dispatch(changeState({ key: 'selectedEduNo', value: eduNo }));
    dispatch(changeState({ key: 'eduDataByEduNo', value: currentData }));
    setAutoPlay(false);
  };

  // 비디오 플레이어 이벤트 처리
  const onReadyPlayerEvent = (player: VideoJsPlayer) => {
    const currentEdu = currentData[selectedEduNo];
    let curPlayTime = currentEdu.playTime;
    let curPlayStep = currentEdu.playStep;

    if (autoPlay) {
      player.play();
    }

    player.on('play', function () {
      if (curPlayStep === 0 && player.currentTime() < 10) {
        curPlayStep = 1;
      }
    });

    player.on('timeupdate', function () {
      if (
        // 10초 지났을 때
        player.currentTime() > curPlayTime + 10 ||
        // 비디오 끝났을 때
        player.ended()
      ) {
        curPlayTime = player.currentTime();

        // 퀴즈부분 삽입 필요
        onTrainingVideo(player, curPlayStep);
      }
    });
  };

  // 교육 리스트 열기/닫기
  const toggleOpen = (eduInfo: any) => {
    const openList = [...openEduList];
    const existIndex = openList.indexOf(eduInfo.eduNo);
    if (existIndex > -1) {
      openList.splice(existIndex, 1);
    } else {
      openList.push(eduInfo.eduNo);
    }

    setOpenEduList(openList);
  };

  // 교육 동영상 실시간 반영
  const onTrainingVideo = async (player: VideoJsPlayer, curPlayStep: number) => {
    if (!player) return;

    const { fileInfo, eduAttachNo, eduStatus } = currentData[selectedEduNo];
    const tempAllEnd = player.ended() && fileInfo.length === curPlayStep;
    if (allEnd !== tempAllEnd) {
      setAllEnd(tempAllEnd);
    }

    try {
      const params = {
        eduNo: selectedEduNo,
        eduTargetNo: userData.eduTargetNo,
        eduAttachNo: eduAttachNo,
        playStep: curPlayStep,
        playTime: parseInt(player.currentTime().toFixed(), 10),
        isEndEdu: tempAllEnd.toString(),
      };
      const response: any = await dispatch(eduVideoUpdate(params));
      const item = response.data.list?.[0] || currentData[selectedEduNo];

      // 총 시간 계산
      let totalPlayTime = 0;
      let totalRunningTime = 0;
      currentData[selectedEduNo]?.fileInfo?.forEach((file: any, index: number) => {
        if (index < item.playStep - 1) {
          totalPlayTime += file.runningTime;
        }
        totalRunningTime += file.runningTime;
      });
      totalPlayTime += item.playTime;

      // 영상 끝났을 시
      if (player.ended()) {
        if (currentQuiz.length > quizSeq) {
          // 퀴즈 있을 시
          setQuizScreen(true);
        } else {
          // 퀴즈 없을 시
          setQuizScreen(false);
          // 자동 재생 설정
          setAutoPlay(curPlayStep && curPlayStep < fileInfo.length ? true : false);

          dispatch(
            changeState({
              key: 'eduDataByEduNo',
              value: {
                ...eduDataByEduNo,
                [item.eduNo]: {
                  ...currentData[selectedEduNo],
                  playStep: curPlayStep === fileInfo.length ? 0 : curPlayStep + 1,
                  playTime: 0,
                  eduProgress:
                    tempAllEnd || eduStatus === 2
                      ? 100
                      : ((totalPlayTime / totalRunningTime) * 100).toFixed(),
                  totalPlayTime:
                    tempAllEnd || eduStatus === 2 ? item.totalRunningTime : totalPlayTime,
                },
              },
            }),
          );
        }
      } else {
        // 영상 재생중일 시
        setQuizScreen(false);
        updateCurrentData({
          [item.eduNo]: {
            ...currentData[selectedEduNo],
            eduProgress:
              tempAllEnd || eduStatus === 2
                ? 100
                : ((totalPlayTime / totalRunningTime) * 100).toFixed(),
            eduStatus: item.eduStatus,
            playStep: item.playStep,
            playTime: item.playTime,
            totalPlayTime: tempAllEnd || eduStatus === 2 ? item.totalRunningTime : totalPlayTime,
          },
        });
      }
    } catch (error) {
      console.log('onTrainingVideo', error);
    }
  };

  // 퀴즈 선택 후 다음/확인 클릭 시
  const onNextQuiz = async () => {
    const { eduAttachNo, eduNo, playStep } = currentData[selectedEduNo];
    const step = playStep || 1;

    try {
      const params = {
        eduNo: selectedEduNo,
        eduTargetNo: userData.eduTargetNo,
        eduAttachNo: eduAttachNo,
        quizNo: currentQuiz[quizSeq].quizNo,
        quizSolution: answer,
      };
      const response: any = await dispatch(eduQuizSolution(params));
      if (response?.data?.list?.length) {
        // 오답일 때 안내
        if (response.data.list[0]?.result === 'fail') {
          alert(formatMessage({ id: 'Edu_79', defaultMessage: '오답입니다.' }));
        }
      }
      setAnswer(0);
      setQuizSeq(quizSeq + 1);

      // 현재 동영상의 퀴즈 다 풀었을 시
      if (currentQuiz.length === quizSeq + 1) {
        // 마지막 영상의 퀴즈일 시 자동 재생 종료
        if (step && step !== currentData[selectedEduNo].fileInfo.length) {
          setAutoPlay(true);
        } else {
          setAutoPlay(false);
        }
        setQuizScreen(false);
        setQuizSeq(0);

        dispatch(
          changeState({
            key: 'eduDataByEduNo',
            value: {
              ...eduDataByEduNo,
              [eduNo]: {
                ...currentData[selectedEduNo],
                playStep: step === videoOptions[selectedEduNo].length ? 1 : step + 1,
                playTime: 0,
                eduStatus:
                  step === videoOptions[selectedEduNo].length
                    ? 2
                    : currentData[selectedEduNo].eduStatus,
              },
            },
          }),
        );
      }
    } catch (error) {
      console.log('onNextQuiz', error);
    }
  };

  // 현재 교육 퀴즈 내용
  const currentQuiz: any = useMemo(() => {
    if (currentData[selectedEduNo]) {
      const { quizData, fileInfo, playStep } = currentData[selectedEduNo];
      const currentFileData = fileInfo[playStep ? playStep - 1 : 0];
      if (Array.isArray(quizData) && currentFileData) {
        return quizData?.filter((quiz: any) => {
          return quiz.fileNo === currentFileData.fileNo;
        });
      }
    }
    return [];
  }, [JSON.stringify(currentData[selectedEduNo])]);

  // 선택한 교육 상세 진행 상태 라벨
  const eduStatusLabel = useMemo(() => {
    const currentEdu = currentData[selectedEduNo];
    if (!currentEdu) return <></>;

    let status = 'wait';
    let message = formatMessage({ id: 'Status_17', defaultMessage: '대기' });
    if (currentEdu.eduStatus === 1) {
      status = 'ing';
      message = formatMessage({ id: 'Status_8', defaultMessage: '진행중' });
    } else if (currentEdu.eduStatus === 2) {
      status = 'finish';
      message = formatMessage({ id: 'Status_16', defaultMessage: '완료' });
    }

    return <span className={`edu-status ${status}`}>{message}</span>;
  }, [JSON.stringify(currentData[selectedEduNo])]);

  return (
    <div className="edu-layout">
      <Loading loading={loading} />

      <div className="edu-play-left">
        <div className="bg-effect">
          <div className="effect-1" />
          <div className="effect-2" />
          <div className="effect-3" />
        </div>

        <div className="list-box">
          <div className="list-top">List</div>
          {Object.keys(currentData)?.length > 0 &&
            eduList?.map((eduInfo: any) => {
              const item = currentData[eduInfo.eduNo];

              return (
                <div
                  key={item.eduNo}
                  className={`list-item ${item.eduNo === selectedEduNo ? 'active' : ''}`}
                  onClick={() => onSelectEdu(item.eduNo)}
                  aria-hidden="true"
                >
                  <div className="list-item-title">
                    {item.eduStatus === 2 && (
                      <span className="complete">
                        {formatMessage({ id: 'Status_16', defaultMessage: '완료' })}
                      </span>
                    )}
                    {item.attachName}
                  </div>
                  <dl>
                    <dt className="list-label">
                      {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
                    </dt>
                    <dd className="list-value">{`${timeFormatFromUTCEpoch(
                      item.startEpoch,
                      3,
                    )} ~ ${timeFormatFromUTCEpoch(item.endEpoch, 3)}`}</dd>
                    <dt className="list-label">
                      {formatMessage({ id: 'Time_13', defaultMessage: '교육 시간' })}
                    </dt>
                    <dd className="list-value">
                      <b>{`${Math.floor(
                        (item.eduStatus === 2 ? item.totalRunningTime : item.totalPlayTime) / 60,
                      ).toString()} / ${Math.floor(item.totalRunningTime / 60)}${formatMessage({
                        id: 'Time_2',
                        defaultMessage: '분',
                      }).toString()}`}</b>
                    </dd>
                  </dl>

                  <dl
                    className={`list-video-box ${openEduList.includes(item.eduNo) ? 'active' : ''}`}
                  >
                    <dt className="list-label">
                      {formatMessage({ id: 'Edu_62', defaultMessage: '영상 목록' })}
                    </dt>
                    <ol className="list-video">
                      {item.fileInfo?.map((file: any) => {
                        return (
                          <li key={file.fileNo} className="video-text">
                            {file.fileName}
                          </li>
                        );
                      })}
                    </ol>
                  </dl>

                  <div className="edu-progress-bar">
                    <div className="progress-percent" style={{ width: `${item.eduProgress}%` }} />
                  </div>

                  <div
                    className={`edu-list-arrow ${openEduList.includes(item.eduNo) ? 'open' : ''}`}
                    onClick={(e: any) => {
                      e.stopPropagation();
                      toggleOpen(item);
                    }}
                    aria-hidden="true"
                  >
                    {item.eduNo === selectedEduNo ? (
                      <img src="/img/edu/active_arrbtn.png" alt="arrow" />
                    ) : (
                      <img src="/img/edu/none_arrbtn.png" alt="arrow" />
                    )}
                  </div>
                </div>
              );
            })}
        </div>
      </div>

      {/* 우측 영역 */}
      <div className="edu-play-right">
        <div className="edu-play-header">
          <div className="edu-play-title">
            {eduTitle ||
              formatMessage(
                { id: 'Edu_71', defaultMessage: '{service} 악성 메일 대응 교육' },
                { service: Option.serviceName },
              )}
          </div>
          <Popover
            overlayClassName="edu-play-popover"
            placement="bottomRight"
            trigger="click"
            content={
              <>
                <div className="edu-my-box">
                  <img src="/img/edu/mybox_inner.png" alt="bg" />
                  <div className="edu-target-box">
                    <img src="/img/edu/my_i.png" alt="bg" />
                    <div className="edu-target-info">
                      <div className="edu-target-name">{userData?.eduTargetName}</div>
                      <div className="edu-target-id">{userData?.eduTargetId}</div>
                    </div>
                  </div>
                </div>
                <div className="logout-btn">
                  <button onClick={onLogout} type="button">
                    <img src="/img/edu/logout_i.png" alt="logout" />
                    {formatMessage({ id: 'AlreadyLogin_4', defaultMessage: '로그아웃' })}
                  </button>
                </div>
              </>
            }
          >
            <div className="my-info">
              <img src="/img/edu/my_i.png" alt="my" />
              <span className="name">{userData?.eduTargetName}</span>
            </div>
          </Popover>
        </div>

        {/* 상세 */}
        <div className="video-info">
          <span className="video-name">{currentData[selectedEduNo]?.attachName}</span>
          {eduStatusLabel}
          <div className="video-summary">
            <span className="summary-item">
              <span className="summary-label">
                {formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}
              </span>
              <span className="summary-value period">
                {`${timeFormatFromUTCEpoch(
                  currentData[selectedEduNo]?.startEpoch,
                  3,
                )} ~ ${timeFormatFromUTCEpoch(currentData[selectedEduNo]?.endEpoch, 3)}`}
              </span>
            </span>
            <span className="summary-item">
              <span className="summary-label">
                {formatMessage({ id: 'Time_13', defaultMessage: '교육 시간' })}
              </span>
              <span className="summary-value">
                <b>
                  {Math.floor(
                    (currentData[selectedEduNo]?.eduStatus === 2
                      ? currentData[selectedEduNo]?.totalRunningTime
                      : currentData[selectedEduNo]?.totalPlayTime) / 60,
                  ).toString()}
                </b>
                {` / ${Math.floor(
                  currentData[selectedEduNo]?.totalRunningTime / 60,
                ).toString()}${formatMessage({
                  id: 'Time_2',
                  defaultMessage: '분',
                })}`}
              </span>
            </span>
            <div className="summary-item percent">
              <span className="summary-label">
                {formatMessage({ id: 'Template_28', defaultMessage: '진행률' })}
              </span>
              <div className="edu-progress-bar">
                <div
                  className="progress-percent"
                  style={{
                    width: `${currentData[selectedEduNo]?.eduProgress}%`,
                  }}
                />
              </div>
              <span className="percent-text">{`${currentData[selectedEduNo]?.eduProgress}%`}</span>
            </div>
          </div>
        </div>

        <div className="video-tab">
          <ol className="tab-list">
            {currentData[selectedEduNo]?.fileInfo?.map((file: any, index: number) => {
              let active = '';
              let status = 'before';
              let message = formatMessage({ id: 'Status_18', defaultMessage: '학습 전' });
              const step =
                currentData[selectedEduNo]?.playStep === 0
                  ? 1
                  : currentData[selectedEduNo]?.playStep;

              if (currentData[selectedEduNo]?.eduStatus === 2) {
                if (step === index + 1) {
                  active = 'on';
                  status = 'ing';
                  message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
                } else {
                  status = 'finish';
                  message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
                }
              } else if (step === index + 1) {
                active = 'on';
                status = 'ing';
                message = formatMessage({ id: 'Status_19', defaultMessage: '학습 중' });
              } else if (step > index + 1) {
                status = 'finish';
                message = formatMessage({ id: 'Status_20', defaultMessage: '학습 완료' });
              }

              return (
                <Popover
                  placement="bottomLeft"
                  content={
                    <div>
                      {`${index + 1}. ${file.fileName}`}
                      <div className={`edu-play-tab-label ${status}`}>{message}</div>
                    </div>
                  }
                  key={file.fileNo}
                >
                  <li className={`tab-item ${active}`}>
                    {active
                      ? file.fileName
                      : `${formatMessage({
                          id: 'Edu_78',
                          defaultMessage: '영상',
                        })}${index + 1}`}
                  </li>
                </Popover>
              );
            })}
          </ol>
        </div>

        {/* 비디오 플레이어 */}
        {!quizScreen && (
          <div className="video-player">
            {videoOptions[selectedEduNo] && (
              <EduVideoPlayer
                curVideoData={currentData[selectedEduNo]}
                selectedEduNo={selectedEduNo}
                videoOption={videoOptions[selectedEduNo]}
                onReadyPlayerEvent={onReadyPlayerEvent}
              />
            )}
          </div>
        )}

        {/* 퀴즈 화면 */}
        {quizScreen && currentQuiz && currentQuiz[quizSeq] && (
          <div className="video-quiz">
            <div className="quiz-contents">
              <div className="quiz-text">Q. {currentQuiz[quizSeq].quizContent}</div>
              <div className="quiz-answer">
                <div
                  className={`btn-o ${answer === 1 ? 'selected' : ''}`}
                  onClick={() => setAnswer(1)}
                  aria-hidden="true"
                >
                  <img src="/img/edu/O.png" alt="O" />
                </div>
                <div
                  className={`btn-x ${answer === 2 ? 'selected' : ''}`}
                  onClick={() => setAnswer(2)}
                  aria-hidden="true"
                >
                  <img src="/img/edu/X.png" alt="X" />
                </div>
              </div>
              <button
                type="button"
                className={`next-quiz-btn ${answer ? 'active' : ''}`}
                onClick={onNextQuiz}
              >
                {allEnd && quizSeq === currentQuiz.length - 1
                  ? formatMessage({ id: 'Button_7', defaultMessage: '확 인' })
                  : formatMessage({ id: 'Button_7', defaultMessage: '다 음' })}
              </button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Eduplay;
