import { useEffect, useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import * as valid from 'utils/validation';
import parse from 'html-react-parser';
import { getShareService, addShare } from 'store/share';
import { changeState } from 'store/user';
import { updateServiceConfig } from 'store/config';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import MainTitle from 'components/common/admin/MainTitle';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormSwitchField from 'components/common/FormSwitchField';
import ShareCancel from 'components/branch/share/ShareCancel';
import '../mudmanage/Conf.scss';
import 'components/common/config/ConfigTemplate.scss';
import './Share.scss';

// import shareImage from 'img/share/share-service-01.png';
// import sharedImage from 'img/share/share-service-02.png';

export default function Share(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const shareData = useSelector((state: any) => state.share.data);
  const needReload = useSelector((state: any) => state.share.needReload);
  const userData = useSelector((state: any) => state.user.user);

  const { errors, control, register, setError, handleSubmit, clearErrors } = useForm({
    mode: 'onChange',
  });

  const [isModal, setIsModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [saveStatus, setSaveStatus] = useState(0);

  useEffect(() => {
    dispatch(getShareService());
  }, [needReload]);

  const addShareUser = async (formData: { [key: string]: any }) => {
    if (!loading) {
      try {
        // 최대 19명 제한
        if (shareData?.length > 19) {
          setError('shareUserEmail', {
            type: 'limit',
            message: formatMessage(
              {
                id: 'ServiceShare_31',
                defaultMessage: '공유 사용자는 {count}명을 초과할 수 없습니다.',
              },
              { count: 19 },
            ),
          });
          return;
        }
        setLoading(true);
        const respObj: any = await dispatch(addShare({ shareUserEmail: formData.shareUserEmail }));
        if (respObj?.status === 200 && respObj.data) {
          if (respObj.data.list) {
            setIsModal(false);
          } else if (respObj?.data.error && respObj.data.error[0].errInfo) {
            if (respObj.data.error[0].errInfo === 'Duplicate User Exist') {
              setError('shareUserEmail', {
                type: 'error',
                message: formatMessage({
                  id: 'Email_9',
                  defaultMessage: '이미 등록된 이메일입니다.',
                }),
              });
            } else if (respObj.data.error[0].errInfo === 'User Not Exist') {
              setError('shareUserEmail', {
                type: 'error',
                message: formatMessage({ id: 'Target_14', defaultMessage: '대상자가 없습니다.' }),
              });
            } else {
              setError('shareUserEmail', {
                type: 'error',
                message: respObj.data.error[0].errInfo,
              });
            }
          }
        } else {
          console.log('실패');
        }
      } catch (e) {
        console.log(e);
      }
      setLoading(false);
    }
  };

  const myService = [];
  const otherService = [];

  for (let i = 0; i < shareData.length; i += 1) {
    const d = shareData[i];
    if (userData.originalServiceNo === d.serviceNo) {
      if (userData.userNo !== d.userNo) {
        myService.push(
          <div key={d.userServiceNo} className="share-content-box">
            <div className="share-box">
              <img src="/img/share/big-share-off.png" alt="share" className="default-image" />
              <div className="share-area">
                <div className="content">{`${d.userName} (${d.userEmail})`}</div>
                <div className="sub-content">
                  {`${formatMessage({
                    id: 'Button_22',
                    defaultMessage: '등록',
                  })}: ${timeFormatFromUTCEpoch(d.regEpoch, 2)}`}
                </div>
              </div>
            </div>
            <ShareCancel shareInfo={d} mode="user" />
          </div>,
        );
      }
    } else {
      otherService.push(
        <div key={d.userServiceNo} className="share-content-box">
          <div className="share-box">
            <img src="/img/share/big-share-off.png" alt="share" className="default-image" />
            <div className="share-area">
              <div className="content">
                {`${d.serviceName} / ${d.serviceUserName} (${d.serviceUserEmail})`}
              </div>
              <div className="sub-content">
                {`${formatMessage({
                  id: 'Button_22',
                  defaultMessage: '등록',
                })}: ${timeFormatFromUTCEpoch(d.regEpoch, 2)}`}
              </div>
            </div>
          </div>
          <ShareCancel shareInfo={d} mode="service" />
        </div>,
      );
    }
  }

  // 기본 서비스 설정 스위치 변경
  const changeSwitch = async (name: string, data: number) => {
    try {
      const params = {
        parameter: name,
        nowValue: data,
      };

      const response: any = await dispatch(updateServiceConfig(params));
      if (!response?.data?.error) {
        setSaveStatus(1);
      }
      // user 정보 업데이트
      dispatch(changeState({ name: 'user', value: { ...userData, [name]: data } }));
    } catch (error) {
      setSaveStatus(2);
      console.log('Share changeSwitch', error);
    }
  };

  return (
    <div className="admin-basic-conf">
      <MainTitle
        className="small"
        title={formatMessage({ id: 'ServiceShare_1', defaultMessage: '서비스 공유' })}
      />

      <div className="config-template">
        <div className="config-class">
          <div className="config-class-box">
            <div className="service-share-title">
              {formatMessage({
                id: 'ServiceShare_4',
                defaultMessage: '내 서비스 이용이 허가된 타 사용자',
              })}
            </div>
            {myService.length > 19 && (
              <div className="error-message mt-5">
                <span>
                  {formatMessage(
                    {
                      id: 'ServiceShare_31',
                      defaultMessage: '공유 사용자는 {count}명을 초과할 수 없습니다.',
                    },
                    { count: 19 },
                  )}
                </span>
                <span>
                  {` ${formatMessage(
                    { id: 'ServiceShare_32', defaultMessage: '{deleteCount}명을 삭제 해주세요.' },
                    { deleteCount: myService.length - 19 },
                  )}`}
                </span>
              </div>
            )}
            <div className="sharing-user">
              {myService.length > 0 ? (
                <div className="shared-users">{myService}</div>
              ) : (
                <div className="service-share-content">
                  <div className="service-share-content-area">
                    <div className="service-share-img">
                      <img src="/img/share/share-service-01.png" alt="share" />
                    </div>
                    <div className="service-share-content-text">
                      <div className="service-share-content-text-header">
                        {parse(
                          formatMessage({
                            id: 'ServiceShare_6',
                            defaultMessage:
                              '내 서비스를 공유하여 <br/>타 사용자와 함께 관리할 수 있습니다.',
                          }),
                        )}
                      </div>
                      <br />
                      {parse(
                        formatMessage({
                          id: 'ServiceShare_7',
                          defaultMessage:
                            '지금 공유 사용자를 추가해 보세요<br /> 옆자리 동료와 함께 대상자 관리 및 훈련 실시등<br />모든 서비스를 공유할 수 있습니다.',
                        }),
                      )}
                    </div>
                  </div>
                  <div className="service-share-notice">
                    {parse(
                      formatMessage({
                        id: 'ServiceShare_10',
                        defaultMessage:
                          '※ 공유 사용자는 서비스 가입자와 동일한 권한을 갖습니다.<br />단, 마이페이지의 "내 정보","서비스 공유" 메뉴는 이용할 수 없습니다.',
                      }),
                    )}
                  </div>
                </div>
              )}
            </div>
            <div className="service-share-bt">
              <button type="button" onClick={() => setIsModal(true)}>
                {formatMessage({ id: 'ServiceShare_13', defaultMessage: '공유 사용자 추가' })}
              </button>
            </div>
            <div className="service-share-title">
              {formatMessage({
                id: 'ServiceShare_5',
                defaultMessage: '내가 이용 가능한 타 서비스',
              })}
            </div>
            {otherService.length > 19 && (
              <div className="error-message mt-5">
                <span>
                  {formatMessage(
                    {
                      id: 'ServiceShare_33',
                      defaultMessage: '공유 서비스는 {count}개를 초과할 수 없습니다.',
                    },
                    { count: 19 },
                  )}
                </span>
                <span>
                  {` ${formatMessage(
                    { id: 'ServiceShare_34', defaultMessage: '{deleteCount}개를 삭제 해주세요.' },
                    { deleteCount: otherService.length - 19 },
                  )}`}
                </span>
              </div>
            )}
            <div className="sharing-user">
              {otherService.length > 0 ? (
                <div className="shared-users">{otherService}</div>
              ) : (
                <div className="service-share-content">
                  <div className="service-share-content-area">
                    <div className="service-share-img">
                      <img src="/img/share/share-service-02.png" alt="share" />
                    </div>
                    <div className="service-share-content-text">
                      <div className="service-share-content-text-header">
                        {parse(
                          formatMessage({
                            id: 'ServiceShare_9',
                            defaultMessage:
                              '타 사용자의 서비스를<br />본인 서비스처럼 이용할 수 있습니다.',
                          }),
                        )}
                      </div>
                      <br />
                      {parse(
                        formatMessage({
                          id: 'ServiceShare_12',
                          defaultMessage:
                            '타 사용자의 서비스를 공유 받으면<br />본인 서비스처럼 모든 기능을 이용할 수 있습니다.',
                        }),
                      )}
                    </div>
                  </div>
                  <div className="service-share-notice">
                    {parse(
                      formatMessage({
                        id: 'ServiceShare_8',
                        defaultMessage:
                          '※ 단, 공유 받은 서비스의 마이페이지 메뉴 중<br /> "내 정보", "서비스 공유" 메뉴는 이용할 수 없습니다.',
                      }),
                    )}
                  </div>
                </div>
              )}
            </div>

            {otherService.length > 0 && (
              <>
                <div className="share-switch-wrap">
                  <FormSwitchField
                    control={control}
                    name="lastServiceByAccessTime"
                    handleOnChange={changeSwitch}
                    defaultValue={userData.lastServiceByAccessTime}
                  />
                  <div className="share-label">
                    {formatMessage({
                      id: 'ServiceShare_11',
                      defaultMessage: '기본 서비스 설정 - 로그인 시 마지막으로 접속한 서비스 유지',
                    })}
                  </div>
                </div>
                {!!saveStatus && (
                  <div className="save-result">
                    {saveStatus === 1
                      ? `${formatMessage({
                          id: 'Config_11',
                          defaultMessage: '저장되었습니다',
                        })}! (${timeFormatFromUTCEpoch(new Date().getTime() / 1000, 10)})`
                      : formatMessage({ id: 'Config_12', defaultMessage: '저장되지 않음' })}
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
      {isModal && (
        <ModalTemplate
          className="modal-464"
          visible={isModal}
          onOk={handleSubmit(addShareUser)}
          onCancel={() => {
            clearErrors('shareUserEmail');
            setIsModal(false);
          }}
          centered
          loading={loading}
          disabled={errors.shareUserEmail}
          title={formatMessage({ id: 'ServiceShare_13', defaultMessage: '공유 사용자 추가' })}
          okText={formatMessage({ id: 'Button_19', defaultMessage: '추 가' })}
          cancelText={formatMessage({ id: 'Button_5', defaultMessage: '닫 기' })}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'ServiceShare_14',
              defaultMessage: '공유할 대상자의 이메일을 입력하세요.',
            })}
            <br />
            {formatMessage({
              id: 'ServiceShare_16',
              defaultMessage: '본 서비스에 가입된 대상자만 가능합니다.',
            })}
          </div>
          <div className="modal-border-box">
            <div className="input-title">
              {formatMessage({ id: 'ServiceShare_15', defaultMessage: '공유 사용자 이메일' })}
            </div>

            <FormTextField
              name="shareUserEmail"
              error={errors.shareUserEmail}
              register={register}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                  mail: (value: any) => valid.email(value),
                },
              }}
            />
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}
