import { Select } from 'antd';
import { Controller } from 'react-hook-form';
import './FormField.scss';

export default function FormSelectField(props: any) {
  const { Option } = Select;
  const {
    className,
    name,
    defaultValue,
    control,
    handleOnChange,
    menuList,
    mode,
    option,
    tagRender,
    placeholder,
    showArrow,
    dropdownClassName,
    disabled,
    validation,
    value,
    error,
    filterOption,
    onBlur,
    onSearch,
    autoFocus,
    listHeight,
    errorMessage,
    errorTooltip
  } = props;
  const item: any = [];

  let selected = '';
  if (option) {
    item.push(option);
  } else {
    for (let i = 0; i < menuList.length; i += 1) {
      item.push(
        <Option key={menuList[i].key} value={menuList[i].value}>
          {menuList[i].text}
        </Option>,
      );

      if (menuList[i].value === defaultValue) {
        selected = menuList[i].text;
      }
    }
  }

  return (
    <div className="form-field-wrap">
      <div className="form-wrap">
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ onChange }) => (
            <Select
              getPopupContainer={(triggerNode) => triggerNode.parentElement}
              className={`${className} ${error && 'warning'}`}
              dropdownClassName={dropdownClassName}
              defaultValue={selected || []}
              onChange={(data) => {
                onChange(data);
                if (handleOnChange) {
                  handleOnChange(name, data);
                }
              }}
              tagRender={tagRender || null}
              mode={mode || null}
              showArrow={showArrow}
              placeholder={placeholder || null}
              disabled={disabled}
              {...(value ? { value: value } : {})} // eslint-disable-line
              filterOption={filterOption || true}
              onBlur={onBlur || null}
              onSearch={onSearch || null}
              autoFocus={autoFocus || false}
              listHeight={listHeight || 256}
            >
              {item}
            </Select>
          )}
        />
        {errorMessage && <div className="error-message mt-5">{errorMessage}</div>}
        {errorTooltip && <div className="arrow-box top">{errorTooltip}</div> }
      </div>
    </div>
  );
}
