import { useIntl } from 'react-intl';
import { Popover, Tooltip } from 'antd';
import ListStep from 'components/common/ListStep';
import { timeFormatFromUTCEpoch } from 'utils/commonFunctions';

// import eduListImage from 'img/edu/list_edu.png';
// import notyetListImage from 'img/edu/list_notyet.png';
// import proceedListImage from 'img/edu/list_proceed.png';
// import readListImage from 'img/exam/list_read.png';
// import sendListImage from 'img/exam/list_send.png';

// import sendImage from 'img/exam/step/send.png';
// import readImage from 'img/exam/step/read.png';
// import progressImage from 'img/edu/step/edu_progress.png';
// import endImage from 'img/edu/step/edu_end.png';

// import sendActiveImage from 'img/exam/step/send_a.png';
// import readActiveImage from 'img/exam/step/read_a.png';
// import progressActiveImage from 'img/edu/step/edu_progress_a.png';
// import endActiveImage from 'img/edu/step/edu_end_a.png';

// import lineReadImage from 'img/exam/step/line_read.png';
// import lineProgressImage from 'img/edu/step/line_edu_progress.png';
// import lineEndImage from 'img/edu/step/line_edu_end.png';

// import circleSendImage from 'img/exam/step/circle_send.png';
// import circleReadImage from 'img/exam/step/circle_read.png';
// import circleProgressImage from 'img/edu/step/circle_edu_progress.png';
// import circleEndImage from 'img/edu/step/circle_edu_end.png';

interface eduItemProps {
  data: any;
  selectedEduNo: string | number;
  eduFileInfo: any;
  quizInfo: any;
}

function EduItem({ data, selectedEduNo, eduFileInfo, quizInfo }: eduItemProps) {
  const { formatMessage } = useIntl();

  // 전체 교육 상태 표시 데이터
  const stepDataAll = [
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_edu.png', // eduListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Edu_3', defaultMessage: '교육' })}: ${data.eduCount}`,
      count: data.eduCount,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_send.png', // sendListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Send_1', defaultMessage: '발송' })}: ${data.send}`,
      count: data.send,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_read.png', // readListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Read_1', defaultMessage: '열람' })}: ${data.read}`,
      count: data.read,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_notyet.png', // notyetListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Status_15', defaultMessage: '진행전' })}: ${
        data.before
      }`,
      count: data.before,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_proceed.png', // proceedListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}: ${data.play}`,
      count: data.play,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/list_end.png', // endListImage,
      },
      tooltipTitle: `${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}: ${data.after}`,
      count: data.after,
    },
  ];

  let sendTooltip = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
  if (data.send) {
    sendTooltip += `: ${timeFormatFromUTCEpoch(data.send)}`;
  } else if (data.eduStatus !== 1 && data.eduStatus !== 2) {
    sendTooltip = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
  }

  // 진행률
  let stepPlayTime = 0;
  let runningTime = 0;
  let runningFileName = '';
  if (eduFileInfo) {
    eduFileInfo.forEach((file: any, index: number) => {
      // 컨텐츠 총 시간
      runningTime += file.runningTime;
      if (data.playStep) {
        if (data.playStep - 1 > index && data.eduStatus === 1) {
          stepPlayTime += file.runningTime;
        } else if (data.playStep - 1 === index) {
          runningFileName = file.fileName;
        }
      }
    });

    if (data.playStep) {
      // 진행중일 때
      stepPlayTime += data.playTime;
    } else if (data.eduStatus === 2) {
      // 완료일 때
      stepPlayTime = runningTime;
    }
  }

  // 교육 상태 메시지
  let statusText = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
  let statusColor = '';
  if (data.eduStatus === 1) {
    statusText = formatMessage({ id: 'Status_8', defaultMessage: '진행중' });
    statusColor = 'color-green';
  } else if (data.eduStatus === 2) {
    statusText = formatMessage({ id: 'Status_16', defaultMessage: '완료' });
    statusColor = 'color-red';
  } else if (data.read) {
    statusText = formatMessage({ id: 'Read_1', defaultMessage: '열람' });
    statusColor = 'color-orange';
  } else if (data.send) {
    statusText = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
  }

  // 개별 교육 상태 표시 데이터
  const stepDataEach = [
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/step/send.png', // sendImage,
        active: data.send || data.eduStatus === 1 || data.eduStatus === 2,
        activeIcon: '/img/exam/step/send_a.png', // sendActiveImage,
        circleIcon: '/img/exam/step/circle_send.png', // circleSendImage,
      },
      tooltipTitle: sendTooltip,
      count: data.send,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/exam/step/read.png', // readImage,
        active: data.read || data.eduStatus === 1 || data.eduStatus === 2,
        activeIcon: '/img/exam/step/read_a.png', // readActiveImage,
        circleIcon: '/img/exam/step/circle_read.png', // circleReadImage,
        lineIcon: '/img/exam/step/line_read.png', // lineReadImage,
      },
      tooltipTitle: `${formatMessage({
        id: 'Read_1',
        defaultMessage: '열람',
      })}: ${timeFormatFromUTCEpoch(data.read)}`,
      count: data.read,
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/step/edu_progress.png', // progressImage,
        active: data.eduStatus && (data.eduStatus === 1 || data.eduStatus === 2),
        activeIcon: '/img/edu/step/edu_progress_a.png', // progressActiveImage,
        circleIcon: '/img/edu/step/circle_edu_progress.png', // circleProgressImage,
        lineIcon: '/img/edu/step/line_edu_progress.png', // lineProgressImage,
      },
      tooltipTitle: data.playStep
        ? `${formatMessage({ id: 'Status_8', defaultMessage: '진행중' })} (Step ${
            data.playStep
          }. ${runningFileName})`
        : formatMessage({ id: 'Status_8', defaultMessage: '진행중' }),
      count: data.playStep,
      badgeText: data.eduStatus === 1 ? data.playStep : '',
    },
    {
      view: true,
      icon: {
        stepIcon: '/img/edu/step/edu_end.png', // endImage,
        active: data.eduStatus && data.eduStatus === 2,
        activeIcon: '/img/edu/step/edu_end_a.png', // endActiveImage,
        circleIcon: '/img/edu/step/circle_edu_end.png', // circleEndImage,
        lineIcon: '/img/edu/step/line_edu_end.png', // lineEndImage,
      },
      tooltipTitle: data.finish
        ? `${formatMessage({ id: 'Status_16', defaultMessage: '완료' })}: ${timeFormatFromUTCEpoch(
            data.finish,
          )}`
        : formatMessage({ id: 'Status_16', defaultMessage: '완료' }),
      count: data.finish,
    },
  ];

  // 개별 훈련 퀴즈 점수
  let eduScore = 0;
  if (selectedEduNo !== 'all') {
    let passCount = 0;
    if (data.quizSolutionData && quizInfo?.length > 0) {
      quizInfo.forEach((quiz: any) => {
        if (data.quizSolutionData[quiz.quizNo]?.result === 'pass') {
          passCount += 1;
        }
      });
    }
    eduScore = passCount && quizInfo?.length > 0 ? (passCount / quizInfo?.length) * 100 : 0;
  }

  return (
    <div className="list-item">
      {/* 대상자 */}
      <div className="email-column">
        <Popover
          overlayClassName="between-popover full"
          placement="topLeft"
          content={
            <>
              <div className="popover-row">
                <div className="text">
                  {formatMessage({ id: 'Target_10', defaultMessage: '대상자 번호' })}
                </div>
                <div className="value">{data.targetNo}</div>
              </div>
              <div className="popover-row">
                <div className="text">
                  {formatMessage({ id: 'Name_1', defaultMessage: '이름' })}
                </div>
                <div className="value">{data.targetName}</div>
              </div>
              <div className="popover-row">
                <div className="text">
                  {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                </div>
                <div className="value">{data.targetEmail}</div>
              </div>
              {!!data.targetPhone && (
                <div className="popover-row">
                  <div className="text">
                    {formatMessage({ id: 'Contact_1', defaultMessage: '연락처' })}
                  </div>
                  <div className="value">{data.targetPhone}</div>
                </div>
              )}
            </>
          }
        >
          <div className="ellipsis">{`${data.targetName} (${data.targetEmail})`}</div>
        </Popover>
      </div>
      {/* 소속/직급 */}
      <div className="list-column">
        <Tooltip
          overlayClassName="black-tooltip"
          placement="bottom"
          title={`${formatMessage({ id: 'Division_1', defaultMessage: '소속' })}: ${
            data.targetDivision || '-'
          } / ${formatMessage({ id: 'Position_1', defaultMessage: '직급' })}: ${
            data.targetPosition || '-'
          } `}
        >
          <div className="target-company ellipsis">{`${data.targetDivision || '-'} / ${
            data.targetPosition || '-'
          }`}</div>
        </Tooltip>
      </div>
      {/* 교육 상태 */}
      <div className="status-column">
        {selectedEduNo === 'all' ? (
          stepDataAll.map(
            (item: any) =>
              item.view && (
                <ListStep
                  tooltipTitle={item.tooltipTitle}
                  icon={item.icon}
                  count={item.count}
                  countColor={item.countColor}
                  key={item.tooltipTitle}
                />
              ),
          )
        ) : (
          <>
            <div className={`status-text ${statusColor}`}>{statusText}</div>
            {stepDataEach.map(
              (item: any) =>
                item.view && (
                  <ListStep
                    tooltipTitle={item.tooltipTitle}
                    icon={item.icon}
                    count={item.count}
                    countColor={item.countColor}
                    badgeText={item.badgeText}
                    key={item.tooltipTitle}
                  />
                ),
            )}
          </>
        )}
      </div>

      {selectedEduNo !== 'all' && (
        <div className="list-column">
          <div>
            <span className="bold">{eduScore.toFixed(0,)}</span>
            <span>{formatMessage({ id: 'Edu_26', defaultMessage: '점' })}</span>
          </div>
        </div>
      )}
      <div className="list-column">
        {selectedEduNo === 'all' ? (
          <>
            <div>{formatMessage({ id: 'Edu_25', defaultMessage: '학습률' })}</div>
            <div className="edu-percent">{`${((data.after / data.eduCount) * 100).toFixed(
              0,
            )}%`}</div>
          </>
        ) : (
          <>
            <div>{formatMessage({ id: 'Template_28', defaultMessage: '진행률' })}</div>
            <div className="edu-percent">{`${((stepPlayTime / runningTime) * 100).toFixed(
              0,
            )}%`}</div>
          </>
        )}
      </div>
    </div>
  );
}

export default EduItem;
