export const apiUrl = '/api/v1/';
export const execUrl = '/exec/';

// 현재 버전
export const updateVersion = 'v1.10.2';

export const Option: any = {
  isSaas: 0,
  isGs: 0,
  isJapan: 0,
  isEdu: 0,
  isDeclare: 0,
  isEng: 1,
  isKNBank: 0,
  customer: '',
  serviceName: '',
};

export const changeOptions = (options: any) => {
  Object.keys(options).forEach((key) => {
    const value = options[key];
    if (key === 'customer' && value) {
      Option.customer = value?.replace(/['"]+/g, '');
    } else if (key === 'serviceName' && value) {
      Option.serviceName = value;
      document.title = value;
    } else {
      Option[key] = value;
    }
  });
};
