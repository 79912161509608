import React, { useEffect, useState } from 'react';
// import ReactPlayer from 'react-player';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';
import './VideoPlayer.scss';

function VideoPlayer({ videoPlayerOption, currentIndex }: any) {
  // videojs 플레이어
  let player: any = null;
  // 비디오 노드
  let videoNode: any = null;

  useEffect(() => {
    // 영상 변경 시 해당 인덱스의 src 넣어줌
    player = videojs(videoNode, videoPlayerOption);
    player.src(videoPlayerOption.sources[currentIndex]);
  }, [currentIndex]);

  return (
    <div data-vjs-player>
      <video
        ref={function (node: any) {
          videoNode = node;
        }}
        className="video-js vjs-big-play-centered"
      >
        <track kind="captions" />
      </video>
    </div>
  );
}

export default VideoPlayer;
