import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { timeDiffFromSeconds } from 'utils/commonFunctions';
import FormSelectField from 'components/common/FormSelectField';
import ModalTemplate from 'components/common/ModalTemplate';
import VideoPlayer from 'components/branch/eduTemplate/VideoPlayer';

function EduTemplateVideo({ visible, dataInfo, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedVideo, setSelectedVideo] = useState(dataInfo?.eduTagData[0].fileNo);
  const { errors, control, register, getValues, setValue, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const { eduTagData } = dataInfo;

  // 영상 목록 드롭다운
  const videoMenuList: Array<any> = [];
  // 영상 url 리스트
  const videoSourceList: Array<any> = [];
  // 총 영상 시간
  let totalTime = 0;

  eduTagData?.forEach((videoData: any) => {
    videoMenuList.push({
      key: videoData.fileNo,
      value: videoData.fileNo,
      text: videoData.fileName,
    });

    videoSourceList.push({
      src: videoData.filePath,
      type: 'video/mp4',
    });

    totalTime += videoData.runnungTime;
  });

  // 동영상 플레이어 옵션
  const videoPlayerOption = {
    height: 390,
    autoPlay: false,
    playbackRates: [1, 1.25, 1.5, 2],
    controls: true,
    sources: videoSourceList,
    controlBar: {
      pictureInPictureToggle: false,
    },
    nativeTextTracks: true,
  };

  // 영상 변경 시
  const changeVideo = (name: string, data: number) => {
    setSelectedVideo(data);
  };

  const currentVideoData = eduTagData.filter((data: any) => data.fileNo === selectedVideo)[0];

  return (
    <ModalTemplate
      className="edu-template-video-modal modal-665"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {formatMessage({ id: 'Edu_57', defaultMessage: '영상 보기' })}
          </div>
        </div>
      }
      onCancel={() => toggleModal(false)}
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      //   loading={loading}
    >
      {/* <Loading loading={loading} /> */}

      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 영상 목록 */}
        <div className="input-title">
          {formatMessage({ id: 'Edu_62', defaultMessage: '영상 목록' })}
        </div>
        <FormSelectField
          name="fileNo"
          control={control}
          error={errors.fileNo}
          menuList={videoMenuList}
          handleOnChange={changeVideo}
          value={selectedVideo}
          showArrow
        />

        {/* 영상 미리보기 */}
        <div className="text-field-title">
          {formatMessage({ id: 'Edu_58', defaultMessage: '영상 미리보기' })}
        </div>
        <div className="video-player-box">
          <div className="video-header">
            <div className="video-header-left">
              <div className="video-title">{dataInfo.attachName}</div>
              <div className="video-sub-title">{currentVideoData?.fileName}</div>
            </div>
            <div className="video-header-right">
              <div>
                {`${formatMessage({
                  id: 'Time_12',
                  defaultMessage: '총 영상 시간',
                })}: ${timeDiffFromSeconds(totalTime)}`}
              </div>
              <div>
                {`${formatMessage({
                  id: 'Time_11',
                  defaultMessage: '영상 시간',
                })}: ${timeDiffFromSeconds(currentVideoData?.runnungTime)}`}
              </div>
            </div>
          </div>
          <div className="video-player">
            <VideoPlayer
              videoPlayerOption={videoPlayerOption}
              currentIndex={eduTagData.findIndex((data: any) => data.fileNo === selectedVideo)}
            />
          </div>
        </div>
      </form>
    </ModalTemplate>
  );
}

export default EduTemplateVideo;
