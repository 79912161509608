import { Redirect, Route, useRouteMatch, Switch, useLocation, withRouter } from "react-router-dom";
import { useIntl } from 'react-intl';
import AutoRoute from "components/common/AutoRoute"
import { Option } from 'utils/commonValues';
import { Menu } from 'antd';
import './Template.scss'
import {
  VideoCameraOutlined,
  GroupOutlined
} from '@ant-design/icons';

export const authLevel = 1;
function Template(props: any) {
  const { formatMessage } = useIntl();
  const { url } = useRouteMatch();
  const { pathname } = useLocation();

  let activeLink = 'examtemplate'
  if (pathname) {
    [activeLink] = pathname.split('/').slice(-1);
  }

  return (
    <div className="common-content-layout">
      <div className="common-left-side">
        <Menu className="template-side-menu" selectedKeys={[activeLink]}>
          <Menu.Item key="examtemplate" className="template-side-menu-item" onClick={() => props.history.push("/template/examtemplate")}>
            <div style={{ margin: "0 0 0 10px" }}>
              <GroupOutlined className="mudmanage-tab-icon" />
              {formatMessage({ id: "Template_1", defaultMessage: "훈련 템플릿" })}
            </div>
          </Menu.Item>
          <Menu.Item key="phishingpage" className="template-side-menu-item submenu" onClick={() => props.history.push("/template/phishingpage")}>
            <div style={{ margin: "0 0 0 10px" }}>
              {formatMessage({ id: "Phishing_1", defaultMessage: "피싱 페이지" })}
            </div>
          </Menu.Item>
          {Option.isEdu === 1 && (
            <>
              <Menu.Item key="edutemplate" className="template-side-menu-item" onClick={() => props.history.push("/template/edutemplate")}>
                <div style={{ margin: "0 0 0 10px" }}>
                  <VideoCameraOutlined className="mudmanage-tab-icon" />
                  {formatMessage({ id: "Edu_4", defaultMessage: "교육 템플릿" })}
                </div>
              </Menu.Item>
              <Menu.Item key="quiz" className="template-side-menu-item submenu" onClick={() => props.history.push("/template/quiz")}>
                <div style={{ margin: "0 0 0 10px" }}>
                  {formatMessage({ id: "Edu_63", defaultMessage: "퀴즈" })}
                </div>
              </Menu.Item>
            </>
          )}
        </Menu>
      </div>
      <div className="common-content">
        <Switch>
          <Route path={`${url}/:name`} component={AutoRoute} />
          <Redirect path="*" to="/template/examtemplate" />
        </Switch>
      </div>
    </div>
  );
}
export default withRouter(Template);
