import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch, useSelector } from 'react-redux';
import { Controller, useForm } from 'react-hook-form';
import { Cell, Pie, PieChart, Sector, Tooltip as ChartTooltip } from 'recharts';
import { Select, Tag, Tooltip } from 'antd';
import {
  CheckOutlined,
  CheckSquareFilled,
  CloseCircleFilled,
  CloseOutlined,
  DeleteFilled,
  EditOutlined,
  PlusCircleFilled,
  PlusOutlined,
} from '@ant-design/icons';
import {
  changeTargetState,
  putTarget,
  putTagTarget,
  getMergeDetail,
  getExamHistory,
  getEduHistory,
  changeTargetExamState,
  postTagTarget,
  deleteTagTarget,
} from 'store/target';
import { openNotification, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import { Option } from 'utils/commonValues';
import * as valid from 'utils/validation';
import FormTextField from 'components/common/FormTextField';
import FormSelectField from 'components/common/FormSelectField';
import Loading from 'components/common/Loading';
import ListStep from 'components/common/ListStep';
import ModalTemplate from 'components/common/ModalTemplate';
import CountInfo from 'components/branch/exam/CountInfo';
import TargetExamChart from 'components/branch/target/TargetExamChart';

// import openImage from 'img/tag/ico_open.png';
// import closeImage from 'img/tag/ico_close.png';
// import declareOnImage from 'img/declare/declare_on.png';
// import declareImage from 'img/declare/declare_off.png';

// import sendImage from 'img/exam/step/send.png';
// import readImage from 'img/exam/step/read.png';
// import progressImage from 'img/edu/step/edu_progress.png';
// import endImage from 'img/edu/step/edu_end.png';

// import sendActiveImage from 'img/exam/step/send_a.png';
// import readActiveImage from 'img/exam/step/read_a.png';
// import progressActiveImage from 'img/edu/step/edu_progress_a.png';
// import endActiveImage from 'img/edu/step/edu_end_a.png';

// import lineReadImage from 'img/exam/step/line_read.png';
// import lineProgressImage from 'img/edu/step/line_edu_progress.png';
// import lineEndImage from 'img/edu/step/line_edu_end.png';

// import circleSendImage from 'img/exam/step/circle_send.png';
// import circleReadImage from 'img/exam/step/circle_read.png';
// import circleProgressImage from 'img/edu/step/circle_edu_progress.png';
// import circleEndImage from 'img/edu/step/circle_edu_end.png';

interface detailProps {
  detailType: string;
  detailData: any;
  editInfo: any;
  dataByTagNo: any;
  tagData: any;
  selectTagList: Array<any>;
  selectTargetList: Array<any>;
  isAllTargetCheck: boolean;
}

function TargetDetail({
  detailType,
  detailData,
  editInfo,
  dataByTagNo,
  tagData,
  selectTagList,
  selectTargetList,
  isAllTargetCheck,
}: detailProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { errors, control, register, handleSubmit, getValues, setValue } = useForm({
    mode: 'onChange',
  });
  const [selectedTag, setSelectedTag]: any = useState([]);
  const [disableSaveBtn, setDisableSaveBtn] = useState(false);
  const [loading, setLoading] = useState(false);
  const [openModal, setOpenModal] = useState('');
  const [deleteTagMenu, setDeleteTagMenu]: any = useState({});
  const [tagSearchText, setTagSearchText] = useState('');
  const values = getValues();

  // 여러 대상자 선택 시 데이터
  const mergeDetailData = useSelector((state: any) => state.target.mergeDetail);

  // 교육 이력 데이터
  const targetEduData = useSelector((state: any) => {
    return {
      data: state.target.targetEdu.data,
      param: state.target.targetEdu.param,
      page: state.target.targetEdu.page,
      totalPages: state.target.targetEdu.totalPages,
    };
  });

  // 훈련 이력 데이터
  const targetExamData = useSelector((state: any) => {
    return {
      data: state.target.targetExam.data,
      param: state.target.targetExam.param,
      page: state.target.targetExam.page,
      totalPages: state.target.targetExam.totalPages,
      openList: state.target.targetExam.openList,
      chartData: state.target.targetExam.chartData,
    };
  });

  useEffect(() => {
    if (detailType === 'single') {
      onGetExamHistory(true);
      onGetEduHistory(true);
      setSelectedTag(detailData?.tagList);
      // 출력모드로 초기화
      dispatch(changeTargetState({ key: 'editInfo', value: { editTarget: '', editValue: '' } }));
    } else if (detailType === 'multi') {
      onGetMergeDetail();
      setSelectedTag([]);
      setDeleteTagMenu({});
    }
  }, [detailData, selectTargetList, selectTagList]);

  useEffect(() => {
    // 태그 일괄 추가/삭제 모달 닫을 시 선택된 태그리스트 값 초기화
    if (!openModal && detailType === 'multi') {
      setSelectedTag([]);
    }
  }, [openModal]);

  // 대상자 상세 정보
  const targetInfo = detailData && [
    {
      title: formatMessage({ id: 'Target_8', defaultMessage: '대상자 정보' }),
      contents: [
        {
          text: formatMessage({ id: 'Exam_69', defaultMessage: '번호' }),
          value: detailData.targetNo,
        },
        {
          text: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
          value: detailData.targetName,
          editKey: 'targetName',
          validation: ['required', 'name'],
        },
        {
          text: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
          value: detailData.targetEmail,
          editKey: 'targetEmail',
          validation: ['required', 'email'],
        },
        {
          text: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
          value: detailData.targetPhone || '-',
          editKey: 'targetPhone',
          validation: ['userPhone'],
        },
        {
          text: formatMessage({ id: 'Division_1', defaultMessage: '소속' }),
          value: detailData.targetDivision || '-',
          editKey: 'targetDivision',
          validation: ['name'],
        },
        {
          text: formatMessage({ id: 'Position_1', defaultMessage: '직급' }),
          value: detailData.targetPosition || '-',
          editKey: 'targetPosition',
          validation: ['name'],
        },
        {
          text: formatMessage({ id: 'Login_10', defaultMessage: '등록한 계정' }),
          value: `${detailData.userName || '-'}${
            detailData.userEmail ? `(${detailData.userEmail})` : ''
          }`,
        },
        {
          text: formatMessage({ id: 'Date_19', defaultMessage: '등록일' }),
          value: timeFormatFromUTCEpoch(detailData.regEpoch, 2),
        },
        {
          text: formatMessage({ id: 'Date_21', defaultMessage: '마지막 수정일' }),
          value: timeFormatFromUTCEpoch(detailData.modifyEpoch, 2),
        },
      ],
    },
  ];

  // 태그 메뉴 리스트
  const tagOption: any = [];
  if (tagData.length > 0) {
    let tagList = [...tagData];
    if (selectedTag?.length > 0) {
      tagList = tagData.filter((tagItem: any) => {
        return !selectedTag.includes(tagItem?.tagNo);
      });
    }

    tagList.forEach((tagItem: any) => {
      if (tagItem) {
        tagOption.push(
          <Select.Option key={tagItem.tagNo} value={tagItem.tagNo}>
            <div className={`tag-color-dot tag-${tagItem.color}`} />
            {dataByTagNo[tagItem.tagNo]?.tagName}
          </Select.Option>,
        );
      }
    });
  }

  // 태그 일괄 삭제 메뉴 리스트
  let deleteTagOption: any = [];
  if (Object.keys(deleteTagMenu).length > 0) {
    let tagList = Object.keys(deleteTagMenu);
    if (selectedTag?.length > 0) {
      tagList = Object.keys(deleteTagMenu).filter((key: any) => {
        return !selectedTag.includes(deleteTagMenu[key]?.tagNo);
      });
    }

    tagList.forEach((key: any) => {
      deleteTagOption.push(
        <Select.Option key={deleteTagMenu[key]?.tagNo} value={deleteTagMenu[key]?.tagNo}>
          <div className={`tag-color-dot tag-${deleteTagMenu[key]?.color}`} />
          {dataByTagNo[deleteTagMenu[key]?.tagNo]?.tagName}
        </Select.Option>,
      );
    });
  } else {
    deleteTagOption = [...tagOption];
  }

  // 대상자 태그
  const tagRender = (props: any) => {
    const currentData = tagData.filter((tagItem: any) => tagItem.tagNo === props.value)[0];
    if (currentData) {
      return (
        <Tag closable className={`color-tag tag-label-${currentData.color} small`}>
          {currentData.tagName}
          <CloseCircleFilled onClick={() => handleClearTag(currentData.tagNo)} />
        </Tag>
      );
    }
    return <></>;
  };

  // 태그 선택 이벤트
  const handleSelectItem = (name: string, data: any) => {
    if (tagSearchText) {
      // 검색 값 초기화
      setTagSearchText('');
      // 저장 버튼 활성화
      setDisableSaveBtn(false);
    }

    // 빈값 제거
    const tag = data.filter((value: any) => !!value);
    setSelectedTag(tag);
    if (detailType === 'single') {
      setValue('targetTag', tag);
    } else if (detailType === 'multi') {
      setValue('tagNoArray', tag);
    }
  };

  // 태그 선택 취소 이벤트
  const handleClearTag = (tagNo: number) => {
    const newTag = selectedTag.filter((tag: number) => tag !== tagNo);
    setSelectedTag(newTag);
    if (detailType === 'single') {
      setValue('targetTag', newTag);
    } else if (detailType === 'multi') {
      setValue('tagNoArray', newTag);
    }
  };

  // 편집 or 텍스트 모드
  const changeMode = (key: string, value: any) => {
    dispatch(changeTargetState({ key: 'editInfo', value: { editTarget: key, editValue: value } }));
  };

  // 태그 검색 이벤트
  const changTagSearch = (value: string) => {
    // 검색 단어가 있을 경우 저장 버튼 비활성화
    if (value.trim()) {
      setTagSearchText(value);
      setDisableSaveBtn(true);
    } else {
      setDisableSaveBtn(false);
    }
  };

  // 대상자 정보 validation 설정
  const setValidation = (validArray: Array<string>) => {
    const validateObj: any = {};
    validArray.forEach((validKey: string) => {
      if (validKey === 'required') {
        validateObj.required = (value: any) => valid.required(value);
      } else if (validKey === 'name') {
        validateObj.name = (value: any) => valid.name(value);
      } else if (validKey === 'email') {
        validateObj.email = (value: any) => valid.email(value);
      } else if (validKey === 'userPhone') {
        validateObj.userPhone = (value: any) => valid.userPhone(value);
      }
    });

    return { validate: validateObj };
  };

  // 대상자 정보 및 태그 수정
  const onEditInfo = async (type: string, name: any) => {
    try {
      const params: any = {
        targetNo: detailData.targetNo,
      };

      // 폼 입력 데이터
      const values = getValues();

      // 값 변경 여부 확인
      let isChanged = false;

      if (type === 'tag') {
        // 태그 정보 수정
        Object.keys(values).forEach((key: string) => {
          if (JSON.stringify(detailData.tagList) !== JSON.stringify(values.targetTag)) {
            isChanged = true;

            // 변경한 정보 파라미터에 저장
            params.tagNoArray = JSON.stringify(
              values.targetTag.length > 0 ? values.targetTag : [0],
            );
          }
        });

        if (isChanged) {
          dispatch(putTagTarget(params));
        }
      } else {
        // 대상자 정보, 메모 수정
        Object.keys(values).forEach((key: string) => {
          if (detailData[key] !== values[key]) {
            isChanged = true;

            // 변경한 정보 파라미터에 저장
            params[key] = values[key];
          }
        });

        if (isChanged) {
          dispatch(putTarget(params));
        }
      }

      // 메시지 팝업
      openNotification(
        `'${detailData.targetName}'${formatMessage({
          id: 'Target_17',
          defaultMessage: '의',
        })} ${name} ${formatMessage({ id: 'Target_18', defaultMessage: '수정' })}`,
      );
    } catch (error) {
      console.log('TargetDetail onEditInfo', error);
    }
  };

  // 대상자 정보 수정 취소
  const onEditCancel = () => {
    dispatch(changeTargetState({ key: 'editInfo', value: { editTarget: '', editValue: '' } }));
  };

  // 훈련 이력 리스트 열기/닫기
  const toggleExamList = (examNo: number) => {
    const newList = [...targetExamData.openList];
    if (newList.includes(examNo)) {
      newList.splice(newList.indexOf(examNo));
    } else {
      newList.push(examNo);
    }
    dispatch(changeTargetExamState({ key: 'openList', value: newList }));
  };

  // 대상자 다중 선택 시 상세 정보 조회
  const onGetMergeDetail = async () => {
    try {
      setLoading(true);

      const params = {
        tagNoArray:
          selectTagList[0] === 'all-tag' || selectTagList[0] === 'no-tag'
            ? selectTagList[0]
            : JSON.stringify(selectTagList),
        targetNoArray: JSON.stringify(selectTargetList),
        isAllCheck: isAllTargetCheck ? 1 : 0,
      };
      const response: any = await dispatch(getMergeDetail(params));
      if (response?.data) {
        setLoading(false);
      }
    } catch (error) {
      console.log('TargetDetail onGetMergeDetail', error);
    }
  };

  // 대상자 훈련 이력 조회
  const onGetExamHistory = async (refresh = false) => {
    try {
      setLoading(true);

      const params: any = {
        targetNo: selectTargetList[0],
        filter: JSON.stringify(targetExamData.param.filter),
        sort: JSON.stringify(targetExamData.param.sort),
        offset: targetExamData.param.limit * targetExamData.page,
        limit: targetExamData.param.limit,
      };

      if (refresh) {
        params.offset = 0;
        params.refresh = true;
      }

      const response: any = await dispatch(getExamHistory(params));
      if (response?.data) {
        setLoading(false);
      }
    } catch (error) {
      console.log('TargetDetail onGetExamHistory', error);
    }
  };

  // 대상자 교육 이력 조회
  const onGetEduHistory = async (refresh = false) => {
    try {
      setLoading(true);

      const params: any = {
        targetNo: selectTargetList[0],
        filter: JSON.stringify(targetEduData.param.filter),
        sort: JSON.stringify(targetEduData.param.sort),
        offset: targetEduData.param.limit * targetEduData.page,
        limit: targetEduData.param.limit,
      };

      if (refresh) {
        params.offset = 0;
        params.refresh = true;
      }

      const response: any = await dispatch(getEduHistory(params));
      if (response?.data) {
        setLoading(false);
      }
    } catch (error) {
      console.log('TargetDetail onGetEduHistory', error);
    }
  };

  // 태그 일괄 추가
  const onAddTargetTag = async () => {
    try {
      setLoading(true);

      const params: any = {
        tagNoArray: JSON.stringify(values.tagNoArray),
      };

      if (isAllTargetCheck) {
        params.isAllCheck = 1;
        params.checkTagNoArray = JSON.stringify(selectTagList);
      } else {
        params.isAllCheck = 0;
        params.targetNoArray = JSON.stringify(selectTargetList);
      }

      const response: any = await dispatch(postTagTarget(params));
      if (response?.data) {
        setLoading(false);

        // 모달 닫기
        setOpenModal('');

        // 메시지 팝업
        openNotification(formatMessage({ id: 'Tag_9', defaultMessage: '태그 일괄 등록' }));
      }
    } catch (error) {
      console.log('TargetDetail onAddTargetTag', error);
    }
  };

  // 태그 일괄 삭제
  const onDeleteTargetTag = async () => {
    try {
      setLoading(true);

      const params: any = {
        tagNoArray: JSON.stringify(values.tagNoArray),
      };

      if (isAllTargetCheck) {
        params.isAllCheck = 1;
        params.checkTagNoArray = JSON.stringify(selectTagList);
      } else {
        params.isAllCheck = 0;
        params.targetNoArray = JSON.stringify(selectTargetList);
      }

      const response: any = await dispatch(deleteTagTarget(params));
      if (response?.data) {
        setLoading(false);

        // 모달 닫기
        setOpenModal('');

        // 메시지 팝업
        openNotification(formatMessage({ id: 'Tag_10', defaultMessage: '태그 일괄 삭제' }));
      }
    } catch (error) {
      console.log('TargetDetail onDeleteTargetTag', error);
    }
  };

  // 감염 현황(파일, 인증서, 메일) 데이티
  const countData = [
    {
      type: 'all',
      countKey: 'leakAllcount',
      sizeKey: 'leakAllsize',
    },
    {
      type: 'file',
      countKey: 'leakFileCount',
      sizeKey: 'leakFileSize',
    },
    {
      type: 'cert',
      countKey: 'leakCertCount',
      sizeKey: 'leakCertSize',
    },
    {
      type: 'mail',
      countKey: 'leakEmailCount',
      sizeKey: 'leakEmailSize',
    },
  ];

  // 최근 훈련 결과
  const circleChartArray = [
    {
      name: '',
      color: '',
      value: mergeDetailData.allTarget > 0 ? 0 : 100,
      percent: mergeDetailData.allTarget > 0 ? 0 : 100,
    },
    {
      name: 'target',
      color: '#797979',
      value: mergeDetailData.target,
      label: formatMessage({ id: 'Target_25', defaultMessage: '결과 없음' }),
      percent: ((mergeDetailData.target / mergeDetailData.allTarget) * 100).toFixed(0),
    },
    {
      name: 'send',
      color: '#14ae96',
      value: mergeDetailData.send,
      label: formatMessage({ id: 'Status_10', defaultMessage: '양호' }),
      percent: ((mergeDetailData.send / mergeDetailData.allTarget) * 100).toFixed(0),
    },
    {
      name: 'read',
      color: '#f69c2e',
      value: mergeDetailData.read,
      label: formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' }),
      percent: ((mergeDetailData.read / mergeDetailData.allTarget) * 100).toFixed(0),
    },
    {
      name: 'download',
      color: '#711bd1',
      value: mergeDetailData.download,
      label: formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' }),
      percent: ((mergeDetailData.download / mergeDetailData.allTarget) * 100).toFixed(0),
    },
    {
      name: 'infection',
      color: '#dc3030',
      value: mergeDetailData.infection,
      label: formatMessage({ id: 'Infection_24', defaultMessage: 'PC 감염' }),
      percent: ((mergeDetailData.infection / mergeDetailData.allTarget) * 100).toFixed(0),
    },
  ];

  // 대상자 정보 컴포넌트
  let targetInfoComponent = null;
  if (detailType === 'single' && targetInfo?.length > 0) {
    targetInfoComponent = targetInfo.map((data: any) => (
      <div className="detail-item" key={data.title}>
        <div className="detail-title">{data.title}</div>
        <div className="detail-contents">
          {data.contents.map((content: any) => (
            <div className="detail-row target-row" key={content.text}>
              <div className="detail-text">{content.text}</div>
              {content.editKey === editInfo.editTarget ? (
                <div className="target-info-edit">
                  <div className="detail-value">
                    <form
                      className="target-form"
                      onSubmit={handleSubmit(() => onEditInfo('target', content.text))}
                      autoComplete="off"
                    >
                      <FormTextField
                        name={content.editKey}
                        defaultValue={editInfo.editValue}
                        error={errors[content.editKey]}
                        register={register}
                        validation={content.validation ? setValidation(content.validation) : {}}
                        autoFocus
                      />
                    </form>
                  </div>
                  <div className="edit-btns">
                    <div
                      className={`edit-btn ${Object.keys(errors)?.length > 0 ? 'disabled' : ''}`}
                      title={formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onEditInfo('target', content.text);
                      }}
                      aria-hidden="true"
                    >
                      <CheckOutlined />
                    </div>
                    <div
                      className="edit-btn"
                      title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onEditCancel();
                      }}
                      aria-hidden="true"
                    >
                      <CloseOutlined />
                    </div>
                  </div>
                </div>
              ) : (
                <div
                  className={`detail-value ${content.editKey ? 'editable' : ''}`}
                  title={content.editKey ? formatMessage({
                    id: 'Target_16',
                    defaultMessage: '편집하려면 클릭하세요.',
                  }) : ''}
                  onClick={() =>
                    content.editKey ? changeMode(content.editKey, detailData[content.editKey]) : {}
                  }
                  aria-hidden="true"
                >
                  <div className="edit-text">{content.value}</div>
                  <div className="edit-icon">
                    <EditOutlined />
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    ));
  } else if (detailType === 'multi') {
    targetInfoComponent = (
      <div className="detail-item">
        <div className="detail-title">
          {formatMessage({ id: 'Target_8', defaultMessage: '대상자 정보' })}
        </div>
        <div className="detail-contents">
          <div className="detail-row target-row">
            <div className="detail-text">
              {formatMessage({ id: 'Target_23', defaultMessage: '선택된 대상자 수' })}
            </div>
            <div className="detail-value">{`${mergeDetailData.allTarget || 0}${formatMessage({
              id: 'StartExam_35',
              defaultMessage: '명',
            })}`}</div>
          </div>
          <div className="detail-row target-row merge">
            <div className="detail-text">
              {formatMessage({ id: 'Exam_72', defaultMessage: '최근 훈련 결과' })}
            </div>
            <div className="detail-value">
              <div className="target-exam-chart merge">
                <div className="exam-chart-item">
                  <div className="chart-contents">
                    <div className="chart-graph-area">
                      <PieChart width={120} height={120}>
                        <Pie
                          dataKey="value"
                          data={[
                            {
                              name: '',
                              value: 100,
                            },
                          ]}
                          cx={55}
                          cy={55}
                          paddingAngle={0}
                          innerRadius={18}
                          outerRadius={56}
                          fill="#f7f7f7"
                        />
                        <Pie
                          dataKey="value"
                          data={circleChartArray}
                          cx={55}
                          cy={55}
                          innerRadius={23}
                          outerRadius={51}
                          startAngle={450}
                          endAngle={90}
                          paddingAngle={-1}
                          activeIndex={0}
                          activeShape={(e: any) => {
                            const { cx, cy, innerRadius, outerRadius, startAngle, endAngle } = e;
                            return (
                              <g>
                                <defs>
                                  <linearGradient id="797979" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#797979" />
                                    <stop offset="100%" stopColor="#5d5d5d" />
                                  </linearGradient>
                                </defs>
                                <defs>
                                  <linearGradient id="14ae96" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#14ae96" />
                                    <stop offset="100%" stopColor="#058f88" />
                                  </linearGradient>
                                </defs>
                                <defs>
                                  <linearGradient id="f69c2e" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#f69c2e" />
                                    <stop offset="100%" stopColor="#ff8715" />
                                  </linearGradient>
                                </defs>
                                <defs>
                                  <linearGradient id="711bd1" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#711bd1" />
                                    <stop offset="100%" stopColor="#4506a1" />
                                  </linearGradient>
                                </defs>
                                <defs>
                                  <linearGradient id="dc3030" x1="0" y1="0" x2="0" y2="1">
                                    <stop offset="0%" stopColor="#dc3030" />
                                    <stop offset="100%" stopColor="#bd0e0e" />
                                  </linearGradient>
                                </defs>
                                <Sector
                                  cx={cx}
                                  cy={cy}
                                  innerRadius={innerRadius}
                                  outerRadius={outerRadius}
                                  startAngle={startAngle}
                                  endAngle={endAngle}
                                  fill="#f7f7f7"
                                />
                                <Sector
                                  cx={cx}
                                  cy={cy}
                                  innerRadius={outerRadius - 1}
                                  outerRadius={outerRadius + 8}
                                  startAngle={startAngle}
                                  endAngle={endAngle}
                                  fill="#f7f7f7"
                                />
                              </g>
                            );
                          }}
                        >
                          {/* 퍼센트 표시 */}
                          {circleChartArray.map((entry: any) => (
                            <Cell key={entry.name} fill={`url(${entry.color})`} />
                          ))}
                        </Pie>
                        <ChartTooltip
                          content={({ payload }: any) => {
                            return (
                              <div>
                                {payload.length > 0 &&
                                  payload[0]?.name &&
                                  payload.map((data: any) => (
                                    <div className="rechart-tooltip" key={data.name}>
                                      <div className="title">{data.payload.label}</div>
                                      <div className="value">
                                        {`${data.payload.value}${formatMessage({
                                          id: 'StartExam_35',
                                          defaultMessage: '명',
                                        })} (${data.payload.percent}%)`}
                                      </div>
                                    </div>
                                  ))}
                              </div>
                            );
                          }}
                        />
                      </PieChart>
                    </div>
                    <div className="chart-text-area">
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot bg-color-grey" />
                          <div className="text">
                            {formatMessage({ id: 'Target_25', defaultMessage: '결과 없음' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.target || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot brand-bg" />
                          <div className="text">
                            {formatMessage({ id: 'Status_10', defaultMessage: '양호' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.send || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot bg-color-orange" />
                          <div className="text">
                            {formatMessage({ id: 'Read_2', defaultMessage: '메일 열람' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.read || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot bg-color-down" />
                          <div className="text">
                            {formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.download || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot bg-color-red" />
                          <div className="text">
                            {formatMessage({ id: 'Infection_24', defaultMessage: 'PC 감염' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.infection || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                      <div className="chart-row">
                        <div className="title">
                          <div className="color-dot bg-color-cure" />
                          <div className="text">
                            {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                          </div>
                        </div>
                        <div className="value">
                          {`${mergeDetailData.cure || 0} ${formatMessage({
                            id: 'StartExam_35',
                            defaultMessage: '명',
                          })}`}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {Option.isDeclare === 1 && (
            <div className="detail-row target-row">
              <div className="detail-text">
                {formatMessage({ id: 'Target_24', defaultMessage: '신고 이력이 있는 대상자' })}
              </div>
              <div className="detail-value">{`${mergeDetailData.declare || 0}${formatMessage({
                id: 'StartExam_35',
                defaultMessage: '명',
              })}`}</div>
            </div>
          )}
        </div>
      </div>
    );
  }

  // 태그 컴포넌트
  let tagComponent = null;
  if (detailType === 'single') {
    if (editInfo.editTarget === 'targetTag') {
      // 태그 편집 모드
      tagComponent = (
        <div className="detail-row target-tag">
          <FormSelectField
            className="tag-select"
            name="targetTag"
            control={control}
            error={errors.targetTag}
            option={tagOption}
            handleOnChange={handleSelectItem}
            mode="multiple"
            showArrow
            tagRender={tagRender}
            placeholder={formatMessage({
              id: 'Tag_14',
              defaultMessage: '태그를 입력하세요.',
            })}
            value={selectedTag}
            filterOption={(input: any, option: any) => {
              return option.children[1]?.toLowerCase().includes(input.toLowerCase());
            }}
            autoFocus
            onSearch={changTagSearch}
          />
          <div className="edit-btns">
            <div
              className={`edit-btn ${disableSaveBtn ? 'disabled' : ''}`}
              title={formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
              onClick={(e: any) => {
                e.stopPropagation();
                onEditInfo('tag', formatMessage({ id: 'Tag_1', defaultMessage: '태그' }));
              }}
              aria-hidden="true"
            >
              <CheckOutlined />
            </div>
            <div
              className="edit-btn"
              title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
              onClick={(e: any) => {
                e.stopPropagation();
                onEditCancel();
              }}
              aria-hidden="true"
            >
              <CloseOutlined />
            </div>
          </div>
        </div>
      );
    } else {
      // 오름차순으로 정렬된 태그 번호 리스트
      const sortedTagNoList = tagData?.map((item: any) => item.tagNo)?.sort();
      // 태그 출력 모드
      tagComponent = (
        <div
          className="editable target-tag"
          title={formatMessage({
            id: 'Target_16',
            defaultMessage: '편집하려면 클릭하세요.',
          })}
          onClick={() => changeMode('targetTag', selectedTag)}
          aria-hidden="true"
        >
          <div className="edit-text">
            {detailData?.tagList?.length > 0 ? (
              sortedTagNoList.map((tagNo: any) => {
                const tagItem = tagData.filter((item: any) => item.tagNo === tagNo)[0];
                if (detailData.tagList.includes(tagItem.tagNo)) {
                  return (
                    <Tag
                      closable
                      className={`color-tag tag-label-${tagItem.color} small`}
                      key={tagItem.tagNo}
                    >
                      {tagItem.tagName}
                    </Tag>
                  );
                }
                return <></>;
              })
            ) : (
              <div>{formatMessage({ id: 'Tag_15', defaultMessage: '태그를 등록하세요.' })}</div>
            )}
          </div>
          <div className="edit-icon border">
            <EditOutlined />
          </div>
        </div>
      );
    }
  } else if (detailType === 'multi') {
    if (detailData && !isAllTargetCheck) {
      // 선택한 대상자 데이터
      const selectedTargetList = detailData.filter((data: any) =>
        selectTargetList.includes(data.targetNo),
      );

      // 선택한 태그 데이터
      const selectedTagData: any = {};
      selectedTargetList.forEach((data: any) => {
        if (data.tagList) {
          data.tagList.forEach((tagNo: number) => {
            if (tagNo && dataByTagNo[tagNo] && !selectedTagData[tagNo]) {
              selectedTagData[tagNo] = dataByTagNo[tagNo];
            }
          });
        }
      });

      tagComponent = (
        <div className="edit-text">
          {Object.keys(selectedTagData)?.length > 0 ? (
            Object.keys(selectedTagData).map((key: any) => {
              return (
                <Tag
                  closable
                  className={`color-tag tag-label-${selectedTagData[key].color} small`}
                  key={selectedTagData[key].tagNo}
                >
                  {selectedTagData[key].tagName}
                </Tag>
              );
            })
          ) : (
            <div>{formatMessage({ id: 'Tag_15', defaultMessage: '태그를 등록하세요.' })}</div>
          )}
        </div>
      );

      // 태그 일괄 삭제 리스트 목록 데이터
      if (JSON.stringify(deleteTagMenu) !== JSON.stringify(selectedTagData)) {
        setDeleteTagMenu(selectedTagData);
      }
    }
  }

  // 훈련 이력 컴포넌트
  let targetExamComponent = null;
  if (targetExamData.data.length > 0) {
    targetExamComponent = (
      <>
        <div className="exam-history-chart-area">
          <TargetExamChart chartData={targetExamData.chartData} chartType="total" useOpen={false} />
          <TargetExamChart chartData={targetExamData.chartData} chartType="attach" useOpen />
          <TargetExamChart chartData={targetExamData.chartData} chartType="phishing" useOpen />
        </div>
        <div className="detail-row">
          <div className="exam-history">
            {targetExamData.data.map((examData: any) => {
              const isPhishing = examData.examType === 4;
              const isCure = examData.examType === 1 && examData.attachExamType === 2;

              return (
                <div className="exam-history-list-item" key={examData.examNo}>
                  <div className="exam-history-title flex">
                    <Tooltip
                      overlayClassName="black-tooltip full"
                      placement="bottomLeft"
                      title={`${examData.examName} ${timeFormatFromUTCEpoch(
                        examData.examStartEpoch,
                        3,
                      )} ~ ${timeFormatFromUTCEpoch(examData.examEndEpoch, 3)}`}
                    >
                      <div className="flex">
                        <span className="title ellipsis">{examData.examName}</span>
                        <span className="period-text">
                          {`${timeFormatFromUTCEpoch(
                            examData.examStartEpoch,
                            3,
                          )} ~ ${timeFormatFromUTCEpoch(examData.examEndEpoch, 3)}`}
                        </span>
                      </div>
                    </Tooltip>
                    <div
                      className="exam-arrow-btn"
                      onClick={() => toggleExamList(examData.examNo)}
                      aria-hidden="true"
                    >
                      {examData.readEpoch &&
                        (targetExamData.openList.includes(examData.examNo) ? (
                          <img src="/img/tag/ico_close.png" alt="arrow" />
                        ) : (
                          <img src="/img/tag/ico_open.png" alt="arrow" />
                        ))}
                    </div>
                  </div>
                  <div className="exam-history-content">
                    <div className="status-area">
                      <div className={`grey-btn ${examData.readEpoch && 'color-border-orange'}`}>
                        {formatMessage({ id: 'Read_1', defaultMessage: '열람' })}
                      </div>
                      <div className={`grey-btn ${examData.downloadEpoch && 'color-border-down'}`}>
                        {isPhishing
                          ? formatMessage({ id: 'Download_11', defaultMessage: '접속' })
                          : formatMessage({ id: 'Download_1', defaultMessage: '다운' })}
                      </div>
                      <div className={`grey-btn ${examData.infectionEpoch && 'color-border-red'}`}>
                        {isPhishing
                          ? formatMessage({ id: 'Infection_44', defaultMessage: '유출' })
                          : formatMessage({ id: 'Infection_1', defaultMessage: '감염' })}
                      </div>
                      {isCure && (
                        <div className={`grey-btn ${examData.cureEpoch && 'color-border-cure'}`}>
                          {formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                        </div>
                      )}
                    </div>
                    <div className="extra-area">
                      {Option.isDeclare === 1 && (
                        <div
                          className={`grey-btn declare ${
                            examData.declareEpoch && 'color-border-declare'
                          }`}
                        >
                          {examData.declareEpoch ? (
                            <img src="/img/declare/declare_on.png" alt="declare" />
                          ) : (
                            <img src="/img/declare/declare_off.png" alt="declare" />
                          )}
                          {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                        </div>
                      )}
                    </div>
                  </div>
                  {targetExamData.openList.includes(examData.examNo) && (
                    <div className="exam-history-detail">
                      <div className="history-detail-title">
                        {formatMessage({ id: 'Date_1', defaultMessage: '일시' })}
                      </div>
                      <div className="history-detail-item">
                        <div className="detail-row">
                          <div className="detail-text">
                            {formatMessage({ id: 'Send_2', defaultMessage: '메일 발송' })}
                          </div>
                          <div className="detail-value">
                            {timeFormatFromUTCEpoch(examData.sendEpoch)}
                          </div>
                        </div>
                      </div>
                      <div className="history-detail-item">
                        <div className="detail-row">
                          <div className="detail-text">
                            {formatMessage({ id: 'Send_15', defaultMessage: '메일 확인' })}
                          </div>
                          <div className="detail-value">
                            {timeFormatFromUTCEpoch(examData.sendEpoch)}
                          </div>
                        </div>
                      </div>
                      <div className="history-detail-item">
                        <div className="detail-row">
                          <div className="detail-text">
                            {isPhishing
                              ? formatMessage({ id: 'Download_8', defaultMessage: '피싱 접속' })
                              : formatMessage({ id: 'Download_4', defaultMessage: '파일 다운' })}
                          </div>
                          <div className="detail-value">
                            {timeFormatFromUTCEpoch(examData.downloadEpoch)}
                          </div>
                        </div>
                      </div>
                      <div className="history-detail-item">
                        <div className="detail-row">
                          <div className="detail-text">
                            {isPhishing
                              ? formatMessage({ id: 'Infection_26', defaultMessage: '정보 유출' })
                              : formatMessage({ id: 'Infection_24', defaultMessage: 'PC 감염' })}
                          </div>
                          <div className="detail-value">
                            {timeFormatFromUTCEpoch(examData.infectionEpoch)}
                          </div>
                        </div>
                      </div>
                      {!isPhishing && isCure && (
                        <div className="history-detail-item">
                          <div className="detail-row">
                            <div className="detail-text">
                              {examData.attachScreenType === 1 &&
                                formatMessage({ id: 'Cure_1', defaultMessage: '치료' })}
                              {examData.attachScreenType === 2 &&
                                formatMessage({ id: 'Cure_2', defaultMessage: '치료(비밀번호)' })}
                            </div>
                            <div className="detail-value">
                              {timeFormatFromUTCEpoch(examData.cureEpoch)}
                            </div>
                          </div>
                        </div>
                      )}
                      {Option.isDeclare === 1 && (
                        <div className="history-detail-item">
                          <div className="detail-row">
                            <div className="detail-text">
                              {formatMessage({ id: 'Declare_1', defaultMessage: '신고' })}
                            </div>
                            <div className="detail-value">
                              {timeFormatFromUTCEpoch(examData.declareEpoch)}
                            </div>
                          </div>
                        </div>
                      )}
                      {/* 감염 PC 목록 */}
                      {!isPhishing && examData.infectionPc && (
                        <div className="inner-item">
                          <div className="inner-title">
                            {formatMessage({
                              id: 'Infection_13',
                              defaultMessage: '감염 PC 목록',
                            })}
                          </div>
                          {examData.infectionPc.map((data: any) => (
                            <div className="inner-contents" key={data.hostname}>
                              <div className="inner-row">
                                <div className="dot-title" title={`${data.hostname}(${data.ip})`}>
                                  <div className="dot">·</div>
                                  <span>{data.hostname}</span>
                                  <span className="ml-5">{` (${data.ip})`}</span>
                                </div>
                              </div>
                              <div className="inner-row">
                                {formatMessage({
                                  id: 'Time_10',
                                  defaultMessage: 'PC 감염 시간',
                                })}
                                <div className="row-grey">
                                  {timeFormatFromUTCEpoch(data.regEpoch, 1)}
                                </div>
                              </div>
                              <div className="inner-row count-row">
                                {countData.map((data: any) => (
                                  <CountInfo
                                    type={data.type}
                                    count={examData[data.countKey]}
                                    size={examData[data.sizeKey]}
                                    key={data.type}
                                  />
                                ))}
                              </div>
                            </div>
                          ))}
                        </div>
                      )}
                    </div>
                  )}
                </div>
              );
            })}
            {/* 더보기 */}
            {targetExamData.totalPages > 1 && targetExamData.totalPages > targetExamData.page && (
              <div className="add-view-btn" onClick={() => onGetExamHistory()} aria-hidden="true">
                <PlusCircleFilled />
                {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
              </div>
            )}
          </div>
        </div>
      </>
    );
  } else {
    targetExamComponent = (
      <div className="no-data color-brand-green">
        <CheckSquareFilled />
        {formatMessage({ id: 'Target_22', defaultMessage: '훈련 이력 없음' })}
      </div>
    );
  }

  // 교육 이력 컴포넌트
  let targetEduComponent = null;
  if (targetEduData.data.length > 0) {
    targetEduComponent = (
      <div className="inner-item">
        {targetEduData.data.map((edu: any) => {
          // 발송 툴팁 메시지
          let sendTooltip = formatMessage({ id: 'Send_1', defaultMessage: '발송' });
          if (edu.send) {
            sendTooltip += `: ${timeFormatFromUTCEpoch(edu.send)}`;
          } else if (edu.eduStatus !== 1 && edu.eduStatus !== 2) {
            sendTooltip = formatMessage({ id: 'Exam_62', defaultMessage: '준비' });
          }

          return (
            <div className="inner-contents" key={edu.eduNo}>
              <div className="inner-row">
                <div className="dot-title">
                  <div className="dot">·</div>
                  <Tooltip
                    overlayClassName="black-tooltip full"
                    placement="bottom"
                    title={edu.eduName}
                  >
                    <span className="bold font-size-14">{edu.eduName}</span>
                  </Tooltip>
                </div>
              </div>
              <div className="inner-row">
                {formatMessage({
                  id: 'Edu_10',
                  defaultMessage: '교육 컨텐츠',
                })}
                <Tooltip
                  overlayClassName="black-tooltip full"
                  placement="bottom"
                  title={edu.attachName}
                >
                  <div className="row-grey ellipsis">{edu.attachName}</div>
                </Tooltip>
              </div>
              <div className="inner-row">
                {formatMessage({
                  id: 'Period_4',
                  defaultMessage: '교육 기간',
                })}
                <div className="row-grey">
                  {`${timeFormatFromUTCEpoch(edu.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                    edu.endEpoch,
                    3,
                  )}`}
                </div>
              </div>
              <div className="inner-row">
                <div className="edu-step-area" key={edu.eduNo}>
                  <ListStep
                    tooltipTitle={sendTooltip}
                    icon={{
                      stepIcon: '/img/exam/step/send.png',
                      active: !!edu.send || edu.eduStatus === 1 || edu.eduStatus === 2,
                      activeIcon: '/img/exam/step/send_a.png',
                      circleIcon: '/img/exam/step/circle_send.png',
                    }}
                  />
                  <ListStep
                    tooltipTitle={
                      edu.read
                        ? `${formatMessage({
                            id: 'Read_1',
                            defaultMessage: '열람',
                          })}: ${timeFormatFromUTCEpoch(edu.read)}`
                        : formatMessage({
                            id: 'Read_1',
                            defaultMessage: '열람',
                          })
                    }
                    icon={{
                      stepIcon: '/img/exam/step/read.png',
                      active: !!edu.read || edu.eduStatus === 1 || edu.eduStatus === 2,
                      activeIcon: '/img/exam/step/read_a.png',
                      circleIcon: '/img/exam/step/circle_read.png',
                      lineIcon: '/img/exam/step/line_read.png',
                    }}
                  />
                  <ListStep
                    tooltipTitle={formatMessage({ id: 'Status_8', defaultMessage: '진행중' })}
                    icon={{
                      stepIcon: '/img/edu/step/edu_progress.png',
                      active: !!edu.eduStatus && (edu.eduStatus === 1 || edu.eduStatus === 2),
                      activeIcon: '/img/edu/step/edu_progress_a.png',
                      lineIcon: '/img/edu/step/line_edu_progress.png',
                      circleIcon: '/img/edu/step/circle_edu_progress.png',
                    }}
                  />
                  <ListStep
                    tooltipTitle={
                      edu.finish
                        ? `${formatMessage({
                            id: 'Status_16',
                            defaultMessage: '완료',
                          })}: ${timeFormatFromUTCEpoch(edu.finish)}`
                        : formatMessage({ id: 'Status_16', defaultMessage: '완료' })
                    }
                    icon={{
                      stepIcon: '/img/edu/step/edu_end.png',
                      active: !!edu.eduStatus && edu.eduStatus === 2,
                      activeIcon: '/img/edu/step/edu_end_a.png',
                      lineIcon: '/img/edu/step/line_edu_end.png',
                      circleIcon: '/img/edu/step/circle_edu_end.png',
                    }}
                  />
                </div>
              </div>
            </div>
          );
        })}
        {/* 더보기 */}
        {targetEduData.totalPages > 1 && targetEduData.totalPages > targetEduData.page && (
          <div className="add-view-btn" onClick={() => onGetEduHistory()} aria-hidden="true">
            <PlusCircleFilled />
            {formatMessage({ id: 'Button_17', defaultMessage: '더보기' })}
          </div>
        )}
      </div>
    );
  } else {
    targetEduComponent = (
      <div className="no-data color-brand-green">
        <CheckSquareFilled />
        {formatMessage({ id: 'Edu_9', defaultMessage: '학습 대상 없음' })}
      </div>
    );
  }

  return (
    <div className="detail-area target-detail">
      <Loading loading={loading} />
      {/* 대상자 정보 */}
      {targetInfoComponent}
      {/* 태그 */}
      <div className="detail-item">
        <div className="detail-title merge">
          {formatMessage({ id: 'Tag_1', defaultMessage: '태그' })}
          {detailType === 'multi' && (
            <div>
              <Tooltip
                overlayClassName="black-tooltip full"
                placement="bottom"
                title={formatMessage({ id: 'Tag_8', defaultMessage: '태그 일괄 추가' })}
              >
                <PlusOutlined onClick={() => setOpenModal('addTargetTag')} aria-hidden="true" />
              </Tooltip>
              <Tooltip
                overlayClassName="black-tooltip full"
                placement="bottom"
                title={formatMessage({ id: 'Tag_10', defaultMessage: '태그 일괄 삭제' })}
              >
                <DeleteFilled onClick={() => setOpenModal('deleteTargetTag')} aria-hidden="true" />
              </Tooltip>
            </div>
          )}
        </div>
        <div className="detail-contents">{tagComponent}</div>
      </div>

      {/* 훈련 이력 */}
      {detailType === 'single' && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Target_21', defaultMessage: '훈련 이력' })}
          </div>
          <div className="detail-contents">{targetExamComponent}</div>
        </div>
      )}

      {/* 교육 이력 */}
      {detailType === 'single' && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Edu_8', defaultMessage: '교육 이력' })}
          </div>
          <div className="detail-contents">{targetEduComponent}</div>
        </div>
      )}

      {/* 메모 */}
      {detailType === 'single' && (
        <div className="detail-item">
          <div className="detail-title">
            {formatMessage({ id: 'Target_19', defaultMessage: '메모' })}
          </div>
          <div className="detail-contents">
            <div className="detail-row target-tag">
              {editInfo.editTarget === 'targetDetail' ? (
                <form
                  className="memo-form"
                  onSubmit={(e: any) => e.preventDefault()}
                  autoComplete="off"
                >
                  <Controller
                    control={control}
                    name="targetDetail"
                    defaultValue={detailData?.targetDetail}
                    rules={{
                      validate: {
                        range: (value: any) => valid.range(value, 0, 5000),
                      },
                    }}
                    render={({ onChange, onBlur, value }) => (
                      <textarea
                        className="memo-textarea"
                        cols={100}
                        rows={5}
                        onChange={onChange}
                        autoFocus // eslint-disable-line
                      >
                        {detailData?.targetDetail}
                      </textarea>
                    )}
                  />
                  <div className="edit-btns">
                    <div
                      className={`edit-btn ${disableSaveBtn ? 'disabled' : ''}`}
                      title={formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onEditInfo(
                          'targetDetail',
                          formatMessage({ id: 'Target_19', defaultMessage: '메모' }),
                        );
                      }}
                      aria-hidden="true"
                    >
                      <CheckOutlined />
                    </div>
                    <div
                      className="edit-btn"
                      title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                      onClick={(e: any) => {
                        e.stopPropagation();
                        onEditCancel();
                      }}
                      aria-hidden="true"
                    >
                      <CloseOutlined />
                    </div>
                  </div>
                </form>
              ) : (
                <div
                  className="editable memo"
                  title={formatMessage({
                    id: 'Target_16',
                    defaultMessage: '편집하려면 클릭하세요.',
                  })}
                  onClick={() => changeMode('targetDetail', detailData?.targetDetail)}
                  aria-hidden="true"
                >
                  <div className="edit-text">
                    {detailData?.targetDetail ? (
                      <pre className="memo-text">{detailData?.targetDetail}</pre>
                    ) : (
                      <div>
                        {formatMessage({ id: 'Target_20', defaultMessage: '클릭하여 입력하세요.' })}
                      </div>
                    )}
                  </div>
                  <div className="edit-icon border">
                    <EditOutlined />
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      )}

      {/* 태그 일괄 추가 모달 */}
      {openModal === 'addTargetTag' && (
        <ModalTemplate
          className="target-tag-modal modal-464"
          visible={openModal === 'addTargetTag'}
          title={formatMessage({ id: 'Tag_8', defaultMessage: '태그 일괄 추가' })}
          onOk={onAddTargetTag}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'Button_19', defaultMessage: '추 가' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          disabled={!values?.tagNoArray || values?.tagNoArray?.length < 1}
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Tag_31',
              defaultMessage: '추가할 태그를 선택하세요.',
            })}
          </div>
          <div className="modal-border-box">
            <div className="input-title">
              {formatMessage({ id: 'Tag_14', defaultMessage: '태그를 입력하세요.' })}
            </div>
            <FormSelectField
              className="tag-select"
              name="tagNoArray"
              control={control}
              error={errors.tagNoArray}
              option={tagOption}
              handleOnChange={handleSelectItem}
              mode="multiple"
              showArrow
              tagRender={tagRender}
              value={values.tagNoArray}
              filterOption={(input: any, option: any) => {
                return option.children[1].toLowerCase().includes(input.toLowerCase());
              }}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
            />
          </div>
        </ModalTemplate>
      )}

      {/* 태그 일괄 삭제 모달 */}
      {openModal === 'deleteTargetTag' && (
        <ModalTemplate
          className="target-tag-modal modal-464"
          visible={openModal === 'deleteTargetTag'}
          title={formatMessage({ id: 'Tag_10', defaultMessage: '태그 일괄 삭제' })}
          onOk={onDeleteTargetTag}
          onCancel={() => setOpenModal('')}
          okText={formatMessage({ id: 'Button_15', defaultMessage: '삭 제' })}
          cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
          loading={loading}
          disabled={!values?.tagNoArray || values?.tagNoArray?.length < 1}
          greyButton
        >
          <div className="modal-explain-text">
            {formatMessage({
              id: 'Tag_32',
              defaultMessage: '삭제할 태그를 선택하세요.',
            })}
          </div>
          <div className="modal-border-box">
            <div className="input-title">
              {formatMessage({ id: 'Tag_14', defaultMessage: '태그를 입력하세요.' })}
            </div>
            <FormSelectField
              className="tag-select"
              name="tagNoArray"
              control={control}
              error={errors.tagNoArray}
              option={deleteTagOption}
              handleOnChange={handleSelectItem}
              mode="multiple"
              showArrow
              tagRender={tagRender}
              value={values.tagNoArray}
              filterOption={(input: any, option: any) => {
                return option.children[1]?.toLowerCase().includes(input.toLowerCase());
              }}
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
            />
          </div>
        </ModalTemplate>
      )}
    </div>
  );
}

export default TargetDetail;
