import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntl } from 'react-intl';
import { Controller, useForm } from 'react-hook-form';
import { Tag, Select, Checkbox } from 'antd';
import { CloseCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';
import moment from 'moment';
import { getExam, getTag } from 'store/startExam';
import { eduNameCheck, getEduAttach, getEduQuiz, postEdu } from 'store/edu';
import ModalTemplate from 'components/common/ModalTemplate';
import FormTextField from 'components/common/FormTextField';
import FormDatePicker from 'components/common/FormDatePicker';
import FormSelectField from 'components/common/FormSelectField';
import Loading from 'components/common/Loading';
import { timeFormatFromUTCEpoch, epochFromDate } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import './StartEdu.scss';

// import prevImage from 'img/exam/startExam/btn_prev.png';
// import nextImage from 'img/exam/startExam/btn_next.png';
// import failImage from 'img/exam/startExam/ico_fail_s.png';

function StartExam(props: any) {
  const dispatch = useDispatch();
  const { formatMessage } = useIntl();
  // const licenseData = useSelector((state: any) => state.startExam.license);
  const examData = useSelector((state: any) => state.startExam.exam);
  const tagData = useSelector((state: any) => state.startExam.tag);
  const eduData = useSelector((state: any) => {
    return {
      data: state.edu.eduAttach.data,
      quizData: state.edu.eduAttach.quizData,
    };
  });

  const [openModal, setOpenModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [step, setStep] = useState(1);
  const [licenseError, setLicenseError]: any = useState({});
  const {
    register,
    handleSubmit,
    getValues,
    setValue,
    setError,
    clearErrors,
    errors,
    control,
  } = useForm({
    mode: 'all',
  });

  const [values, setValues]: any = useState({
    eduName: '',
    eduAttachNo: 0,
    startEpoch: moment().startOf('day'),
    endEpoch: moment().endOf('day').add(1, 'd'),
    tagNoArray: [],
    examNoArray: [],
    addQuiz: false,
  });

  const { Option } = Select;

  useEffect(() => {
    // 라이선스 에러 팝업 제거
    if (licenseError.error || licenseError.data ) {
      setLicenseError({});
    }
  }, [values, step]);

  // 대상자 태그 조회
  const onGetTag = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getTag());
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StrartEdu onGetTag', error);
    }
  };

  // 교육 컨텐츠 조회
  const onGetEduAttach = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getEduAttach());
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StrartEdu onGetEduAttach', error);
    }
  };

  // 훈련 조회
  const onGetExam = async () => {
    try {
      setLoading(true);
      const response: any = await dispatch(getExam());
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StrartEdu onGetExam', error);
    }
  };

  // 교육 퀴즈 조회
  const onGetEduQuiz = async () => {
    try {
      setLoading(true);
      const params = {
        eduAttachNo: values.eduAttachNo,
      };
      const response: any = await dispatch(getEduQuiz(params));
      if (response?.data?.list.length > 0) {
        setLoading(false);
      }
    } catch (error) {
      console.log('StrartEdu onGetEduQuiz', error);
    }
  };

  // 교육 실시
  const onStartEdu = async () => {
    setLoading(true);
    const { eduName, eduAttachNo, startEpoch, endEpoch, tagNoArray, examNoArray } = values;

    // 대상자 태그 값 재확인
    let errorKey = '';
    Object.keys(values).forEach((key: string) => {
      if (key === 'tagNoArray' && values[key]?.length < 1) {
        errorKey = key;
      }
    });

    if (errorKey) {
      setLoading(false);
      setError(errorKey, { type: 'required' });
      return;
    }

    try {
      const params: any = {
        eduName: eduName,
        eduAttachNo: eduAttachNo,
        startEpoch: epochFromDate(startEpoch),
        endEpoch: epochFromDate(endEpoch),
        tagNoArray: JSON.stringify(tagNoArray),
      };

      // 연결훈련 선택 시
      if (examNoArray.length > 0) {
        params.examNoArray = JSON.stringify(examNoArray);
      }

      // 모든 퀴즈 값을 하나의 배열로 통합
      const quizNoList: any = [];
      eduData.quizData.forEach((data: any) => {
        const item = `quizNoArray${data.fileNo}`;
        values[item]?.forEach((quizNo: number) => {
          quizNoList.push(quizNo);
        });
      });

      // 퀴즈 선택 시
      if (quizNoList.length > 0) {
        params.quizNoArray = JSON.stringify(quizNoList);
      }

      const response: any = await dispatch(postEdu(params));

      if (response?.data?.isValidLicense === 0) {
        // 라이선스 에러
        setLicenseError({
          list: response.data.list || [],
          error: response.data.error,
          isValidLicense: response.data.isValidLicense,
          data: response.data,
        });
      } else if (response?.data?.error && Array.isArray(response.data.error)) {
        setStep(1);
        if (response.data.error[0].errInfo === 'Duplicate Edu') {
          // 중복된 교육명
          setError('eduName', {
            type: 'duplicate',
            message: formatMessage({ id: 'Edu_39', defaultMessage: '이미 사용중인 교육명입니다.' }),
          });
        } else {
          // 기타 에러
          setError('eduName', { type: 'error', message: response?.data?.error[0]?.errInfo });
        }
      } else if (response?.data?.list) {
        toggleModal(false);
      } else {
        // 라이선스 에러
        setLicenseError({
          list: response.data.list || [],
          error: response.data.error,
          isValidLicense: response.data.isValidLicense,
          data: response.data,
        });
      }

      setLoading(false);
    } catch (error) {
      console.log('StartEdu onStartEdu', error);
    }
  };

  // 모달 열기/닫기 이벤트
  const toggleModal = (isOpen: boolean) => {
    if (isOpen) {
      // 모달 열기
      setOpenModal(true);
      onGetTag();
      onGetEduAttach();
      onGetExam();
    } else {
      // 모달 닫기
      clearErrors();
      setStep(1);
      setOpenModal(false);
      setValues({
        eduName: '',
        eduAttachNo: 0,
        startEpoch: moment().startOf('day'),
        endEpoch: moment().endOf('day').add(1, 'd'),
        tagNoArray: [],
        examNoArray: [],
        addQuiz: false,
      });
    }
  };

  // 이전 클릭 이벤트
  const prevStep = () => {
    // 이전 단계로 이동
    setStep(1);
  };

  // 다음, 실행 버튼 클릭 이벤트
  const nextStep = () => {
    if (values.addQuiz && step === 1) {
      // 퀴즈 선택 화면으로 이동
      onGetEduQuiz();
      setStep(2);
    } else {
      // 교육 실시
      onStartEdu();
    }
  };

  // 시작일자, 종료일자 선택 불가 날짜 지정
  const handleDisableDate = (type: any, current: any) => {
    let isDisable = false;
    if (type === 'startEpoch') {
      isDisable =
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') || current > moment('2037-12-30').startOf('day');
    } else {
      isDisable =
        // 시작일자보다 작을 때
        current < moment(values.startEpoch).add(1, 'd') ||
        // 현재일(오늘)보다 작을때
        current < moment().startOf('day') ||
        current > moment('2037-12-31').add(1, 'd');
    }
    return isDisable;
  };

  // 시작일자, 종료일자 선택 시
  const handleOnChangeDate = (type: string, current: any) => {
    if (type === 'startEpoch') {
      // 시작일이 종료일과 같거나 클 경우
      if (moment(current).endOf('day') >= moment(values.endEpoch)) {
        setValues({
          ...values,
          startEpoch: moment(current).startOf('day'),
          endEpoch: null,
        });
        setValue('endEpoch', null);
      } else {
        setValues({ ...values, startEpoch: moment(current).startOf('day') });
      }
    } else if (type === 'endEpoch') {
      setValues({
        ...values,
        endEpoch: moment(current).hour(23).minute(59).second(59).millisecond(0),
      });
    }
  };

  // 대상자 태그
  const tagRender = (props: any, name: any) => {
    let tagComponent = null;
    const currentTagData = tagData.filter((tag: any) => tag.tagNo === props.value)[0];
    if (currentTagData) {
      const { tagName, color, targetCount, tagNo } = currentTagData;
      tagComponent = (
        <Tag closable className={`color-tag tag-label-${color}`}>
          {`${tagName} - ${targetCount}${formatMessage({
            id: 'StartExam_35',
            defaultMessage: '명',
          })}`}
          <CloseCircleFilled onClick={() => handleClearTag(name, tagNo)} />
        </Tag>
      );
    }
    return tagComponent;
  };

  // 드롭다운 리스트 선택 이벤트
  const handleSelectList = (name: string, data: any) => {
    setValues({
      ...values,
      [name]: data,
    });
  };

  // 태그 삭제(X 버튼 클릭) 이벤트
  const handleClearTag = (name: any, exceptTagNo: any) => {
    const selectedValue = values[name].filter((tagNo: any) => tagNo !== exceptTagNo);
    setValues({
      ...values,
      [name]: selectedValue,
    });
  };

  // 태그 목록 컴포넌트
  let tagListComponent = null;
  let tagTargetCount = 0;
  const tagOption: any = [];

  // 등록된 태그가 있을 경우
  if (tagData?.length > 0) {
    tagData.forEach((tag: any) => {
      // 태그에 할당된 대상자 수 확인
      tagTargetCount += tag.targetCount;
    });

    // 대상자 태그 리스트
    const tagList = tagData.filter(
      (tag: any) =>
        !values.excepttagNoArray?.includes(tag.tagNo) && !values.tagNoArray?.includes(tag.tagNo),
    );

    tagList.forEach((tag: any) => {
      const { tagNo, tagName, color, targetCount } = tag;

      //  드롭다운 메뉴
      tagOption.push(
        <Option
          // key={`exam-tag-${tagNo}`}
          value={tagNo}
          className={`exam-tag ${targetCount < 1 ? 'disabled' : ''}`}
        >
          <div className="tag-select-option flex">
            <div className={`tag-circle tag-${color}`} />
            <div className="tag-name">{tagName}</div>
            <div className="tag-count">
              {targetCount}
              {formatMessage({
                id: 'StartExam_35',
                defaultMessage: '명',
              })}
            </div>
          </div>
        </Option>,
      );
    });

    tagListComponent = (
      <FormSelectField
        className={tagTargetCount < 1 && 'warning'}
        name="tagNoArray"
        control={control}
        error={errors.tagNoArray}
        option={tagOption}
        handleOnChange={handleSelectList}
        mode="multiple"
        showArrow
        tagRender={(props: any) => tagRender(props, 'tagNoArray')}
        placeholder={
          tagTargetCount < 1
            ? formatMessage({ id: 'Tag_24', defaultMessage: '태그에 할당된 대상자가 없습니다.' })
            : formatMessage({ id: 'Edu_43', defaultMessage: '교육 실시할 태그를 선택하세요.' })
        }
        validation={{
          validate: {
            required: (value: any) => {
              return valid.required(value?.length > 0 || values.tagNoArray?.length > 0);
            },
          },
        }}
        value={values.tagNoArray}
        filterOption={(input: any, option: any) => {
          return option.children.props.children[1].props.children
            .toLowerCase()
            .includes(input.toLowerCase());
        }}
      />
    );
  } else {
    // 등록된 태그가 없을 경우
    tagListComponent = (
      <div className="no-tag-area">
        <div className="no-tag-main-text">
          <ExclamationCircleFilled />
          {formatMessage({ id: 'Tag_25', defaultMessage: '대상자 태그가 없습니다.' })}
        </div>
        <div className="no-tag-sub-text">
          {formatMessage({
            id: 'Tag_26',
            defaultMessage:
              '대상자를 추가하고 태그를 부여하면 태그를 이용하여 여러 대상자를 한번에 선택할 수 있습니다.',
          })}
        </div>
      </div>
    );
  }

  // 교육 컨텐츠 드롭다운 메뉴
  const eduAttachMenu: any = [];
  if (eduData?.data?.length > 0) {
    eduData.data.forEach((data: any) => {
      eduAttachMenu.push({
        key: data.eduAttachNo,
        value: data.eduAttachNo,
        text: data.attachName,
      });
    });
  }

  // 퀴즈 드롭다운 메뉴
  const makeQuizOption = (fileNo: number) => {
    const eduQuizMenu: any = [];
    if (eduData?.quizData?.length > 0) {
      const remainData: any = [];
      eduData?.quizData?.forEach((data: any) => {
        if (data.quizInfo && data.fileNo === fileNo) {
          data.quizInfo.forEach((quiz: any) => {
            if (!values[`quizNoArray${fileNo}`]?.includes(quiz.quizNo)) {
              remainData.push(quiz);
            }
          });
        }
      });

      remainData?.forEach((quiz: any) => {
        eduQuizMenu.push(
          <Option value={quiz.quizNo} key={quiz.quizContent}>
            {quiz.quizContent}
          </Option>,
        );
      });

      return eduQuizMenu;
    }
    return <></>;
  };

  // 퀴즈 태그
  const quizTagRender = (props: any, name: any) => {
    let tagComponent = null;
    let remainData: any = {};

    eduData?.quizData?.forEach((data: any) => {
      data?.quizInfo?.forEach((quiz: any) => {
        if (quiz.quizNo === props.value) {
          remainData = quiz;
        }
      });
    });

    if (Object.keys(remainData).length > 0) {
      const { quizNo, quizContent } = remainData;
      tagComponent = (
        <Tag closable className="default-tag" onClose={() => handleClearTag(name, quizNo)}>
          {quizContent}
        </Tag>
      );
    }
    return tagComponent;
  };

  // 연결훈련 드롭다운 메뉴
  const examMenu: any = [];
  if (examData?.length > 0) {
    const remainData = examData.filter((exam: any) => !values.examNoArray.includes(exam.examNo));
    remainData.forEach((exam: any) => {
      examMenu.push(
        <Option value={exam.examNo} key={exam.examName}>
          {exam.examName}
        </Option>,
      );
    });
  }

  // 연결훈련 태그
  const examTagRender = (props: any, name: any) => {
    let tagComponent = null;
    const remainData = examData.filter((exam: any) => exam.examNo === props.value)[0];
    if (remainData) {
      const { examNo, examName } = remainData;
      tagComponent = (
        <Tag closable className="default-tag" onClose={() => handleClearTag(name, examNo)}>
          {examName}
        </Tag>
      );
    }
    return tagComponent;
  };

  // 라이선스 에러 확인
  let licenseContent = null;
  if (licenseError?.isValidLicense === 0) {
    if (licenseError.error === 'noLicense') {
      licenseContent = (
        <div>
          {formatMessage({
            id: 'License_3',
            defaultMessage: '라이선스가 존재하지 않습니다.',
          })}
        </div>
      );
    } else if (licenseError.error === 'noReserve') {
      licenseContent = (
        <div>
          {formatMessage({
            id: 'License_23',
            defaultMessage: '교육 시작일을 라이선스 유효기간 이후로 예약할 수 없습니다.',
          })}
        </div>
      );
    } else {
      let periodCount = 0;
      let timesCount = 0;
      const { periodCnt, timesCnt } = licenseError.data;
      licenseError.list.forEach((license: any) => {
        const {
          licenseType,
          licenseNo,
          remainCount,
          licenseCount,
          startEpoch,
          endEpoch,
          examLimitCount,
        } = license;
        if (licenseType === 1 || licenseType === 2) {
          periodCount += 1;
        } else if (licenseType === 4) {
          timesCount += 1;
        }
        licenseContent = (
          <div>
            <div>
              {formatMessage({
                id: 'License_4',
                defaultMessage: '라이선스의 수량이 부족합니다.',
              })}
            </div>
            <div className="license-status-row">
              {(licenseType === 1 || licenseType === 2) && (
                <div>
                  {periodCount === 1 && (
                    <div className="license-name">
                      {`· ${formatMessage({
                        id: 'License_7',
                        defaultMessage: '기간 라이선스',
                      })} (${periodCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-detail">
                    <div className="license-number">No.{licenseNo}</div>
                    <div className="license-target">{` ${
                      licenseCount - remainCount
                    }/${licenseCount}${formatMessage({
                      id: 'StartExam_35',
                      defaultMessage: '명',
                    })}`}</div>
                    <div className="license-period">{` ${timeFormatFromUTCEpoch(
                      startEpoch,
                      3,
                    )} ~ ${timeFormatFromUTCEpoch(endEpoch, 3)}`}</div>
                  </div>
                </div>
              )}
              {licenseType === 4 && (
                <div>
                  {timesCount === 1 && (
                    <div className="license-name">
                      {`· ${formatMessage({
                        id: 'License_8',
                        defaultMessage: '횟수 라이선스',
                      })} (${timesCnt}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })} ${formatMessage({
                        id: 'License_9',
                        defaultMessage: '부족',
                      })})`}
                    </div>
                  )}
                  <div className="license-detail">
                    <div>No.{licenseNo}</div>
                    <div>
                      {` ${licenseCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}
                    </div>
                    <div>
                      {` ${examLimitCount - remainCount}/${examLimitCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '회',
                      })}`}
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        );
      });
    }
  }

  return (
    <div>
      <button type="button" className="brand-square-btn" onClick={() => toggleModal(true)}>
        {formatMessage({ id: 'Edu_1', defaultMessage: '교육 실시' })}
      </button>
      {openModal && (
        <ModalTemplate
          className="start-exam-modal modal-665 start-edu"
          isCloseBlack
          visible={openModal}
          title={
            <div className="modal-title-area">
              <div className="modal-main-title">
                {formatMessage({ id: 'Edu_1', defaultMessage: '교육 실시' })}
              </div>
              <div className="modal-sub-title">
                {step === 1
                  ? formatMessage({
                      id: 'Edu_40',
                      defaultMessage:
                        '교육명 및 교육기간을 입력하고, 대상자 태그 및 교육 컨텐츠를 선택합니다.',
                    })
                  : formatMessage({
                      id: 'Edu_41',
                      defaultMessage: '교육 컨텐츠에 삽입할 퀴즈를 선택합니다.',
                    })}
              </div>
            </div>
          }
          onCancel={() => toggleModal(false)}
          footer={
            <>
              {step === 2 && (
                <button key="cancel" className="footer-btn" type="submit" onClick={prevStep}>
                  {/* <img src={prevImage} alt="prev" /> */}
                  <img src="/img/exam/startExam/btn_prev.png" alt="prev" />
                  {formatMessage({ id: 'Button_6', defaultMessage: '이 전' })}
                </button>
              )}

              <button
                key="ok"
                className={`footer-btn ok ${
                  Object.keys(errors).length > 0 || loading || tagData.length < 1 ? 'disabled' : ''
                }`}
                type="submit"
                onClick={handleSubmit(nextStep)}
              >
                {step === 2 || (step === 1 && !values.addQuiz) ? (
                  formatMessage({ id: 'Button_9', defaultMessage: '실 행' })
                ) : (
                  <>
                    {formatMessage({ id: 'Button_7', defaultMessage: '다 음' })}
                    {/* <img src={nextImage} alt="next" /> */}
                    <img src="/img/exam/startExam/btn_next.png" alt="next" />
                  </>
                )}
              </button>
            </>
          }
        >
          {/* 로딩 */}
          <Loading loading={loading} />

          <form autoComplete="off">
            {/* STEP 1 */}
            {step === 1 && (
              <div className="modal-content">
                {/* 교육명 */}
                <div className="content-item">
                  <div className="input-title">
                    {formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}*
                  </div>
                  <FormTextField
                    name="eduName"
                    defaultValue={values.eduName}
                    error={errors.eduName}
                    arrowPosition="top"
                    register={register}
                    onChange={(e: any) => setValues({ ...values, eduName: e.target.value })}
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value),
                        name: (value: any) => valid.name(value),
                      },
                    }}
                  />
                </div>

                {/* 시작일자/종료일자 */}
                <div className="content-item">
                  <div className="date-picker-area">
                    <div className="date-picker-item">
                      <div className="text-field-title">
                        {formatMessage({ id: 'Date_11', defaultMessage: '시작일자' })}*
                      </div>
                      <FormDatePicker
                        control={control}
                        name="startEpoch"
                        value={values.startEpoch}
                        error={errors.startEpoch}
                        handleOnChange={handleOnChangeDate}
                        handleDisableDate={handleDisableDate}
                        helperText={
                          moment() < values.startEpoch &&
                          formatMessage({ id: 'Edu_42', defaultMessage: '교육을 예약함' })
                        }
                        // disabled={values.startEpoch < moment()}
                      />
                    </div>
                    <div className="date-picker-item">
                      <div className="text-field-title">
                        {formatMessage({ id: 'Date_12', defaultMessage: '종료일자' })}*
                      </div>
                      <FormDatePicker
                        control={control}
                        name="endEpoch"
                        value={values.endEpoch}
                        error={errors.endEpoch}
                        validation={{
                          validate: {
                            required: (value: any) => {
                              return valid.required(value || values.endEpoch);
                            },
                          },
                        }}
                        handleOnChange={handleOnChangeDate}
                        handleDisableDate={handleDisableDate}
                        helperText={
                          values.endEpoch &&
                          `${formatMessage({
                            id: 'Period_4',
                            defaultMessage: '교육 기간',
                          })}: ${moment
                            .duration(values.endEpoch.diff(values.startEpoch))
                            .humanize()}`
                        }
                        arrowPosition="top"
                        // disabled={values.endEpoch < moment().startOf('day')}
                      />
                    </div>
                  </div>
                </div>

                {/* 대상자 태그 선택 */}
                <div className="content-item">
                  <div className="input-title">
                    {formatMessage({
                      id: 'Tag_17',
                      defaultMessage: '대상자 태그',
                    })}
                    *
                  </div>
                  {tagListComponent}
                </div>

                {/* 교육 컨텐츠 */}
                <div className="content-item">
                  <div className="text-field-title">
                    {formatMessage({ id: 'Edu_10', defaultMessage: '교육 컨텐츠' })}*
                  </div>
                  <FormSelectField
                    name="eduAttachNo"
                    control={control}
                    error={errors.eduAttachNo}
                    menuList={eduAttachMenu}
                    defaultValue={values.eduAttachNo}
                    placeholder={formatMessage({
                      id: 'Edu_44',
                      defaultMessage: '교육 컨텐츠를 선택하세요.',
                    })}
                    handleOnChange={handleSelectList}
                    validation={{
                      validate: {
                        required: (value: any) => valid.required(value || values.eduAttachNo),
                      },
                    }}
                  />
                  <div>
                    <Controller
                      control={control}
                      name="addQuiz"
                      render={({ onChange }) => (
                        <Checkbox
                          className="modal-checkbox"
                          onChange={(e) => {
                            onChange(e.target.checked);
                            setValues({ ...values, addQuiz: e.target.checked });
                          }}
                          checked={values.addQuiz}
                        >
                          {formatMessage({
                            id: 'Edu_46',
                            defaultMessage: '컨텐츠에 퀴즈 삽입',
                          })}
                        </Checkbox>
                      )}
                    />
                  </div>

                  {/* 연결훈련 */}
                  <div className="content-item">
                    <div className="input-title">
                      {formatMessage({ id: 'Edu_47', defaultMessage: '연결훈련' })}
                    </div>
                    <FormSelectField
                      className={`multi-select ${examData?.length > 0 ? '' : 'disabled'}`}
                      name="examNoArray"
                      control={control}
                      error={errors.examNoArray}
                      option={examMenu}
                      handleOnChange={handleSelectList}
                      mode="multiple"
                      showArrow
                      placeholder={formatMessage({
                        id: 'Edu_45',
                        defaultMessage: '교육과 연결시킬 훈련을 선택하세요.',
                      })}
                      value={values.examNoArray}
                      tagRender={(props: any) => examTagRender(props, 'examNoArray')}
                      filterOption={(input: any, option: any) => {
                        return option.children.toLowerCase().includes(input.toLowerCase());
                      }}
                    />
                  </div>
                </div>
              </div>
            )}

            {/* STEP 2 */}
            {step === 2 && (
              <div className="modal-content">
                {eduData?.quizData?.length > 0 &&
                  eduData.quizData?.map((quiz: any, index: number) => {
                    return (
                      <div className="content-item" key={quiz.fileNo}>
                        <div className="text-field-title">{`${index + 1}. ${quiz.fileName}`}</div>
                        <FormSelectField
                          className={`multi-select ${quiz.quizInfo ? '' : 'disabled'}`}
                          name={`quizNoArray${quiz.fileNo}`}
                          control={control}
                          error={errors[`quizNoArray${quiz.fileNo}`]}
                          option={makeQuizOption(quiz.fileNo)}
                          handleOnChange={handleSelectList}
                          mode="multiple"
                          showArrow
                          tagRender={(props: any) =>
                            quizTagRender(props, `quizNoArray${quiz.fileNo}`)
                          }
                          placeholder={formatMessage({
                            id: 'Edu_48',
                            defaultMessage: '퀴즈를 선택하세요.',
                          })}
                          value={values[`quizNoArray${quiz.fileNo}`]}
                          filterOption={(input: any, option: any) => {
                            return option.children.toLowerCase().includes(input.toLowerCase());
                          }}
                        />
                      </div>
                    );
                  })}
              </div>
            )}

            {/* 라이선스 오류 표시 */}
            {licenseError.isValidLicense === 0 && (
              <div className="license-error">
                <div className="license-error-content">
                  {step === 3 && (
                    <div className="license-error-text">
                      {/* <img src={failImage} alt="fail" /> */}
                      <img src="/img/exam/startExam/ico_fail_s.png" alt="fail" />
                      {formatMessage({
                        id: 'StartExam_60',
                        defaultMessage: '선택한 대상자의 라이선스가 필요합니다.',
                      })}
                    </div>
                  )}
                  <div className="license-error-popup">
                    <div className="popup-header">
                      {formatMessage({
                        id: 'License_2',
                        defaultMessage: '라이선스 현황',
                      })}
                    </div>
                    <div className="popup-content">{licenseContent}</div>
                  </div>
                </div>
              </div>
            )}
          </form>
        </ModalTemplate>
      )}
    </div>
  );
}

export default withRouter(StartExam);
