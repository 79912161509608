import { SettingOutlined } from '@ant-design/icons';
import { Popover, Tag } from 'antd';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import TableDatePicker from 'components/common/TableDatePicker';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getPhishing, changeParam } from 'store/phishing';
import PhishingPageSettings from './PhishingPageSettings';

export default function PhishingPageList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.phishing.param.sort,
      filter: state.phishing.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.phishing.needReload);
  const respData = useSelector((state: any) => state.phishing.data);
  const totalCount = useSelector((state: any) => state.phishing.totalCount);
  const totalPages = useSelector((state: any) => state.phishing.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getPhishingData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getPhishingData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getPhishing(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    phishingNo: 'No',
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    phishingName: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    isBasicArray: {
      name: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      child: [
        {
          label: formatMessage({ id: 'Template_14', defaultMessage: '기 본' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'User_1', defaultMessage: '사용자' }),
          value: '0',
        },
      ],
    },
    phishingLevelArray: {
      name: formatMessage({ id: 'Phishing_3', defaultMessage: '단계' }),
      child: [
        {
          label: `1${formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })}`,
          value: '1',
        },
        {
          label: `2${formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })}`,
          value: '2',
        },
        {
          label: `3${formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })}`,
          value: '3',
        },
      ],
    },
  };
  // 테이블
  const baseColumns = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 100,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <PhishingPageSettings isAdmin={isAdmin} phishingInfo={props.row.original} />;
      },
    },
    {
      Header: 'No',
      accessor: 'phishingNo',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_22', defaultMessage: '구분' }),
      accessor: 'isBasic',
      resizable: true,
      sortable: true,
      width: 83,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = (
          <Tag className="temp-type-label color-temp-system no-margin">
            {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
          </Tag>
        );

        if (props.row.original.serviceNo) {
          printValue = (
            <Tag className="temp-type-label color-temp-user no-margin">
              {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
            </Tag>
          );
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Phishing_4', defaultMessage: '피싱 단계' }),
      accessor: 'phishingLevel',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        const printValue = (
          <Tag className="temp-type-label color-temp-phishing">
            {props.value
              ? props.value + formatMessage({ id: 'Phishing_3', defaultMessage: '단계' })
              : '-'}
          </Tag>
        );
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' }),
      accessor: 'phishingName',
      resizable: true,
      sortable: true,
      width: 300,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_3', defaultMessage: '등록일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 150,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  if (isAdmin === 1) {
    baseColumns.splice(2, 0, {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 80,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value) {
          printValue = props.value;
        }
        return printValue;
      },
    });

    baseColumns.splice(13, 0, {
      Header: formatMessage({ id: 'Template_26', defaultMessage: '공개' }),
      accessor: 'isPublic',
      sortable: true,
      resizable: false,
      width: 83,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = 'O';
        }
        return printValue;
      },
    });
  }

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="isEnable"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
