import Icon, { SettingOutlined } from '@ant-design/icons';
import moment from 'moment';
import { useEffect, useMemo, useState } from 'react';
import { useIntl } from 'react-intl';
import { shallowEqual } from 'react-intl/src/utils';
import { useDispatch, useSelector } from 'react-redux';
import { getUser, changeParam } from 'store/user';
import { Option } from 'utils/commonValues';
import TableList from 'components/common/TableList';
import TablePagination from 'components/common/TablePagenation';
import TableSearch from 'components/common/TableSearch';
import UserSettings from 'components/branch/user/UserSettings';
import TableDatePicker from 'components/common/TableDatePicker';

export default function UserList(props: any) {
  let tableRowCount = Math.trunc((window.innerHeight - 315) / 48);
  tableRowCount = tableRowCount < 5 ? 5 : tableRowCount;

  const { formatMessage } = useIntl();
  const [loading, setLoding] = useState(false);
  const [pageSize, setPageSize] = useState(tableRowCount);
  const [currentPage, setCurrentPage] = useState(0);
  const dispatch = useDispatch();
  const { sort, filter } = useSelector(
    (state: any) => ({
      sort: state.user.param.sort,
      filter: state.user.param.filter,
    }),
    shallowEqual,
  );
  const needReload = useSelector((state: any) => state.user.needReload);
  const respData = useSelector((state: any) => state.user.data);
  const totalCount = useSelector((state: any) => state.user.totalCount);
  const totalPages = useSelector((state: any) => state.user.totalPages);
  const isAdmin = useSelector((state: any) => state.login.userData.isAdmin);

  useEffect(() => {
    getUserhData();
  }, [sort, filter, pageSize, currentPage, needReload]);

  // API 호출
  const getUserhData = async () => {
    if (!loading) {
      setLoding(true);

      try {
        const params = {
          filter: JSON.stringify(filter),
          sort: JSON.stringify(sort),
          offset: pageSize * (currentPage < 0 ? 0 : currentPage), // page 는 0 번 부터 시작
          limit: pageSize < 1 ? 20 : pageSize,
        };

        await dispatch(getUser(params));
      } catch (e) {
        console.log(e);
      }
    }

    setLoding(false);
  };

  // 검색
  const searchOption = {
    searchAll: formatMessage({ id: 'Faq_1', defaultMessage: '전체' }),
    serviceNo: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
    serviceName: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
    userNo: 'No',
    userEmail: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
    userPhone: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
    storeName: formatMessage({ id: 'User_29', defaultMessage: '판매점명' }),
    storeId: formatMessage({ id: 'User_23', defaultMessage: '판매점ID' }),
    customerId: formatMessage({ id: 'User_24', defaultMessage: '고객ID' }),
  };

  // 필터
  const filterOption: { [key: string]: any } = {
    attachCategoryArray: {
      name: formatMessage({ id: 'User_2', defaultMessage: '로그인 실패' }),
      child: [
        {
          label: formatMessage({ id: 'User_3', defaultMessage: '잠김' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'User_4', defaultMessage: '정상' }),
          value: '2',
        },
      ],
    },
    attachExamTypeArray: {
      name: formatMessage({ id: 'User_4', defaultMessage: '활성' }),
      child: [
        {
          label: formatMessage({ id: 'User_6', defaultMessage: '비활성화' }),
          value: '1',
        },
        {
          label: formatMessage({ id: 'User_7', defaultMessage: '활성화' }),
          value: '2',
        },
      ],
    },
    isEnableArray: {
      name: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      child: [
        {
          label: 'O',
          value: '1',
        },
        {
          label: '-',
          value: '0',
        },
      ],
    },
  };
  // 테이블
  const baseColumns: Array<{ [key: string]: any }> = [
    {
      Header: <SettingOutlined />,
      accessor: 'setting',
      resizable: false,
      sortable: false,
      width: 88,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center settings',
      Cell: function cellFunc(props: { [key: string]: any }) {
        return <UserSettings isAdmin={isAdmin} dataInfo={props.row.original} />;
      },
    },
    {
      Header: formatMessage({ id: 'Service_8', defaultMessage: '서비스No' }),
      accessor: 'serviceNo',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Service_1', defaultMessage: '서비스명' }),
      accessor: 'serviceName',
      resizable: false,
      sortable: true,
      width: 143,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: 'No',
      accessor: 'userNo',
      resizable: false,
      sortable: true,
      width: 72,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center number-text',
    },
    {
      Header: formatMessage({ id: 'Name_1', defaultMessage: '이름' }),
      accessor: 'userName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td text-align-center',
    },
    {
      Header: formatMessage({ id: 'Email_1', defaultMessage: '이메일' }),
      accessor: 'userEmail',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th',
      className: 'react-table-td',
    },
    {
      Header: formatMessage({ id: 'Contact_1', defaultMessage: '연락처' }),
      accessor: 'userPhone',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'User_23', defaultMessage: '판매점ID' }),
      accessor: 'storeId',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'User_29', defaultMessage: '판매점명' }),
      accessor: 'storeName',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'User_24', defaultMessage: '고객ID' }),
      accessor: 'customerId',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
    },
    {
      Header: formatMessage({ id: 'User_2', defaultMessage: '로그인 실패' }),
      accessor: 'loginFail',
      resizable: false,
      sortable: true,
      width: 120,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
    },
    {
      Header: formatMessage({ id: 'User_5', defaultMessage: '활성' }),
      accessor: 'userActived',
      sortable: true,
      resizable: false,
      width: 80,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: function icon(props: { [key: string]: any }) {
        let printValue = '-';
        if (props.value) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'Template_34', defaultMessage: '삭제 여부' }),
      accessor: 'userEnabled',
      resizable: false,
      sortable: true,
      width: 110,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 0) {
          printValue = 'O';
        }
        return printValue;
      },
    },
    {
      Header: formatMessage({ id: 'ServiceShare_2', defaultMessage: '공유 서비스' }),
      accessor: 'sharedServiceNameArray',
      resizable: false,
      sortable: false,
      width: 172,
      headerClassName: 'react-table-th',
      className: 'react-table-td sub-text',
      Cell: function shared(props: { [key: string]: any }) {
        return props.value ? (
          <div title={props.value.join(', ')}>{props.value.join(', ')}</div>
        ) : (
          '-'
        );
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="PasswdUpdateEpoch"
          title={formatMessage({ id: 'Date_8', defaultMessage: '비밀번호 변경일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'passwdUpdateEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function paswwchange(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
    {
      Header: (
        <TableDatePicker
          dateType="RegEpoch"
          title={formatMessage({ id: 'Date_2', defaultMessage: '가입일시' })}
          filter={filter}
          actionParam={changeParam}
        />
      ),
      accessor: 'regEpoch',
      resizable: false,
      sortable: true,
      width: 177,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center sub-text',
      Cell: function regEpoch(props: { [key: string]: any }) {
        const { value } = props;
        const dateFormat = localStorage.getItem('language') === 'en' ? 'MM/DD/YYYY' : 'YYYY-MM-DD';
        return <div>{moment.unix(value).format(`${dateFormat} HH:mm:ss`)}</div>;
      },
    },
  ];

  if (Option.isEdu === 1) {
    baseColumns.splice(Option.customer === 'saxa' ? 10 : 7, 0, {
      Header: formatMessage({ id: 'Edu_3', defaultMessage: '교육' }),
      accessor: 'isEdu',
      sortable: true,
      resizable: false,
      width: 83,
      headerClassName: 'react-table-th overflow-visible',
      className: 'react-table-td text-align-center',
      Cell: (props: { [key: string]: any }) => {
        let printValue = '-';
        if (props.value === 1) {
          printValue = 'O';
        }
        return printValue;
      },
    });
  }

  const columns: Array<any> = useMemo(() => baseColumns, [respData]);

  if (
    respData?.result === 1 &&
    respData.success &&
    Array.isArray(respData.success) &&
    respData.success.length > 0
  ) {
    if (respData.success[0].rnum) {
      respData.success.map((userData: any, index: number) => {
        if (userData.loginFail === 1) {
          respData.success[index].loginFail = `${formatMessage({
            id: 'User_3',
            defaultMessage: '잠김',
          })}
            (${userData.loginFailCount}${formatMessage({ id: 'User_8', defaultMessage: '회' })})`;
        } else {
          respData.success[index].loginFail = `${formatMessage({
            id: 'User_4',
            defaultMessage: '정상',
          })}
          (${userData.loginFailCount}${formatMessage({ id: 'User_8', defaultMessage: '회' })})`;
        }

        return true;
      });
    }
  }

  return (
    <>
      <TableSearch
        pageSize={pageSize}
        setPageSize={setPageSize}
        totalCount={totalCount}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        changeParam={changeParam}
        searchOption={searchOption}
        filter={filter}
        filterOption={filterOption}
      />
      <TableList
        columns={columns}
        data={respData.success}
        changeParam={changeParam}
        loading={loading}
        disableKey="userEnabled"
      />
      <TablePagination
        totalPages={totalPages}
        currentPage={currentPage + 1}
        setCurrentPage={setCurrentPage}
      />
    </>
  );
}
