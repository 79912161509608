import { useCallback, useEffect } from 'react';
import videojs from 'video.js';
import 'video.js/dist/video-js.css';

// 기본 옵션
const initalOptions = {
  height: 390,
  autoPlay: false,
  playbackRates: [1, 1.25, 1.5],
  controls: true,
  controlBar: {
    pictureInPictureToggle: false,
  },
  nativeTextTracks: true,
  inactivityTimeout: 0,
};

function EduVideoPlayer({ curVideoData, videoOption, onReadyPlayerEvent }: any) {
  // 비디오 컴포넌트 (비디오 옵션이 변경되었을때만 렌더링)
  const VideoComponent = useCallback(() => {
    return (
      <div data-vjs-player>
        <video id="my-video" className="video-js vjs-big-play-centered">
          <track kind="captions" />
        </video>
      </div>
    );
  }, [videoOption]);

  useEffect(() => {
    const curPlayStep = curVideoData.playStep ? curVideoData.playStep - 1 : 0;

    // 비디오 객체 생성 및 옵션 설정
    let player: any = null;
    player = videojs('my-video', {
      ...initalOptions,
      ...videoOption[curPlayStep],
    });
    player?.poster(videoOption[curPlayStep].poster);
    player?.currentTime(curVideoData.playTime);
    player?.src(videoOption[curPlayStep].sources[0]);
    player?.controlBar.progressControl.disable();
    player?.ready(function () {
      // 생성한 플레이어를 상위 컴포넌트에 넘겨줌
      onReadyPlayerEvent(player);
    });

    return () => {
      if (player) {
        player.dispose();
      }
    };
  }, [videoOption]);

  return <VideoComponent />;
}

export default EduVideoPlayer;
