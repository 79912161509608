import parse from 'html-react-parser';
import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { findPassword } from 'store/edumain';
import { Option } from 'utils/commonValues';
import FormTextField from 'components/common/FormTextField';

function Findpassword(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const [loading, setLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const { errors, control, register, handleSubmit } = useForm({
    mode: 'all',
  });

  const edumainStore = useSelector((state: any) => {
    return {
      serviceNo: state.edumain.serviceNo,
      userData: state.edumain.userData,
    };
  }, shallowEqual);

  useEffect(() => {
    // form validation 표시
    if (Object.keys(errors)?.length > 0) {
      setErrorMessage(Object.values(errors)[0]?.message);
    }
  }, [Object.keys(errors)]);

  const onFindPassword = async (form: any) => {
    try {
      const params = {
        serviceNo: edumainStore.serviceNo,
        eduTargetId: form.eduEmail,
      };
      const response: any = await dispatch(findPassword(params));

      const { list, error } = response?.data;
      if (response?.data?.list) {
        if (list.isExist === 1) {
          // 메일 발송 성공
          alert(
            formatMessage({ id: 'Edu_74', defaultMessage: '메일이 성공적으로 발송되었습니다.' }),
          );
          history.push(`/edumain/edulogin?serviceNo=${edumainStore.serviceNo}`);
        } else {
          // 메일 발송 실패
          setErrorMessage(
            formatMessage({ id: 'Email_18', defaultMessage: '존재하지 않는 계정입니다.' }),
          );
        }
      } else {
        setErrorMessage((error && error[0]?.errInfo) || '');
      }
    } catch (error) {
      console.log('Findpassword onFindPassword', error);
    }
  };

  return (
    <div className="edu-layout">
      <div className="layout-left">
        <div className="layout-left-box find-password">
          <img className="login-bg" src="/img/edu/title_bg.png" alt="bg" />
          <span className="login-text">
            {formatMessage({ id: 'Password_5', defaultMessage: '비밀번호 찾기' })}
          </span>
          <img className="arrow" src="/img/edu/login_arr.png" alt="arrow" />
        </div>
      </div>
      <div className="layout-right">
        <div className="r-logo">
          <img src="/img/edu/r_logo.jpg" alt="logo" />
        </div>

        <div className="layout-right-box">
          <div className="edu-title">
            {parse(
              formatMessage({
                id: 'Edu_72',
                defaultMessage: '입력하신 이메일로<br/>로그인 정보를 전송합니다.',
              }),
            )}
          </div>

          <div className="edu-form-box">
            <form
              autoComplete="off"
              onChange={() => setErrorMessage('')}
              onSubmit={handleSubmit(onFindPassword)}
            >
              <div className="text-field-title">
                {formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
              </div>
              <FormTextField
                name="eduEmail"
                register={register}
                placeholder={formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                validation={{
                  validate: {
                    required: (value: any) =>
                      !value
                        ? formatMessage({ id: 'Email_6', defaultMessage: '이메일을 입력해 주세요' })
                        : undefined,
                  },
                }}
              />
              {!!errorMessage && (
                <div className="edu-error-message">
                  <img src="/img/edu/fail_login_i.jpg" alt="icon" />
                  {errorMessage}
                </div>
              )}

              <div className="edu-button-box">
                <button className="btn-orange" type="submit">
                  {formatMessage({ id: 'Button_3', defaultMessage: '확인' })}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Findpassword;
