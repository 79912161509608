/* eslint-disable react/jsx-props-no-spreading */
import { useDispatch } from 'react-redux';
import Loading from 'components/common/Loading';
import { useTable, useSortBy, useResizeColumns, useFlexLayout, useExpanded } from 'react-table';

export default function TableList(props: any) {
  const dispatch = useDispatch();

  const { columns, data, changeParam, loading, SubComponent, disableKey } = props;

  const { getTableProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columns,
      data: data,
      manualSortBy: true,
    },
    useResizeColumns,
    useFlexLayout,
    useSortBy,
    useExpanded,
  );

  // 정렬
  const sortTemplate = (column: { [key: string]: any }) => {
    const sort = [];
    sort.push({ field: column.id, order: column.isSortedDesc ? 'DESC' : 'ASC' });

    dispatch(
      changeParam({
        name: 'sort',
        value: sort,
      }),
    );
  };

  return (
    <div className="react-table">
      <Loading loading={loading} />
      <div {...getTableProps()} className="ReactTable">
        <div className="rt-table">
          <div
            className="rt-thead -header"
            style={{
              minWidth: headerGroups[0].headers.reduce(
                (sum: number, value: { [key: string]: any }) => sum + value.width,
                0,
              ),
            }}
          >
            {headerGroups.map((headerGroup: { [key: string]: any }) => (
              <div {...headerGroup.getHeaderGroupProps()} className="rt-tr" key="header">
                {headerGroup.headers.map((column: { [key: string]: any }) => (
                  <div
                    role="button"
                    tabIndex={0}
                    key={column.id}
                    onClick={
                      column.sortable
                        ? (e) => {
                            if (column.isSortedDesc) {
                              column.toggleSortBy(false, false);
                            } else {
                              column.toggleSortBy(true, false);
                            }

                            sortTemplate(column);
                          }
                        : undefined
                    }
                    onKeyDown={() => 'test'}
                    {...column.getHeaderProps([
                      {
                        className: `ellipsis ${column.headerClassName} ${
                          column.sortable ? '' : 'no-sort'
                        }`,
                        style: { minWidth: columns.minWidth || 0 },
                      },
                    ])}
                  >
                    <div>{column.render('Header')}</div>
                    {column.isSorted &&
                      (column.isSortedDesc ? (
                        // <div className="img-asc-ordering" />                        
                        <img className="img-asc-ordering" src="/img/table/ico_ascending.png" alt="ascend" />
                      ) : (
                        // <div className="img-desc-ordering" />
                        <img className="img-desc-ordering" src="/img/table/ico_descending.png" alt="descend" />
                      ))}
                    <div
                      {...column.getResizerProps([
                        { className: column.resizable ? 'rt-resizer' : '' },
                      ])}
                      role="button"
                      tabIndex={0}
                      onClick={(e) => e.stopPropagation()}
                      onKeyDown={(e) => e.stopPropagation()}
                    />
                  </div>
                ))}
              </div>
            ))}
          </div>
          <div
            className="react-table-tbody-wrapper"
            style={{
              minWidth: headerGroups[0].headers.reduce(
                (sum: number, value: { [key: string]: any }) => sum + value.width,
                0,
              ),
            }}
          >
            <div className="rt-tbody">
              {rows.map((row: any) => {
                prepareRow(row);

                let customClassName = '';
                // 라이선스 테이블
                if (disableKey === 'licenseEnabled') {
                  if (row.original.licenseEnabled === 0) {
                    customClassName = 'delete-item';
                  } else if (row.original.approve === 0) {
                    customClassName = 'license-applying-item';
                  } else if (row.original.status === 0) {
                    customClassName = 'license-status-orange';
                  }
                } // 모든 테이블
                else if (row.original[disableKey] === 0) {
                  customClassName = 'delete-item';
                }

                return (
                  <div key={row.id} className="rt-tr-group">
                    <div {...row.getRowProps()} key={row.id} className={customClassName}>
                      {row.cells.map((cell: { [key: string]: any }) => {
                        return (
                          <div
                            {...cell.getCellProps([
                              { className: `ellipsis ${cell.column.className}` },
                            ])}
                            key={cell.column.id}
                          >
                            {cell.render('Cell')}
                          </div>
                        );
                      })}
                    </div>
                    {row.isExpanded && <SubComponent row={row} />}
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
