import { Collapse, Progress, Tag } from 'antd';
import moment from 'moment';
import { useIntl } from 'react-intl';
import { commaNumber, nowEpoch, timeFormatFromUTCEpoch } from 'utils/commonFunctions';
import './LicenseUserList.scss';

// import forbiddenImg from 'img/setting/forbidden.png';
// import offImg from 'img/setting/license-off.png';

function LicenseUserList(props: any) {
  const { formatMessage } = useIntl();

  const { Panel } = Collapse;
  const { licenseInfo, existLicenseType, existLicenseData } = props;
  const nowEpochTime = nowEpoch();
  const targetNoArray: any = [];
  const {
    licenseNo,
    serviceName,
    startEpoch,
    endEpoch,
    licenseCount,
    licenseType,
    examUsedCount,
    examLimitCount,
    examLimitInfo,
    targetLimitInfo,
    orderUserName,
    orderUserEmail,
    regEpoch,
    issueEpoch,
    targetUsedCount,
  } = licenseInfo;

  const licenseStatus = existLicenseData[existLicenseType].byNo[licenseNo];

  const licenseOptionData: { [key: number]: any } = {
    0: {
      labelColor: '#ffb400',
      labelContent: formatMessage({ id: 'Status_3', defaultMessage: '신청중' }),
      gridClass: 'license-request license-process',
      periodProgressProps: {},
    },
    1: {
      labelColor: '#00ad7b',
      labelContent: formatMessage({ id: 'Status_4', defaultMessage: '사용중' }),
      gridClass: 'license-use license-process',
      periodProgressProps: { active: true },
    },
    2: {
      labelColor: '#4183c4',
      labelContent: formatMessage({ id: 'Status_5', defaultMessage: '사용대기' }),
      gridClass: 'license-ready license-process',
      periodProgressProps: {},
    },
    3: {
      labelColor: '#dddddd',
      labelContent: formatMessage({ id: 'Status_7', defaultMessage: '만료' }),
      gridClass: 'license-expired license-process',
      periodProgressProps: { color: 'grey' },
    },
  };
  const licenseOption = licenseOptionData[licenseStatus];

  let expireSoon;
  // 기간 라이센스만 표시
  if (existLicenseType === 'period' && licenseStatus !== 0) {
    if (nowEpoch > endEpoch || licenseStatus === 3) {
      expireSoon = (
        <div className="expire-soon">
          <img src="/img/setting/forbidden.png" alt="img" />
          {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
        </div>
      );
    } else if (endEpoch - nowEpochTime < 14 * 24 * 60 * 60) {
      expireSoon = (
        <div className="expire-soon">
          <img src="/img/setting/forbidden.png" alt="img" />
          {`${formatMessage({ id: 'License_31', defaultMessage: '만료까지' })} ${moment
            .duration((endEpoch - nowEpochTime) * 1000)
            .humanize()} ${formatMessage({ id: 'License_32', defaultMessage: '' })}`}
        </div>
      );
    }
  }
  // 횟수
  if (existLicenseType === 'times') {
    if (licenseStatus === 3) {
      expireSoon = (
        <div className="expire-soon">
          <img src="/img/setting/forbidden.png" alt="img" />
          {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
        </div>
      );
    }
  }
  if (existLicenseType === 'periodtimes') {
    if (nowEpoch > endEpoch || licenseStatus === 3) {
      expireSoon = (
        <div className="expire-soon">
          <img src="/img/setting/forbidden.png" alt="img" />
          {formatMessage({ id: 'Status_7', defaultMessage: '만료' })}
        </div>
      );
    }
  }

  let periodPercent = 0;
  // 만료
  if (licenseStatus === 3) {
    periodPercent = 100;
    // 사용중
  } else if (licenseStatus === 1) {
    // 기간 라이선스
    if (licenseType === 1 || licenseType === 2) {
      periodPercent = Math.round(((nowEpochTime - startEpoch) / (endEpoch - startEpoch)) * 100);
      // 횟수 라이선스
    } else if (licenseType === 4 || licenseType === 8 ) {
      periodPercent = Math.round((examUsedCount / examLimitCount) * 100);
    }
  }

  // 횟수 상세보기 - 훈련, 교육
  const detailList: Array<JSX.Element> = [];

  if (examLimitInfo) {
    examLimitInfo.map((infoDetail: any, index: number) => {
      const examNoArray: Array<any> = [];
      if (infoDetail.examNoArray && infoDetail.examNoArray.length > 0) {
        infoDetail.examNoArray.map((data: any, index: number) => {
          examNoArray.push(data);
          return true;
        });
      }
      detailList.push(
        <div>
          {!!infoDetail.examNo && (
            <div className="content-list mtb-5">
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'Exam_1', defaultMessage: '훈련명' })}`}
                </span>
                <span className="detail-content">{infoDetail.examName}</span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'Period_3', defaultMessage: '훈련 기간' })}`}
                </span>
                <span className="detail-content">
                  {`${timeFormatFromUTCEpoch(
                    infoDetail.examStartEpoch,
                    3,
                  )} ~ ${timeFormatFromUTCEpoch(infoDetail.examEndEpoch, 3)}`}
                </span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'Template_24', defaultMessage: '템플릿명' })}`}
                </span>
                <span className="detail-content">
                  {infoDetail.templatePick === 1 ? (
                    <Tag className="template-user-label color-temp-user">
                      {formatMessage({ id: 'User_1', defaultMessage: '사용자' })}
                    </Tag>
                  ) : (
                    <div className="template-user-label color-temp-system">
                      {formatMessage({ id: 'Template_14', defaultMessage: '기 본' })}
                    </div>
                  )}
                  {infoDetail.templateName}
                </span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'License_33', defaultMessage: '훈련 실행자' })}`}
                </span>
                <span className="detail-content">{`${infoDetail.userName}(${infoDetail.userEmail})`}</span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'License_34', defaultMessage: '훈련 대상수' })}`}
                </span>
                <span className="detail-content">
                  {infoDetail.targetNoArray.length}
                  {formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
                </span>
              </div>
            </div>
          )}
          {!!infoDetail.eduNo && (
            <div className="content-list mtb-5">
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'Edu_28', defaultMessage: '교육명' })}`}
                </span>
                <span className="detail-content">{infoDetail.eduName}</span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'Period_4', defaultMessage: '교육 기간' })}`}
                </span>
                <span className="detail-content">
                  {`${timeFormatFromUTCEpoch(infoDetail.startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                    infoDetail.endEpoch,
                    3,
                  )}`}
                </span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'License_35', defaultMessage: '교육 실시자' })}`}
                </span>
                <span className="detail-content">{`${infoDetail.userName}(${infoDetail.userEmail})`}</span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'License_36', defaultMessage: '교육 대상수' })}`}
                </span>
                <span className="detail-content">
                  {infoDetail.targetNoArray.length}
                  {formatMessage({ id: 'StartExam_35', defaultMessage: '명' })}
                </span>
              </div>
              <div>
                <span className="detail-title">
                  {`· ${formatMessage({ id: 'License_37', defaultMessage: '컨텐츠' })}No`}
                </span>
                <span className="detail-content">{infoDetail.eduAttachNo}</span>
              </div>
              {examNoArray && examNoArray.length > 0 && (
                <div>
                  <span className="detail-title">
                    {`· ${formatMessage({ id: 'License_38', defaultMessage: '연결 훈련' })}`}
                  </span>
                  <span className="detail-content">{examNoArray.join(', ')}</span>
                </div>
              )}
            </div>
          )}
        </div>,
      );
      return true;
    });
  }

  const dotStyle = { backgroundImage: 'url(/img/setting/list-dot.png)' };

  return (
    <div className="user-license-set">
      <div className="license-box">
        <div>
          <div className="segment-box">
            <div
              className={`license-title ${licenseOption.gridClass ? licenseOption.gridClass : ''}`}
            >
              <img src="/img/setting/license-off.png" alt="img" />
              <div className="title-box">
                <div className={`${licenseOption.gridClass ? licenseOption.gridClass : ''}`}>
                  {licenseOption.labelContent}
                </div>
                <div className="license-user-title inline-block">
                  {`${serviceName} / ${formatMessage({
                    id: 'License_1',
                    defaultMessage: '라이선스',
                  })} No.${licenseNo}`}
                </div>
              </div>
              {expireSoon}
            </div>

            <div className="license-info">
              {/* 라이선스 타입 */}
              <div className="info-row">
                <div className="info-title" style={dotStyle}>
                  {formatMessage({ id: 'License_18', defaultMessage: '라이선스 타입' })}
                </div>
                <div className="info-value bold">
                  {licenseType === 1 &&
                    formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' })}
                  {licenseType === 2 &&
                    formatMessage({ id: 'License_7', defaultMessage: '기간 라이선스' })}
                  {licenseType === 4 &&
                    formatMessage({ id: 'License_8', defaultMessage: '횟수 라이선스' })}
                  {licenseType === 8 &&
                    formatMessage({ id: 'License_58', defaultMessage: '기간+횟수 라이선스' })}
                </div>
              </div>
              {/* 영구 라이선스 */}
              {licenseType === 1 && (
                <div className="info-row">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'Date_24', defaultMessage: '유효기간' })}
                  </div>
                  <div className="info-value">
                    {formatMessage({ id: 'License_15', defaultMessage: '영구 라이선스' })}
                  </div>
                </div>
              )}
              {/* 기간 라이선스 */}
              {licenseType === 2 && (
                <div className="info-row">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'Date_24', defaultMessage: '유효기간' })}
                  </div>
                  <div className="info-progress-group">
                    <div className="info-value">
                      {`${timeFormatFromUTCEpoch(startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                        endEpoch,
                        3,
                      )}`}
                    </div>
                    <div className="info-extra">
                      <Progress
                        className="license-progress"
                        percent={periodPercent}
                        status="active"
                        showInfo={false}
                        strokeColor={licenseOption.labelColor}
                        strokeWidth={6}
                      />
                      <div className="progress">{periodPercent}%</div>
                    </div>
                  </div>
                </div>
              )}
              {/* 기간+횟수 라이선스 - 훈련 횟수 */}
              { licenseType === 8 && (
                <div className="info-row">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'Date_24', defaultMessage: '유효기간' })}
                  </div>
                  <div className="info-progress-group">
                    <div className="info-value">
                      {`${timeFormatFromUTCEpoch(startEpoch, 3)} ~ ${timeFormatFromUTCEpoch(
                        endEpoch,
                        3,
                      )}`}
                    </div>
                    <div className="info-extra">
                      <Progress
                        className="license-progress"
                        percent={periodPercent}
                        status="active"
                        showInfo={false}
                        strokeColor={licenseOption.labelColor}
                        strokeWidth={6}
                      />
                      <div className="progress">{periodPercent}%</div>
                    </div>
                  </div>
                </div>
              )}
              {/* 횟수 라이선스 - 훈련 횟수 */}
              {(licenseType === 4 || licenseType === 8) && (
                <div className="info-row">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'License_11', defaultMessage: '훈련 횟수' })}
                  </div>
                  <div className="info-progress-group">
                    <div className="info-value">
                      {`${examUsedCount} / ${examLimitCount}${formatMessage({
                        id: 'License_13',
                        defaultMessage: '회',
                      })}`}
                    </div>
                    <div className="info-extra">
                      <Progress
                        className="license-progress"
                        percent={periodPercent}
                        status="active"
                        showInfo={false}
                        strokeColor={licenseOption.labelColor}
                        strokeWidth={6}
                      />
                      <div className="progress">{periodPercent}%</div>
                    </div>
                  </div>
                </div>
              )}
              <div className="info-row">
                <div className="info-title" style={dotStyle}>
                  {formatMessage({ id: 'License_16', defaultMessage: '수량' })}
                </div>
                <div className="info-value">
                  {(licenseType === 4 || licenseType === 8)
                    ? commaNumber(licenseCount)
                    : `${targetUsedCount} / ${commaNumber(licenseCount)}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}
                </div>
                <div className="info-extra">&nbsp;</div>
              </div>
              <div className="info-row">
                <div className="info-title" style={dotStyle}>
                  {formatMessage({ id: 'License_21', defaultMessage: '신청자' })}
                </div>
                {orderUserName && orderUserEmail ? (
                  <div className="info-wrap">
                    <div className="info-value">{`${orderUserName} (${orderUserEmail})`}</div>
                    <div className="info-extra">&nbsp;</div>
                  </div>
                ) : (
                  <div className="info-wrap">
                    <div className="info-value">
                      {formatMessage({ id: 'Name_6', defaultMessage: '관리자' })}
                    </div>
                    <div className="info-extra">&nbsp;</div>
                  </div>
                )}
              </div>
              <div className="info-row">
                <div className="info-title" style={dotStyle}>
                  {formatMessage({ id: 'Date_14', defaultMessage: '신청일자' })}
                </div>
                <div className="info-value">{timeFormatFromUTCEpoch(regEpoch)}</div>
                <div className="info-extra">&nbsp;</div>
              </div>
              <div className="info-row">
                <div className="info-title" style={dotStyle}>
                  {formatMessage({ id: 'Date_15', defaultMessage: '발급일자' })}
                </div>
                <div className="info-value">{timeFormatFromUTCEpoch(issueEpoch)}</div>
                <div className="info-extra">&nbsp;</div>
              </div>

              {/* 기간 라이선스 - 라이선스 사용 내역 */}
              {
                (licenseType === 1 || licenseType === 2) && !!targetLimitInfo && (
                  <div className="info-row examLimitInfo-area">
                    <div className="info-title" style={dotStyle}>
                      {formatMessage({ id: 'License_39', defaultMessage: '사용 내역' })}
                    </div>
                    <div className="info-value examLimitInfo">
                      <Collapse ghost>
                        <Panel
                          header={formatMessage({
                            id: 'License_40',
                            defaultMessage: '상세 보기',
                          })}
                          key="1"
                        >
                          {Object.keys(targetLimitInfo).map((infoDetail, index) => {
                            targetNoArray.push(infoDetail);
                            return true;
                          })}
                          {!!targetNoArray && targetNoArray.length > 0 && (
                            <div className="content">
                              <span>{targetNoArray.join(', ')}</span>
                            </div>
                          )}
                        </Panel>
                      </Collapse>
                    </div>
                  </div>
                )
                // )
              }

              {/* 횟수 라이선스 - 라이선스 사용 내역 */}
              {(licenseType === 4)&& !!examLimitInfo && (
                <div className="info-row examLimitInfo-area">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'License_39', defaultMessage: '사용 내역' })}
                  </div>
                  <div className="info-value examLimitInfo">
                    <Collapse ghost>
                      <Panel
                        header={formatMessage({
                          id: 'License_40',
                          defaultMessage: '상세 보기',
                        })}
                        key="1"
                      >
                        <div className="content">{detailList && detailList}</div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              )}
              
              {(licenseType === 8)&& !!examLimitInfo && (
                <div className="info-row examLimitInfo-area">
                  <div className="info-title" style={dotStyle}>
                    {formatMessage({ id: 'License_39', defaultMessage: '사용 내역' })}
                  </div>
                  <div className="info-value examLimitInfo">
                    <Collapse ghost>
                      <Panel
                        header={formatMessage({
                          id: 'License_40',
                          defaultMessage: '상세 보기',
                        })}
                        key="1"
                      >
                        <div className="content">{detailList && detailList}</div>
                      </Panel>
                    </Collapse>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LicenseUserList;
