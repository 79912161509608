import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Dropdown, Menu, Tag, Tooltip } from 'antd';
import {
  DownOutlined,
  UpOutlined,
  FormatPainterFilled,
  HighlightFilled,
  DeleteFilled,
  HighlightOutlined,
  DeleteOutlined,
  CheckOutlined,
  CloseOutlined,
  EditFilled,
} from '@ant-design/icons';
import { saveForm, initForm } from 'store/form';
import FormTextField from 'components/common/FormTextField';
import * as valid from 'utils/validation';
import TagColor from './TagColor';

interface tagProps {
  type: string;
  tagInitial: string;
  tagData: any;
  isOpen: boolean;
  isEditMode: boolean;
  selectedTag: Array<string | number>;
  editData: any;
  toggleOpenList: (tagName: string) => void;
  clickTag: (tag: string | number) => void;
  changeTagName: (form: any) => void;
  changeTagColor: (color: string) => void;
  setEditData: (data: any) => void;
  setModal: (modal: string) => void;
}

function TagItem({
  type,
  tagInitial,
  tagData,
  isOpen,
  isEditMode,
  selectedTag,
  editData,
  toggleOpenList,
  clickTag,
  changeTagName,
  changeTagColor,
  setEditData,
  setModal,
}: tagProps) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const { errors, register, watch, handleSubmit } = useForm({
    mode: 'onChange',
  });
  const { tagName } = watch();
  const [mode, setMode] = useState('');
  const [colorVisible, setColorVisible] = useState(false);

  // 현재 입력 값 폼 스토어에 저장
  const onSaveForm = (e: any) => {
    dispatch(saveForm({ tagName: e.target.value }));
  };

  return (
    <div className="tag-item">
      <div className="tag-title">
        <div className="title">{tagInitial}</div>
        <div className="arrow-icon" onClick={() => toggleOpenList(tagInitial)} aria-hidden="true">
          {isOpen ? <UpOutlined /> : <DownOutlined />}
        </div>
      </div>
      {isOpen && (
        <div className="tag-content">
          {tagData.map((tagInfo: any) => {
            // 수정 모드
            return isEditMode ? (
              <form
                autoComplete="off"
                onChange={onSaveForm}
                onSubmit={handleSubmit((form: any) => changeTagName(form.tagName))}
              >
                <Tag
                  className={`color-tag tag-label-${tagInfo.color} ${
                    selectedTag.includes(tagInfo.tagNo) ? `selected tag-${tagInfo.color}` : ''
                  }`}
                  key={tagInfo.tagNo}
                  onClick={(e: any) => {
                    e.stopPropagation();
                    setEditData(tagInfo);
                  }}
                  aria-hidden="true"
                >
                  <div className="tag-edit-btns flex">
                    {mode === 'name' && editData?.tagNo === tagInfo.tagNo ? (
                      // 태그명 수정
                      <>
                        <FormTextField
                          className="tag-input"
                          name="tagName"
                          defaultValue={tagInfo.tagName}
                          error={errors.tagName}
                          register={register}
                          validation={{
                            validate: {
                              required: (value: any) => valid.required(value),
                              name: (value: any) => valid.name(value),
                            },
                          }}
                          title={tagInfo.tagName}
                        />
                        <div className="tag-btn-group flex">
                          <div
                            title={formatMessage({ id: 'Button_18', defaultMessage: '저장' })}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              if (!errors.tagName) {
                                changeTagName(tagName);
                                setMode('save');
                              }
                            }}
                            aria-hidden="true"
                          >
                            <CheckOutlined />
                          </div>
                          <div
                            title={formatMessage({ id: 'Button_2', defaultMessage: '취소' })}
                            onClick={(e: any) => {
                              e.stopPropagation();
                              setEditData({});
                              setMode('cancel');
                            }}
                            aria-hidden="true"
                          >
                            <CloseOutlined />
                          </div>
                        </div>
                      </>
                    ) : (
                      // 태그명 출력, 색상 수정, 태그 삭제
                      <>
                        <div
                          className="underline ellipsis"
                          onClick={(e: any) => setMode('name')}
                          aria-hidden="true"
                          title={tagInfo.tagName}
                        >
                          {tagInfo.tagName}
                        </div>
                        <div className="tag-btn-group flex">
                          <Dropdown
                            visible={mode === 'color' && colorVisible}
                            trigger={['click']}
                            overlay={
                              editData?.tagNo === tagInfo.tagNo ? (
                                <Menu>
                                  <TagColor
                                    color={editData.color}
                                    onSelectColor={(colorValue: string) => {
                                      changeTagColor(colorValue);
                                      setColorVisible(false);
                                    }}
                                  />
                                </Menu>
                              ) : (
                                <></>
                              )
                            }
                          >
                            <HighlightOutlined
                              title={formatMessage({ id: 'Tag_47', defaultMessage: '색상 수정' })}
                              onClick={() => {
                                setColorVisible(!colorVisible);
                                setMode('color');
                              }}
                            />
                          </Dropdown>
                          <div
                            title={formatMessage({ id: 'Tag_7', defaultMessage: '태그 삭제' })}
                            onClick={() => {
                              setModal('delete');
                              setMode('delete');
                            }}
                            aria-hidden="true"
                          >
                            <DeleteOutlined />
                          </div>
                        </div>
                      </>
                    )}
                  </div>
                </Tag>
              </form>
            ) : (
              // 기본 모드
              !!tagInfo.isView && (
                <Tag
                  className={`color-tag tag-label-${tagInfo.color} ${
                    selectedTag.includes(tagInfo.tagNo) ? `selected tag-${tagInfo.color}` : ''
                  }`}
                  key={tagInfo.tagNo}
                  onClick={() => clickTag(tagInfo.tagNo)}
                  aria-hidden="true"
                >
                  <span className="ellipsis" title={tagInfo.tagName}>
                    {tagInfo.tagName}
                  </span>
                  {type !== 'declare' && (
                    <Tooltip
                      overlayClassName="black-tooltip full"
                      placement="bottomLeft"
                      title={`${formatMessage({
                        id: 'Tag_46',
                        defaultMessage: '태그에 포함된 대상자 수',
                      })}: ${tagInfo.targetCount}${formatMessage({
                        id: 'StartExam_35',
                        defaultMessage: '명',
                      })}`}
                    >
                      <div className="tag-count">
                        <div className="number">{tagInfo.targetCount}</div>
                      </div>
                    </Tooltip>
                  )}
                </Tag>
              )
            );
          })}
        </div>
      )}
    </div>
  );
}

export default TagItem;
