import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';
import { param } from 'jquery';

// Action Types
const SET_INITIAL = 'admin/SET_INITIAL';
const GET_SYSTEM_CONFIG = 'admin/GET_SYSTEM_CONFIG';
const UPDATE_SYSTEM_CONFIG = 'admin/UPDATE_SYSTEM_CONFIG';
const SYSTEM_CONFIG_INITIAL_BASIC = 'admin/SYSTEM_CONFIG_INITIAL_BASIC';
const CHECK_SMTP = 'admin/CHECK_SMTP';

// Actions
export const systemConfigInitBasic = (params: {[key: string]: string}) => 
  actionFormat(SYSTEM_CONFIG_INITIAL_BASIC, params, 'put', `${execUrl}SystemConfigInit`)
export const getSystemConfig = (params: any) =>
  actionFormat(GET_SYSTEM_CONFIG, params, 'get', `${execUrl}SystemConfig`);
export const adminInitSetting = (params: any) =>
  actionFormat(null, params, 'put', `${apiUrl}Admin/InitSetting`);
export const editPassword = (params: any) =>
  actionFormat(null, params, 'put', `${apiUrl}Admin/AdminEditPasswd`);
export const updateSystemConfig = (params: {[key: string]: any}) =>
  actionFormat(UPDATE_SYSTEM_CONFIG, params, 'put', `${execUrl}SystemConfig`);
export const adminCheckSmtp = () => actionFormat(CHECK_SMTP, null, 'get', `${execUrl}CheckSmtp`);
export const postSyncTest = (params: any) => actionFormat(null, params, 'post', `${apiUrl}Sync/Test`)
export const targetSync = () => actionFormat(null, null, 'post', `${apiUrl}Sync`)

// initial state
interface stateType {
  basicConfig: { [key: string]: any };
  smtpConfig: { [key: string]: any };
  syncConfig: { [key: string]: any };
}

const initialState: stateType = {
  basicConfig: {},
  smtpConfig: {},
  syncConfig: {},
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case SYSTEM_CONFIG_INITIAL_BASIC:
      return initialState;
    case GET_SYSTEM_CONFIG:
      if (action?.payload?.config?.params?.category) {
        const normalizedData: { [key: string]: any } = {
          parameter: {},
          class: {},
          classOrder: [],
        };
        const { category } = action.payload.config.params;
        const { list } = action.payload.data;
        
        const parameterOrder = getFollowOrderArray(list, 'parameter', 'followOrder');

        const seenClass: { [key: string]: any } = {};
        for (let i = 0; i < parameterOrder.length; i += 1) {
          if (typeof list[parameterOrder[i]] === 'undefined') {
            i += 1;
          }
          const config = list[parameterOrder[i]];
          
          normalizedData.parameter[config.parameter] = config;
          normalizedData.parameter[config.parameter].beforeValue = config.value;
          
          if (config.paramType !== 0) {
            if (typeof seenClass[config.class] === 'undefined') {
              normalizedData.classOrder.push(config.class);
              normalizedData.class[config.class] = [];
              seenClass[config.class] = 1;
            }
            normalizedData.class[config.class].push({
              parameter: config.parameter,
              parameterNotice: config.parameterNotice,
              followOrder: config.followOrder,
              paramType: config.paramType,
            });
          } else {
            // 메일 서버 테스트 파라메터
          }
        }
        state = {
          ...state,
          [`${category}Config`]: normalizedData
        }
      }
      return state;
    case UPDATE_SYSTEM_CONFIG:
      if (action?.payload) {
        const { list } = action.payload.data;
        const { category, parameter, value } = list[0];
        let configCategory = "" 
        if (category === "basic") {
          configCategory = "basicConfig"
        } else if (category === "smtp") {
          configCategory = "smtpConfig"
        } else {
          configCategory = "syncConfig"
        }
        
        state[configCategory].parameter[parameter].beforeValue = value;
      }
      return state
    case CHECK_SMTP:
      if (action?.payload) {
        const { list } = action.payload.data;
        
        state.smtpConfig.parameter.mailSmtpTested = list;
      }
      return state
    default:
      return state;
  }
}
