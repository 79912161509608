import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { useIntl, FormattedMessage } from 'react-intl';
import { LoadingOutlined } from '@ant-design/icons';
import { useForm } from 'react-hook-form';
import { getCheckEmail, saveForm, initPwreset } from 'store/login';
import * as valid from 'utils/validation';
import { Option } from 'utils/commonValues';
import '../Login.scss';

// import mfLogoImage from 'img/logo/mf_logo_white.png';
// import sxLogoImage from 'img/logo/sx_logo_white.png';
// import pwIcon from 'img/login/icon_pw.png';
// import pwFailIcon from 'img/login/icon_fail.png';

function Pwreset(props: any) {
  const loginData = useSelector((state: any) => state.login);
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [resend, setResend] = useState(0);
  const { handleSubmit, register, getValues, setError, errors } = useForm();
  const { formatMessage } = useIntl();

  useEffect(() => {
    dispatch(initPwreset());
  }, []);

  const checkEmail = async (formData: any) => {
    // 폼 데이터 스토어에 저장
    dispatch(saveForm(formData));

    setLoading(true);
    try {
      const params = {
        userEmail: formData.userEmail,
      };
      const response: { [key: string]: any } = await dispatch(getCheckEmail(params));
      if (response?.data?.list) {
        const { isExist, isSend } = response.data.list;
        if (isExist === 2) {
          setError('userEmail', {
            type: 'notExist',
            message: formatMessage({ id: 'Email_18', defaultMessage: '존재하지 않는 계정입니다.' }),
          });
        }
      }
      setLoading(false);
    } catch (error) {
      console.log('PasswordReset checkEmail', error);
    }
  };

  const onResendMail = async () => {
    setLoading(true);

    try {
      const params = {
        userEmail: loginData.formStore.userEmail,
      };
      const response: { [key: string]: any } = await dispatch(getCheckEmail(params));
      if (response?.data?.list) {
        const { isSend } = response.data.list;
        setResend(isSend);
      }
      setLoading(false);
    } catch (error) {
      console.log('onResetPassword', error);
    }
  };

  return (
    <div className="password-reset-page login-wrapper column">
      <div className="password-reset">
        <img
          className="logo-white"
          src="/img/logo/mf_logo_white.png" // {Option.isSaxa === 1 ? sxLogoImage : mfLogoImage}
          alt="logo"
        />
        {loginData?.step === 2 && (
          // 비밀번호 재설정 후 메일 발송 안내 화면
          <div className="content-box send">
            <div className="info-text">
              <div className="main-text">
                {loginData?.send.isSend === 1 ? (
                  <img src="/img/login/icon_pw.png" className="passwd-reset-icon" alt="" />
                ) : (
                  <img src="/img/login/icon_fail.png" className="passwd-reset-icon" alt="" />
                )}
                {formatMessage({
                  id: 'ResetPassword_1',
                  defaultMessage: '비밀번호 재설정 메일 발송',
                })}
              </div>
              <div className="sub-text">
                {formatMessage({
                  id: 'ResetPassword_3',
                  defaultMessage: '메일로 전송된 링크를 클릭하여 비밀번호를 재설정하세요.',
                })}
              </div>
            </div>
            <div className="button-box">
              <button
                type="button"
                className="login-btn"
                onClick={() => props.history.push('/login/basic')}
                style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
              >
                {formatMessage({ id: 'Login_11', defaultMessage: '로그인 화면으로 돌아가기' })}
              </button>
              <button
                type="button"
                className="login-btn grey"
                onClick={onResendMail}
                style={{ background: 'url(/img/login/grey_button.png) no-repeat center' }}
              >
                {formatMessage({ id: 'Resend_1', defaultMessage: '재전송' })}
                {loading && (
                  <span className="button-loading">
                    <LoadingOutlined />
                  </span>
                )}
              </button>
              <div className={`resend-result${resend === 1 ? ' success' : ''}`}>
                {!!resend &&
                  !loading &&
                  (resend === 1
                    ? formatMessage({
                        id: 'Resend_7',
                        defaultMessage: '비밀번호 설정 메일 재전송 성공',
                      })
                    : formatMessage({
                        id: 'Resend_8',
                        defaultMessage: '비밀번호 설정 메일 재전송 실패',
                      }))}
              </div>
            </div>
          </div>
        )}
        {loginData?.step === 1 && (
          // 비밀번호 재설정 화면
          <div className="content-box">
            <div className="info-text">
              <div>
                <div className="main-text">
                  {formatMessage({ id: 'Password_5', defaultMessage: '비밀번호 찾기' })}
                </div>
                <div className="sub-text">
                  {formatMessage({
                    id: 'ResetPassword_6',
                    defaultMessage: '해당 이메일로 비밀번호 재설정 링크를 보내드립니다.',
                  })}
                </div>
              </div>
            </div>
            <form
              className="password-reset-form"
              onSubmit={handleSubmit(checkEmail)}
              autoComplete="off"
            >
              {/* 이메일 */}
              <input
                name="userEmail"
                className="underline-input"
                placeholder={formatMessage({ id: 'Email_1', defaultMessage: '이메일' })}
                ref={register({
                  validate: {
                    required: (value) => valid.required(value),
                    email: (value) => valid.email(value),
                  },
                })}
              />
              <div className="error-message">{errors.userEmail && errors.userEmail.message}</div>

              <div className="button-box">
                <button
                  className="login-btn"
                  type="submit"
                  style={{ background: 'url(/img/login/login_button.png) no-repeat center' }}
                >
                  {formatMessage({ id: 'Button_26', defaultMessage: '재설정 메일 전송' })}
                  {loading && (
                    <span className="button-loading">
                      <LoadingOutlined />
                    </span>
                  )}
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
}

export default withRouter(Pwreset);
