import { LoadingOutlined } from '@ant-design/icons';
import { List } from 'antd';
import { useState } from 'react';
import { useIntl } from 'react-intl';
import { useDispatch } from 'react-redux';
import { postSyncTest } from 'store/admin';
import ModalTemplate from '../ModalTemplate';
import './SyncTest.scss';


export default function SyncTest(props: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();

  const [showModal, setShowModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [resultMessage, setResultMessage] = useState({ success: [], fail: "" })


  const syncQueryTest = async () => {
    setLoading(true);

    const { parameter } = props;
    const params = { queryType: parameter === "userQuery" ? 1 : 2 }

    try {
      const response: any = await dispatch(postSyncTest(params));
      if (response && !response.data.error) {
        setResultMessage({ success: response.data.list, fail: "" })
      } else {
        setResultMessage({ success: [], fail: response.data.error[0].errInfo })
      }
    } catch (e) {
      console.log(e);
    }

    setLoading(false);
    setShowModal(true);
  }
  
  return (
    <>
      {showModal && (
        <ModalTemplate
          visible
          className="modal-665"
          onCancel={() => setShowModal(false)}
          loading={loading}
          title={formatMessage({ id: 'Config_10', defaultMessage: '연결 테스트' })}
          cancelText={formatMessage({ id: 'Button_4', defaultMessage: '확 인' })}
        >
          <div className="modal-text">
            {resultMessage.success.length > 0 ? (
              <div className="template-detail-box">
                <div className="template-detail">
                  <List
                    size='small'
                    dataSource={resultMessage.success}
                    renderItem={item => <List.Item>{JSON.stringify(item)}</List.Item>}
                  />
                </div>
              </div>
            ) : (
                <div>{resultMessage.fail}</div>
              )}
          </div>
        </ModalTemplate>
      )}
      <button className="sync-test-btn" onClick={syncQueryTest} type="button">
        {formatMessage({
          id: "Config_10",
          defaultMessage: "연결 테스트",
        })}
        {loading && (
          <span className="button-loading">
            <LoadingOutlined />
          </span>
        )}
      </button>
    </>
  );
}