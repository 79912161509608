import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat, getFollowOrderArray } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'edu/SET_INITIAL';
const GET_EDU_TEMPLATE = 'edu/GET_EDU_TEMPLATE';
const RELOAD_EDU_TEMPLATE = 'edu/RELOAD_EDU_TEMPLATE';
const CHANGE_PARAM = 'edu/CHANGE_PARAM';
const GET_EDU_SUMMARY = 'edu/GET_EDU_SUMMARY';
const CHANGE_SUMMARY_STATE = 'edu/CHANGE_SUMMARY_STATE';
const GET_EDU_TARGET = 'edu/GET_EDU_TARGET';
const CHANGE_TARGET_STATE = 'edu/CHANGE_TARGET_STATE';
const POST_EDU = 'edu/POST_EDU';
const PUT_EDU = 'edu/PUT_EDU';
const DELETE_EDU = 'edu/DELETE_EDU';
const GET_EDU_ATTACH = 'edu/GET_EDU_ATTACH';
const GET_EDU_QUIZ = 'edu/GET_EDU_QUIZ';

// Actions
export const getEduTemplate = (params?: { [key: string]: any }) =>
  actionFormat(GET_EDU_TEMPLATE, params, 'get', `${apiUrl}EduTemplate`);
export const addEduTemplate = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_EDU_TEMPLATE, params, 'post', `${apiUrl}EduTemplate`);
export const deleteEduTemplate = (params?: { [key: string]: any }) =>
  actionFormat(RELOAD_EDU_TEMPLATE, params, 'delete', `${apiUrl}EduTemplate`);
export const changeParam = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_PARAM, params, null, '');
export const getEduSummary = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU_SUMMARY, params, 'get', `${apiUrl}Edu`);
export const changeSummaryState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_SUMMARY_STATE, params);
export const getEduTarget = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU_TARGET, params, 'get', `${apiUrl}Edu/Target`);
export const changeTargetState = (params: { [key: string]: any }) =>
  actionFormat(CHANGE_TARGET_STATE, params);
// 교육 실시
export const postEdu = (params: { [key: string]: any }) =>
  actionFormat(POST_EDU, params, 'post', `${apiUrl}Edu`);
// 교육 수정
export const putEdu = (params: { [key: string]: any }) =>
  actionFormat(PUT_EDU, params, 'put', `${apiUrl}Edu`);
// 교육 삭제
export const deleteEdu = (params: { [key: string]: any }) =>
  actionFormat(DELETE_EDU, params, 'delete', `${apiUrl}Edu`);
// 교육 컨텐츠
export const getEduAttach = () =>
  actionFormat(GET_EDU_ATTACH, null, 'get', `${apiUrl}Edu/EduAttach`);
// 교육 실시 - 퀴즈 목록 조회
export const getEduQuiz = (params: { [key: string]: any }) =>
  actionFormat(GET_EDU_QUIZ, params, 'get', `${apiUrl}Quiz/QuizList`);
// 교육 실시 - 교육명 중복체크
export const eduNameCheck = (params: { [key: string]: any }) =>
  actionFormat(null, params, 'get', `${apiUrl}Edu`);

// initial state
interface stateType {
  data: { [key: string]: any };
  param: { [key: string]: any };
  needReload: boolean;
  totalPages: number;
  totalCount: number;
  eduSummary: { [key: string]: any };
  eduTarget: { [key: string]: any };
  eduAttach: { [key: string]: any };
}

const initialState: stateType = {
  data: {
    result: -1,
    success: [],
    fail: [],
  },
  param: {
    filter: {},
    sort: [{ field: 'regEpoch', order: 'DESC' }],
  },
  needReload: false,
  totalPages: 0,
  totalCount: 0,

  eduSummary: {
    data: [],
    dataByEduNo: {},
    needReload: false,
    param: {
      filter: {},
      sort: [{ field: 'eduNo', order: 'DESC' }],
    },
    selectedEduNo: 'all',
  },

  eduTarget: {
    data: [],
    dataByEduNo: {},
    needReload: false,
    param: {
      filter: {},
      sort: [{ field: 'eduTargetNo', order: 'DESC' }],
      limit: 30
    },
    page: 1,
    totalPage: 0,
    totalItem: 0,
    selectedList: [],
  },

  // 교육 컨텐츠 리스트
  eduAttach: {
    data: [],
    quizData: [],
  },
};

// Reducers
export default function (state: { [key: string]: any } = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case GET_EDU_TEMPLATE:
      if (action.payload) {
        const { data } = action.payload;
        const { offset, limit } = action.payload.config.params;

        // 전체 목록 수
        const totalCount = data.list.length > 0 ? data.list[0].rnum + offset : 0;
        // 전체 페이지 수
        const totalPages = Math.ceil(totalCount / limit);

        if (!data.error && data.list && Array.isArray(data.list)) {
          state.data = {
            result: 1,
            success: data.list,
            fail: [],
          };
        } else if (data.error && Array.isArray(data.error)) {
          state.data = {
            result: 0,
            success: [],
            fail: data.errror,
          };
        }

        state.needReload = false;
        state.totalPages = totalPages;
        state.totalCount = totalCount;
      }
      return state;

    case RELOAD_EDU_TEMPLATE:
      if (action.payload) {
        state.needReload = true;
      }
      return state;
    case CHANGE_PARAM:
      if (action.payload) {
        const { name, value } = action.payload;
        state.param[name] = value;
      }
      return state;
    case GET_EDU_SUMMARY:
      if (action.payload) {
        const { list } = action.payload.data;
        state.eduSummary.data = list;
        const eduData: any = {};
        list.forEach((item: any) => {
          eduData[item.eduNo] = item;
        });
        state.eduSummary.dataByEduNo = eduData;
        state.eduSummary.needReload = false;
      }
      return state;
    case POST_EDU:
      if (!action.payload?.data?.error) {
        state.eduSummary.needReload = true;
      }
      return state;
    case PUT_EDU:
      if (!action.payload?.data?.error) {
        state.eduSummary.needReload = true;
      }
      return state;
    case DELETE_EDU:
      if (action.payload) {
        state.eduSummary.selectedEduNo = 'all';
        state.eduSummary.needReload = true;
      }
      return state;
    case GET_EDU_ATTACH:
      if (action.payload) {
        const { list } = action.payload.data;
        state.eduAttach.data = list;
      }
      return state;
    case GET_EDU_QUIZ:
      if (action.payload) {
        const { list } = action.payload.data;
        state.eduAttach.quizData = list;
      }
      return state;
    case CHANGE_SUMMARY_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.eduSummary[key] = value;
      }
      return state;
    case GET_EDU_TARGET:
      if (action.payload) {
        const { list } = action.payload.data;
        const { refresh, limit } = action.payload.config.params;

        let curPage = 1;
        let curDataList = [];
        let curDataObj: any = {};

        if (list?.length > 0) {
          if (refresh) {
            state.eduTarget.totalPage = Math.ceil(list[0].rnum / limit);
            state.eduTarget.totalItem = list[0].rnum;
            curDataList = list;
            list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });
          } else {
            // 무한스크롤
            curPage = state.eduTarget.page + 1;
            curDataList = state.eduTarget.data.concat(list);
            list.forEach((item: any) => {
              curDataObj[item.targetNo] = item;
            });
            curDataObj = { ...state.eduTarget.dataByEduNo, ...curDataObj };
          }
        } else {
          state.eduTarget.totalPage = 0;
          state.eduTarget.totalItem = 0;
        }

        state.eduTarget.data = curDataList;
        state.eduTarget.dataByEduNo = curDataObj;
        state.eduTarget.page = curPage;
        state.eduTarget.needReload = false;
      }
      return state;
    case CHANGE_TARGET_STATE:
      if (action.payload) {
        const { key, value } = action.payload;
        state.eduTarget[key] = value;
      }
      return state;

    default:
      return state;
  }
}
