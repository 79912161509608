import { apiUrl, execUrl } from 'utils/commonValues';
import { actionFormat } from 'utils/commonFunctions';

// Action Types
const SET_INITIAL = 'login/SET_INITIAL';
const CHECK_LOGIN = 'login/CHECK_LOGIN' as const;
const POST_LOGIN = 'login/POST_LOGIN' as const;
const OTP_LOGIN = 'login/OTP_LOGIN';
const RESEND_OTP_EMAIL = 'login/RESEND_OTP_EMAIL';
const LOGOUT = 'login/LOGOUT' as const;
const CHECK_EMAIL = 'login/CHECK_EMAIL';
const SAVE_FORM = 'login/SAVE_FORM';
const INIT_PWRESET = 'login/INIT_PWRESET';

// Actions
export const setInitial = () => actionFormat(SET_INITIAL);
export const getCheckLogin = () =>
  actionFormat(CHECK_LOGIN, null, 'get', `${apiUrl}Login/CheckLogin`);
export const postLogin = (params: any) =>
  actionFormat(POST_LOGIN, params, 'post', `${apiUrl}Login`);
export const otpLogin = (params: any) =>
  actionFormat(OTP_LOGIN, params, 'post', `${apiUrl}Login/LoginOtp`);
export const resendOtpEmail = (params: any) =>
  actionFormat(RESEND_OTP_EMAIL, params, 'post', `${apiUrl}Login/ReSendLoginOtpEmail`);
export const getLogout = () => actionFormat(LOGOUT, null, 'get', `${apiUrl}Login/Logout`);

export const getCheckEmail = (params: any) =>
  actionFormat(CHECK_EMAIL, params, 'get', `${execUrl}PasswdMail`);
export const saveForm = (params: any) => actionFormat(SAVE_FORM, params);
export const initPwreset = () => actionFormat(INIT_PWRESET);

// Initial state
interface stateType {
  step?: number;
  sessionTimeout?: number;
  loginResult?: number;
  loginOtpResult?: number;
  loginMessage?: string;
  userData?: any;
  serviceNo?: any;
  passwordReset?: any;
  formStore?: any;
  send?: any;
}
const initialState: stateType = {
  step: 1,
  sessionTimeout: 0,
  loginResult: 0,
  loginOtpResult: 0,
  loginMessage: '',
  userData: null,
  serviceNo: null,
  passwordReset: null,
  formStore: {
    userEmail: '',
    userPasswd: '',
  },
  send: {
    isExist: 0,
    isSend: 0,
  },
};

// Reducers
export default function (state = initialState, action: any) {
  switch (action.type) {
    case SET_INITIAL:
      return initialState;
    case CHECK_LOGIN:
      if (action.payload) {
        const { list } = action.payload.data;
        state = {
          ...state,
          sessionTimeout: list.sessionTimeout,
          userData: list.userData,
          serviceNo: list.serviceNo,
        };
      }
      return state;
    case POST_LOGIN:
      if (action.payload) {
        const { list } = action.payload.data;
        state = {
          ...state,
          sessionTimeout: list.sessionTimeout,
          loginResult: list.loginResult,
          loginMessage: list.loginMessage,
          userData: list.userData,
          serviceNo: list.serviceNo,
        };
      } else {
        state = {
          ...state,
          loginResult: 2,
          loginMessage: 'Unable to connect to Server',
          userData: null,
          serviceNo: null,
        };
      }
      return state;
    case OTP_LOGIN:
      if (action.payload) {
        const { list } = action.payload.data;
        state = {
          ...state,
          sessionTimeout: list.sessionTimeout,
          loginResult: list.loginResult,
          loginOtpResult: list?.loginOtpResult,
          loginMessage: list.loginMessage,
          userData: list.userData,
          serviceNo: list.serviceNo,
        };
      } else {
        state = {
          ...state,
          loginResult: 2,
          loginOtpResult: 0,
          loginMessage: 'Unable to connect to Server',
          userData: null,
          serviceNo: null,
        };
      }
      return state;
    case CHECK_EMAIL:
      if (action.payload) {
        const { list } = action.payload.data;
        state.send = list;
        if (list.isExist === 1) {
          state.step = 2;
        }
      }
      return state;
    case SAVE_FORM:
      if (action.payload) {
        state.formStore = action.payload;
      }
      return state;
    case INIT_PWRESET:
      state = {
        ...state,
        step: 1,
      };
      return state;
    case LOGOUT:
      return initialState;
    default:
      return state;
  }
}
