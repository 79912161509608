import React from 'react';
import { useIntl } from 'react-intl';
import { Tooltip } from 'antd';
import { fileSizeTransform } from 'utils/commonFunctions';
import './CountInfo.scss';

// import allImage from 'img/exam/info_all.png';
// import fileImage from 'img/exam/info_file.png';
// import certImage from 'img/exam/info_certificate.png';
// import mailImage from 'img/exam/info_mail.png';
// import waitImage from 'img/exam/info_notyet.png';
// import ingImage from 'img/exam/info_proceed.png';
// import endImage from 'img/exam/info_end.png';

interface countProps {
  type: string;
  count: number;
  size?: number;
  tooltip?: any;
  isEdu?: boolean;
}

CountInfo.defaultProps = {
  size: 0,
  tooltip: null,
  isEdu: false,
};

function CountInfo({ type, count, size, tooltip, isEdu }: countProps) {
  const { formatMessage } = useIntl();

  let icon = '/img/exam/info_all.png';
  let unit = '';
  if (isEdu) {
    // 교육
    if (type === 'wait') {
      // icon = '/img/exam/info_notyet.png';
    } else if (type === 'ing') {
      icon = '/img/exam/info_proceed.png';
    } else if (type === 'finish') {
      icon = '/img/exam/info_end.png';
    }
    unit = formatMessage({ id: 'StartExam_35', defaultMessage: '명' });
  } else {
    // 감염
    if (type === 'file') {
      icon = '/img/exam/info_file.png';
    } else if (type === 'cert') {
      icon = '/img/exam/info_certificate.png';
    } else if (type === 'mail') {
      icon = '/img/exam/info_mail.png';
    }
    unit = formatMessage({ id: 'StartExam_21', defaultMessage: '개' });
  }

  return (
    <div className="leak-count-wrap color-dim-grey">
      {tooltip ? (
        <Tooltip overlayClassName="black-tooltip full" placement="top" title={tooltip}>
          <div className="flex">
            <img src={icon} alt="icon" />
            {count ? (
              <div>
                <div className="leak-text">{`${count}${unit}`}</div>
                {!!size && (
                  <div className="leak-text size">{`(${fileSizeTransform(size).common})`}</div>
                )}
              </div>
            ) : (
              <div>-</div>
            )}
          </div>
        </Tooltip>
      ) : (
        <>
          <img src={icon} alt="icon" />
          {count ? (
            <div>
              <div className="leak-text">{`${count}${unit}`}</div>
              {!!size && (
                <div className="leak-text size">{`(${fileSizeTransform(size).common})`}</div>
              )}
            </div>
          ) : (
            <div>-</div>
          )}
        </>
      )}
    </div>
  );
}

export default CountInfo;
