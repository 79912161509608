import { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import { useIntl } from 'react-intl';
import { addEduTemplate } from 'store/edu';
import { timeDiffFromSeconds } from 'utils/commonFunctions';
import * as valid from 'utils/validation';
import ModalTemplate from 'components/common/ModalTemplate';
import Loading from 'components/common/Loading';
import FormTextField from 'components/common/FormTextField';
import './EduTemplate.scss';

function EduTemplateAdd({ visible, toggleModal }: any) {
  const { formatMessage } = useIntl();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [videoList, setVideoList] = useState([]);
  const [file, setFile]: any = useState(null);
  const { errors, register, handleSubmit } = useForm({ mode: 'all' });
  const {
    errors: errors2,
    register: register2,
    getValues: getValues2,
    setValue: setValue2,
    setError: setError2,
    clearErrors: clearErrors2,
    handleSubmit: handleSubmit2
  } = useForm({ mode: 'all' });

  // 교육 템플릿 등록
  const onAddEduTemplate = async (formData: any) => {
    try {
      setLoading(true);

      const form = new FormData();
      form.append('attachName', formData.attachName);
      form.append('fileInfo', JSON.stringify(videoList));

      if (file) {
        form.append('thumbnail', file);
      }

      const response: any = await dispatch(addEduTemplate(form));
      if (!response?.data?.error) {
        setLoading(false);
        toggleModal(false);
      }
    } catch (error) {
      console.log('EduTemplateAdd onAddEduTemplate', error);
    }
  };

  // 영상 추가
  const addVideo = () => {
    const { fileName, filePath } = getValues2();
    if (fileName && filePath) {
      // 비디오 엘리먼트 생성
      const video: any = document.createElement('video');
      video.src = filePath;
      video.addEventListener('error', () => {
        setError2('filePath', {
          type: 'error',
          message: formatMessage({ id: 'Edu_69', defaultMessage: '올바르지 않은 URL 입니다.' })
        })
      });
      // 비디오가 로드되면 해당 정보 리스트 저장
      video.addEventListener('loadedmetadata', () => {
        const tempList: any = [...videoList];
        tempList.push({
          fileName: fileName,
          filePath: filePath,
          runningTime: parseInt(video.duration, 10),
        });
        // http://vod.cdn.hunet.co.kr/M_b2b/etc/20190528/HLSC26884/53_02.mp4
        setVideoList(tempList);
        // 입력값 초기화
        setValue2('fileName', null);
        setValue2('filePath', null);
        clearErrors2('filePath')
      });
    }
  };

  // 파일 선택
  const selectFile = (e: any) => {
    const file = e.target.files[0];
    const fileTypeArray = ['image/bmp', 'image/jpg', 'image/jpeg', 'image/png', 'image/gif'];
    if (file.type && fileTypeArray.indexOf(file.type) > -1) {
      setFile(file);
    } else {
      setFile(null);
    }
  };

  return (
    <ModalTemplate
      className="edu-template-add-modal modal-464"
      visible={visible}
      title={
        <div className="modal-template-header">
          <div className="title">
            {formatMessage({ id: 'Edu_53', defaultMessage: '교육 템플릿 등록' })}
          </div>
        </div>
      }
      onOk={handleSubmit(onAddEduTemplate)}
      onCancel={() => toggleModal(false)}
      okText={formatMessage({ id: 'Button_10', defaultMessage: '등 록' })}
      cancelText={formatMessage({ id: 'Button_12', defaultMessage: '취 소' })}
      loading={loading}
      disabled={videoList.length < 1}
    >
      <Loading loading={loading} />

      <form autoComplete="off" onSubmit={(e: any) => e.preventDefault()}>
        {/* 컨텐츠명 */}
        <div className="input-title">
          {formatMessage({ id: 'Edu_5', defaultMessage: '컨텐츠명' })}*
        </div>
        <FormTextField
          name="attachName"
          error={errors.attachName}
          register={register}
          arrowPosition="top"
          validation={{
            validate: {
              required: (value: any) => valid.required(value),
            },
          }}
        />

        <div className="video-add-area">
          {/* 영상명 */}
          <div className="edu-item">
            <div className="text-field-title">
              {formatMessage({ id: 'Edu_6', defaultMessage: '영상명' })}*
            </div>
            <FormTextField
              name="fileName"
              error={errors2.fileName}
              register={register2}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
            />
          </div>

          {/* 영상 URL */}
          <div className="edu-item">
            <div className="text-field-title">
              {formatMessage({ id: 'Edu_54', defaultMessage: '영상 URL' })}*
            </div>
            <FormTextField
              name="filePath"
              error={errors2.filePath}
              register={register2}
              arrowPosition="top"
              validation={{
                validate: {
                  required: (value: any) => valid.required(value),
                },
              }}
            />
          </div>

          <button className="add-video-btn" type="button" onClick={handleSubmit2(addVideo)}>
            {formatMessage({ id: 'Edu_55', defaultMessage: '추가' })}
          </button>
        </div>

        <div className="text-field-title">
          {formatMessage({ id: 'Edu_70', defaultMessage: '썸네일 등록' })}
        </div>
        <div className="file-input-area">
          {file ? (
            <label>{file.name}</label>
          ) : (
            <label className="upload-hint">
              {formatMessage({
                id: 'Phishing_13',
                defaultMessage: '파일을 선택하세요.',
              })}
            </label>
          )}
          <input
            accept="image/bmp, image/jpg, image/jpeg, image/png, image/gif"
            type="file"
            onChange={(e: any) => selectFile(e)}
            onClick={(e: any) => (e.target.value = null)} // eslint-disable-line
          />
        </div>
      </form>

      <div className="text-field-title">
        {formatMessage({ id: 'Edu_56', defaultMessage: '추가한 영상 목록' })}
      </div>
      <div className="video-list-box">
        <div className="video-list">
          {videoList.map((video: any) => {
            return (
              <div className="video-list-item" key={`${video.fileName}${video.filePath}`}>
                <div className="video-title">
                  <span className="dot">·</span>
                  {video.fileName}
                  <span className="running-time">{` (${timeDiffFromSeconds(
                    video.runningTime,
                  )})`}</span>
                </div>
                <div className="video-url ellipsis" title={video.filePath}>
                  {video.filePath}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </ModalTemplate>
  );
}

export default EduTemplateAdd;
